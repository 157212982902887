<template>
  <div v-if="param.isShow" class="popup" style="display: block">
    <div class="popup_header">
      <div class="header_title">{{ param.title }}</div>
      <button class="close_popup" @click="close()">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" />
      </button>
    </div>
    <div class="popup_content">
      <div class="text_info_popup">
        {{ param.msg }}
      </div>
      <div v-show="param.pdfView" class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title" style="width: 100px">선택</p>
            <p class="input">
              <label class="custom_radio">
                <input
                  v-model="pdfView"
                  type="radio"
                  name="modi_color_01"
                  :value="true"
                />
                <span>PDFviewer</span>
              </label>
              <label class="custom_radio">
                <input
                  v-model="pdfView"
                  type="radio"
                  name="modi_color_01"
                  :value="false"
                />
                <span> 파일 다운로드</span>
              </label>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close()">취소</button>
        <button type="button" class="comfirm" @click="ok(param.callback)">
          확인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  components: {},
  props: {
    param: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      pdfView: true,
    };
  },
  computed: {},
  watch: {
    param: {
      immediate: true,
      deep: true,
      handler(val) {
        this.param = val;
      },
    },
  },

  beforeCreate() {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.param.isShow = false;
      this.param.pdfView = false;
    },

    ok() {
      console.log(this.param);
      this.param.callbackParam.file = this.param.file;
      this.param.callbackParam.pdfViewType = this.pdfView;
      this.param.isShow = false;
      this.param.pdfView = false;
      this.$emit("confirmCallback", this.param.item);
      this.$emit("logOutCallback", this.param.logOutCallback);
      this.$emit("pdfCallback", this.param.callbackParam);
    },
  },
};
</script>

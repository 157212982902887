import { httpClient } from "../../network/httpclient/HttpClientFactory";

const mainStatistic = (siteId, param) =>
  httpClient.get(`/count/${siteId}`, { params: param }).then((res) => res.data);

const mainStatisticByDepth = (siteId, param) =>
  httpClient
    .get(`/count/depth/${siteId}`, { params: param })
    .then((res) => res.data);

const mainStatisticDepth2 = (siteId, code) =>
  httpClient.get(`/count/${siteId}/${code}`).then((res) => res.data);

const monthlyCount = (siteId, param) =>
  httpClient
    .get(`/count/month/${siteId}`, { params: param })
    .then((res) => res.data);

const monthlyCountByDepth = (siteId, param) =>
  httpClient
    .get(`/count/month/depth/${siteId}`, { params: param })
    .then((res) => res.data);

const monthlyCountDepth2 = (siteId, code) =>
  httpClient.get(`/count/month/${siteId}/${code}`).then((res) => res.data);

const graphCount = (param) =>
  httpClient.get(`/count/graph`, { params: param }).then((res) => res.data);

const graphCountByDepth = (param) =>
  httpClient
    .get(`/count/graph/depth`, { params: param })
    .then((res) => res.data);

const graphCountDivision = (param) =>
  httpClient
    .get(`/count/graph/division`, { params: param })
    .then((res) => res.data);

const graphCountDivisionMonthly = (param) =>
  httpClient
    .get(`/count/graph/division/month`, { params: param })
    .then((res) => res.data);

const graphCountPour = (type, param) =>
  httpClient
    .get(`/count/graph/pour/${type}`, { params: param })
    .then((res) => res.data);
const graphCountPourScatter = (siteId, param) =>
  httpClient
    .get(`/count/graph/pourScatter/${siteId}`, { params: param })
    .then((res) => res.data);

const graphCountPourXr = (siteId, material, param) =>
  httpClient
    .get(`/count/graph/pourXr/${siteId}/${material}`, { params: param })
    .then((res) => res.data);

const graphCountMaterial = (param) =>
  httpClient
    .get(`/count/graph/material`, { params: param })
    .then((res) => res.data);

const graphStatisticYear = (siteId, param) =>
  httpClient
    .get(`/count/graph/statistic/year/${siteId}`, { params: param })
    .then((res) => res.data);

const graphStatisticYearByDepth = (siteId, param) =>
  httpClient
    .get(`/count/graph/statistic/year/depth/${siteId}`, { params: param })
    .then((res) => res.data);

export default {
  mainStatistic,
  mainStatisticDepth2,
  monthlyCount,
  monthlyCountDepth2,
  graphCountDivision,
  graphCount,
  graphCountPour,
  graphCountPourScatter,
  graphCountMaterial,
  graphCountDivisionMonthly,
  graphStatisticYear,
  graphCountPourXr,
  graphCountByDepth,
  mainStatisticByDepth,
  monthlyCountByDepth,
  graphStatisticYearByDepth,
};

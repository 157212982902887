<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">수정요청</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">변경사유</p>
            <p class="input">
              <textarea
                v-model="msg"
                readonly
                type="text"
                style="height: 70px"
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">요청일시</p>
            <p class="input">
              <input v-model="item.confirmDateTimeFm" type="text" readonly />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button
          v-show="item.confirmStatus === 'change'"
          type="button"
          class="comfirm"
          @click="save"
        >
          승인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PopupConfirm",
  components: {},
  props: {},
  data: () => {
    return {
      isShow: false,
      msg: null,
      item: {
        confirmStatus: null,
      },
    };
  },
  computed: {
    ...mapState({}),
  },
  watch: {},

  beforeCreate() {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.isShow = false;
    },
    open(item) {
      this.isShow = true;
      this.msg = null;
      if (item.confirmStatus !== "confirm" && item.msg) this.msg = item.msg;
      this.item = item;

      if (this.item.confirmDatetime)
        this.item.confirmDateTime = this.item.confirmDatetime;

      this.item.confirmDateTimeFm = this.formattedDatetime(
        this.item.confirmDateTime
      );
    },
    save() {
      this.$emit("regConfirm", this.item);
      this.isShow = false;
    },
    remove() {
      this.$emit("removeConfirm", this.item);
      this.isShow = false;
    },
    formattedDatetime(datetimeString) {
      const datetime = new Date(datetimeString);

      const year = datetime.getFullYear();
      const month = String(datetime.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더하고, 문자열로 변환하여 2자리로 만듭니다.
      const date = String(datetime.getDate()).padStart(2, "0"); // 일자를 2자리로 만듭니다.
      const hours = String(datetime.getHours()).padStart(2, "0"); // 시를 2자리로 만듭니다.
      const minutes = String(datetime.getMinutes()).padStart(2, "0"); // 분을 2자리로 만듭니다.
      const seconds = String(datetime.getSeconds()).padStart(2, "0"); // 초를 2자리로 만듭니다.

      const formattedDatetime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
      return formattedDatetime;
    },
  },
};
</script>

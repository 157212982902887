<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap" style="display: flex">
        <div class="search_filter">
          <form>
            <select
              v-model="selectSiteId"
              class="search_option"
              @change="changeSite()"
            >
              <option
                v-for="item in accessSiteList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              v-show="displayGraph === true"
              v-model="listParam.year"
              class="search_option"
              @change="changeGraph()"
            >
              <option
                v-for="item in yearList"
                :key="item.key"
                :value="item.key"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              v-show="displayGraph === true"
              v-model="listParam.month"
              class="search_option"
              @change="changeGraph()"
            >
              <option
                v-for="item in monthList"
                :key="item.key"
                :value="item.key"
              >
                {{ item.name }}
              </option>
            </select>
            <div v-show="displayList === true" class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </div>
            <select
              v-show="displayList === true"
              v-model="listParam.registrationYn"
              class="search_option"
              @change="changeSite()"
            >
              <option value="N">CSI 미등록</option>
              <option value="Y">CSI 등록완료</option>
              <option :value="null">전체</option>
            </select>

            <select
              v-show="displayList === true"
              v-model="listParam.division"
              class="search_option"
              @change="changeSite()"
            >
              <option value="inside">현장시험</option>
              <option value="remicon">레미콘시험</option>
              <option value="shortCrete">숏크리트시험</option>
              <option :value="null">전체</option>
            </select>

            <div v-show="displayList === true" class="option_date w100">
              <input
                v-model="listParam.searchText"
                type="text"
                placeholder="시험번호 검색"
                @keyup.enter="onClickSearch"
              />
            </div>

            <div v-show="displayList === true" class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div>
          </form>
        </div>
      </div>

      <!-- <div class="option_wrap">
        <div class="search_filter">
          <form action="">
            <label class="custom_checkbox">
              <input
                v-model="selectedStatus"
                value="approval"
                type="checkbox"
                @change="changeSite()"
              />
              <span>결재완료 &nbsp; </span>
            </label>
            <label class="custom_checkbox">
              <input
                v-model="selectedStatus"
                value="waiting"
                type="checkbox"
                @change="changeSite()"
              />
              <span>결재즁 &nbsp; </span>
            </label>
            <label class="custom_checkbox">
              <input
                v-model="selectedStatus"
                value="writing"
                type="checkbox"
                @change="changeSite()"
              />
              <span>작성중 &nbsp; </span>
            </label>
          </form>
        </div>
      </div> -->

      <div class="option_wrap">
        <div class="right_area">
          <div
            class="download_button"
            :class="displayList === true ? 'on' : ''"
          >
            <button type="button" @click="changeDisplay('list')">성적서</button>
          </div>
          <div
            class="download_button"
            :class="displayGraph === true ? 'on' : ''"
          >
            <button type="button" @click="changeDisplay('graph')">
              주단위 통계 그래프
            </button>
          </div>
        </div>
      </div>

      <div v-show="displayGraph === true" id="container">
        <v-chart class="chart" :option="optionMonthEnroll" autoresize />
        <v-chart class="chart" :option="optionMonthNotEnroll" autoresize />
      </div>

      <!-- 검색 Wrap //-->

      <div v-show="displayList === true" class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="40">현장명</th>
              <th width="40">CSI</th>
              <th width="60">시험완료일</th>
              <th width="70">시험번호</th>
              <th width="70">시험구분</th>
              <th width="200">시험·검사 장소</th>
              <th width="100">재료</th>
              <th width="60">생산자</th>
              <th width="60">시험항목</th>
              <th width="60">시험기준</th>
              <th width="60">시험결과</th>
              <th width="60">판정</th>
              <th width="60">시험자</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.length === 0">
              <td colspan="13">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam" :key="index">
                <td>
                  {{ item.siteName }}
                </td>
                <td>
                  <p style="color: aqua">
                    {{ registrationType(item.registrationYn) }}
                  </p>
                </td>

                <td>
                  {{ item.testDay }}
                </td>
                <td>
                  {{ item.testNo }}
                </td>
                <td>
                  {{ divisionType(item) }}
                </td>
                <td>
                  {{ item.location }}
                </td>
                <td>
                  {{ item.material }}
                </td>
                <td>
                  {{ item.company }}
                </td>
                <td>
                  <p
                    v-for="(itemName, nameIndex) in item.itemNameList"
                    :key="nameIndex"
                    class="bordered"
                  >
                    {{ itemName }}
                  </p>
                </td>
                <td>
                  <p
                    v-for="(standard, standardIndex) in item.standardList"
                    :key="standardIndex"
                    class="bordered"
                  >
                    {{ standard }}
                  </p>
                </td>
                <td>
                  <p
                    v-for="(result, resultIndex) in item.resultList"
                    :key="resultIndex"
                    class="bordered"
                  >
                    {{ result }}
                  </p>
                </td>
                <td>
                  {{ displayJudgment(item.judgment) }}
                </td>
                <td>
                  {{ item.regName }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <popup-quality-plan-detail
      ref="popupQualityDetail"
      @viewPdf="callViewPdf"
    />
    <popup-confirm-list ref="popupConfirmList" />
    <popup-confirm ref="popupConfirm" @regConfirm="regConfirm" />
    <confirm :param="confirmParam" @confirmCallback="confirmCallback" />
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { getToday, toDayFormatAddDay, dayFormat } from "../utils/date";
import { mapGetters, mapActions } from "vuex";
import HistoryFileApi from "../api/qbox/HistoryFileApi";
import SiteApi from "../api/qbox/SiteApi";
import PopupQualityPlanDetail from "./detail/PopupQualityPlanDetail.vue";
import ConfirmRequestApi from "../api/qbox/ConfirmRequestApi";
import PopupConfirm from "./detail/PopupConfirm.vue";
import PopupConfirmList from "./detail/PopupConfirmList.vue";
import Confirm from "./Confirm.vue";
import CsiApi from "../api/qbox/CsiApi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
]);
export default {
  name: "PopupQualityPlan",
  components: {
    PopupQualityPlanDetail,
    PopupConfirm,
    PopupConfirmList,
    Confirm,
    DatePicker,
    VChart,
  },
  props: {
    siteId: {},
  },
  data: () => {
    return {
      displayList: true,
      displayGraph: false,
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        beginDate: "2024-07-10",
        endDate: getToday(),
        name: null,
        registrationYn: "N",
        division: "inside",
        month: 7,
        year: 2024,
      },
      isShow: false,
      allSelected: false,
      fileIds: [],
      accessSiteList: [],
      selectSiteId: null,
      confirmStatus: { writing: "미확인", confirm: "확인", change: "수정요청" },
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
      },
      optionMonthEnroll: {},
      optionMonthNotEnroll: {},
      xAxisData: [],
      insideCountRegistration: [],
      insideCountNotRegistration: [],
      remiconCountRegistration: [],
      remiconCountNotRegistration: [],
      shortCountRegistration: [],
      shortCountNotRegistration: [],
      monthList: [],
      yearList: [],
      siteYearPlanList: [],
      selectedStatus: ["approval", "waiting", "writing"],
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    getToday: getToday,
    dayFormat: dayFormat,
    async init() {
      this.selectSiteId = this.siteId;

      const today = new Date();
      const currentYear = today.getFullYear();

      this.monthList = [];

      for (let i = 1; i <= 12; i++) {
        this.monthList.push({ name: i + "월", key: i });
      }
      const startYear = 2024;
      const yearRange = currentYear - startYear;

      this.yearList.push({ name: startYear + "년", key: startYear });

      if (yearRange > 0) {
        for (let i = 1; i <= yearRange; i++) {
          this.yearList.push({
            name: startYear + 1 + "년",
            key: startYear + i,
          });
        }
      }

      if (currentYear > 2024) {
        const response = await SiteApi.getSiteYearPlan(this.siteId);
        this.siteYearPlanList = response;
        if (this.siteYearPlanList.length > 0) {
          const today = this.getToday();
          const findSiteYear = this.siteYearPlanList.filter(
            (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
          );
          if (findSiteYear.length > 0) {
            this.listParam.beginDate = findSiteYear[0].beginDay;
          } else {
            this.listParam.beginDate = toDayFormatAddDay(-7);
          }
        }
      }

      this.getSiteList();
      this.fetchHistoryFile();
      this.getStat();
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
      console.log(this.accessSiteList);
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchHistoryFile();
    },
    async getStat() {
      this.listParam.siteId = this.selectSiteId;
      const response = await CsiApi.csiStat(this.listParam);
      console.log(response);

      this.xAxisData = [];
      this.insideCountRegistration = [];
      this.insideCountNotRegistration = [];
      this.remiconCountRegistration = [];
      this.remiconCountNotRegistration = [];
      this.shortCountRegistration = [];
      this.shortCountNotRegistration = [];
      response.forEach((item) => {
        this.xAxisData.push(item.weekLabel);
        this.insideCountRegistration.push(item.insideCountRegistration);
        this.insideCountNotRegistration.push(item.insideCountNotRegistration);
        this.remiconCountRegistration.push(item.remiconCountRegistration);
        this.remiconCountNotRegistration.push(item.remiconCountNotRegistration);
        this.shortCountRegistration.push(item.shortCountRegistration);
        this.shortCountNotRegistration.push(item.shortCountNotRegistration);
      });
      this.setupMonthEnroll();
      this.setupMonthNotEnroll();
    },

    async fetchHistoryFile() {
      this.listParam.siteId = this.selectSiteId;

      this.showLoading();

      const response = await CsiApi.csiInsideBookList(this.listParam);
      this.pageParam = response;
      console.log(response);
      this.pageParam.forEach((item) => {
        this.accessSiteList.forEach((site) => {
          if (item.siteId === site.id) {
            item.siteName = site.name;
          }
        });
      });
      this.hideLoading();
    },
    convertNumber(category) {
      if (!category) return null;
      const index = category.indexOf("-");
      let result = null;
      if (index !== -1) {
        result = category.substring(0, index);
      } else {
        result = category;
      }

      return Number(result);
    },
    pageCallback(page) {
      this.listParam.page = page;
      this.fetchHistoryFile();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },

    sliceTime(time) {
      if (time) return time.slice(0, 5);
      return "";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.listParam.division = "inside";
      this.listParam.registrationYn = "N";
      this.listParam.searchText = null;
      this.isShow = false;
    },
    selectAll() {
      this.fileIds = [];
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.pageParam.content.forEach((element) => {
          this.fileIds.push(element.fileId);
        });
      }
    },
    select() {
      this.allSelected = false;
    },
    async detail(id) {
      console.log(id);
      //TODO 팝업 테스트
      const response = await HistoryFileApi.detail(id);
      console.log(response);
      this.$refs.popupQualityDetail.open(response.content);
    },

    changeSite() {
      this.fetchHistoryFile();
      this.getStat();
    },
    changeGraph() {
      // this.fetchHistoryFile();
      this.getStat();
    },

    registrationType(data) {
      if (data === "Y") {
        return "등록";
      }
      if (!data || data === "N") {
        return "미등록";
      }
    },
    divisionType(data) {
      if (data.division === "inside") {
        return "현장시험";
      }
      if (data.division === "remicon") {
        if (data.concreteType === "normal") {
          return "레미콘";
        } else {
          return "숏크리트";
        }
      }
    },
    displayJudgment(judgment) {
      if (judgment === "pass") {
        return "합격";
      } else if (judgment === "fail") {
        return "불합격";
      }
      return "-";
    },
    setupMonthEnroll() {
      this.optionMonthEnroll = {
        title: {
          text: "CSI 등록",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 15,
            color: "#ffffff",
          },
          backgroundColor: "rgba(30,110,235,1)",
          borderRadius: 50,
        },
        grid: {
          top: 40,
          bottom: 50,
          left: 50,
          right: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow",
          },
          data: this.xAxisData,
          boundaryGap: true,
          axisLabel: {
            rotate: 20,
            interval: 0,
            // X축 폰트 크기
            show: true,
            fontSize: 20,
            // color: "rgba(255, 255, 255, 0.5)",
            color: "#8D8D8D",
            textStyle: {
              fontSize: 18,
              fontWeight: "bolder",
            },
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        legend: {
          data: ["현장시험", "레미콘시험", "숏크리트시험"],
          textStyle: {
            color: "#ccc",
            fontSize: 20,
            fontFamily: "sans-serif",
          },
        },
        series: [
          {
            name: "현장시험",
            type: "line",
            data: this.insideCountRegistration,
          },
          {
            name: "레미콘시험",
            type: "line",
            data: this.remiconCountRegistration,
          },
          {
            name: "숏크리트시험",
            type: "line",
            data: this.shortCountRegistration,
          },
        ],
      };
      console.log(this.optionMonth);
    },
    setupMonthNotEnroll() {
      this.optionMonthNotEnroll = {
        title: {
          text: "CSI 미등록",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 15,
            color: "#ffffff",
          },
          backgroundColor: "rgba(30,110,235,1)",
          borderRadius: 50,
        },
        grid: {
          top: 40,
          bottom: 50,
          left: 50,
          right: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow",
          },
          data: this.xAxisData,
          boundaryGap: true,
          axisLabel: {
            rotate: 20,
            interval: 0,
            // X축 폰트 크기
            show: true,
            fontSize: 8,
            // color: "rgba(255, 255, 255, 0.5)",
            color: "#8D8D8D",
            textStyle: {
              fontSize: 18,
              fontWeight: "bolder",
            },
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        legend: {
          data: ["현장시험", "레미콘시험", "숏크리트시험"],
          textStyle: {
            color: "#ccc",
            fontSize: 20,
            fontFamily: "sans-serif",
          },
        },
        series: [
          {
            name: "현장시험",
            type: "line",
            data: this.insideCountNotRegistration,
          },
          {
            name: "레미콘시험",
            type: "line",
            data: this.remiconCountNotRegistration,
          },
          {
            name: "숏크리트시험",
            type: "line",
            data: this.shortCountNotRegistration,
          },
        ],
      };
      console.log(this.optionMonth);
    },
    changeDisplay(type) {
      if (type === "list") {
        this.displayList = true;
        this.displayGraph = false;
      } else if (type === "graph") {
        this.displayList = false;
        this.displayGraph = true;
      }
    },
  },
};
</script>
<style scoped>
#container {
  height: calc(100vh - 500px) !important;
  display: flex;
}
.option_wrap {
  display: block;
}
td {
  border: 1px solid !important;
  padding: 0 !important;
}
.bordered {
  border-bottom: 1px solid !important; /* 원하는 스타일로 변경 */
}
.bordered:last-child {
  border-bottom: none !important; /* 원하는 스타일로 변경 */
}
table {
  table-layout: auto !important; /* 테이블의 레이아웃을 콘텐츠에 따라 자동으로 조정합니다. */
}
</style>

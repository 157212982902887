import { render, staticRenderFns } from "./PopupGraph.vue?vue&type=template&id=4525ba40&scoped=true"
import script from "./PopupGraph.vue?vue&type=script&lang=js"
export * from "./PopupGraph.vue?vue&type=script&lang=js"
import style0 from "./PopupGraph.vue?vue&type=style&index=0&id=4525ba40&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4525ba40",
  null
  
)

export default component.exports
import { httpClient } from "../../network/httpclient/HttpClientFactory";

const ledger = (siteId, specId) =>
  httpClient
    .get(`/material/inspection/ledger/${siteId}/${specId}`)
    .then((res) => res.data);

export default {
  ledger,
};

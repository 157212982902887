<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">품질관리 계획서 상세</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div v-show="qualityPlan.type === 'quality'" class="form_write">
            <p class="title">문서번호</p>
            <p class="input">
              <input v-model="qualityPlan.category" type="text" readonly />
            </p>
          </div>
          <div class="form_write">
            <p class="title">{{ titleMsg }}</p>
            <p class="input">
              <input v-model="qualityPlan.content" type="text" readonly />
            </p>
          </div>
          <div v-show="qualityPlan.type === 'concrete'" class="form_write">
            <p class="title">규격</p>
            <p class="input">
              <textarea
                v-model="qualityPlan.specification"
                type="text"
                readonly
              />
            </p>
          </div>
          <div class="form_write">
            <p class="title">개정번호</p>
            <p class="input">
              <select
                class="search_option"
                v-model="selectedQualityPlan"
                @change="selectQualityPlanChange"
              >
                <option
                  v-for="(item, index) in revisionList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </p>
          </div>
          <div class="form_write">
            <p class="title">개정일자</p>
            <p class="input">
              <input
                v-model="qualityPlan.registrationDay"
                type="text"
                readonly
              />
            </p>
          </div>
          <div class="form_write">
            <p class="title">개정내용</p>
            <p class="input">
              <textarea v-model="qualityPlan.remark" type="text" readonly />
            </p>
          </div>

          <div v-show="qualityPlan.type === 'concrete'" class="form_write">
            <p class="title">Conc 타입</p>
            <p class="input">
              <input
                :value="testTypeConvert(qualityPlan.concType)"
                type="text"
                readonly
              />
            </p>
          </div>

          <div class="form_write" style="display: contents">
            <p
              class="title"
              style="margin-left: 350px; margin-top: 20px; margin-bottom: 20px"
            >
              첨부파일 목록
            </p>
            <div class="foot_btns" v-show="etcFiles.length !== 0">
              <button
                type="button"
                class="cancel"
                @click="allFileDown"
                style="border: 1px solid #0064ff"
              >
                전체 받기
              </button>
            </div>
            <p class="input" v-if="etcFiles.length === 0">
              <input v-model="msg" type="text" readonly />
            </p>
            <div
              class="form_write"
              style="display: block; height: 400px; overflow-y: scroll"
            >
              <p
                class="input"
                v-for="file in etcFiles"
                :key="file.fileNo"
                @click="fileDown(file)"
                download
              >
                <input
                  :value="getFileInfo(file)"
                  type="text"
                  readonly
                  style="cursor: pointer"
                />
                <span
                  v-if="file.fileExtension === 'pdf'"
                  class="pdf-btn"
                  @click.stop="viewPdf(file)"
                  >Viewer
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">닫기</button>
      </div>
    </div>
    <confirm :param="confirmParam" @pdfCallback="pdfCallback" />
  </div>
</template>
<script>
import HistoryFileApi from "../../api/qbox/HistoryFileApi";
import Confirm from "../Confirm.vue";
export default {
  name: "PopupQualityPlanDetail",
  components: { Confirm },
  props: {},
  data: () => {
    return {
      isShow: false,
      qualityPlan: {},
      selectedQualityPlan: null,
      selectedQualityPlanIndex: 0,
      revisionList: [],
      qualityPlanList: [],
      etcFiles: [],
      msg: "등록된 파일이 없습니다",
      titleMsg: "",
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
        callbackParam: {},
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.revisionList = [];
      this.qualityPlan = {};
      this.etcFiles = [];
      this.isShow = false;
    },
    open(item) {
      if (item.qualityPlan.type === "concrete") {
        this.titleMsg = "업체명";
      } else {
        this.titleMsg = "문서명";
      }
      console.log(this.titleMsg);
      this.qualityPlan = item.qualityPlan;
      this.qualityPlanList = item.qualityPlanDetailList;

      if (this.qualityPlan.fileId) {
        HistoryFileApi.getFiles(this.qualityPlan.fileId).then((res) => {
          this.etcFiles = res.content;
          this.etcAllFiles = res.content;
          this.etcFiles = this.etcFiles.filter((file) => file.deleteYn === "N");
        });
      }

      this.selectedQualityPlan = this.qualityPlan.revision;

      this.qualityPlanList.forEach((qualityPlan, index) => {
        this.$set(this.revisionList, index, qualityPlan.revision);
      });
      this.isShow = true;
    },
    selectQualityPlanChange() {
      this.selectedQualityPlanIndex = this.qualityPlanList.findIndex(
        (qualityPlan) => qualityPlan.revision === this.selectedQualityPlan
      );
      this.qualityPlan = this.qualityPlanList[this.selectedQualityPlanIndex];

      if (this.qualityPlan.fileId) {
        HistoryFileApi.getFiles(this.qualityPlan.fileId).then((res) => {
          this.etcFiles = res.content;
          this.etcAllFiles = res.content;
          this.etcFiles = this.etcFiles.filter((file) => file.deleteYn === "N");
        });
      } else {
        this.etcFiles = [];
      }
    },
    fileDown(file) {
      HistoryFileApi.fileDown(file.name, file.fileId, file.fileNo);
    },
    async allFileDown() {
      const fileArray = [];
      console.log(this.etcFiles);

      this.etcAllFiles.forEach((item) => {
        const file = {};
        file.fileId = item.fileId;
        file.fileNo = item.fileNo;
        fileArray.push(file);
      });

      const fileName = this.qualityPlan.content;
      await HistoryFileApi.multiDownload(fileArray, fileName);
    },
    viewPdf(file) {
      console.log("detail");
      if (file.size > 10000000) {
        this.confirmParam.file = file;
        this.confirmParam.pdfView = true;
        this.confirmParam.msg =
          "10MB 이상의 파일은 PDFviewer 또는 다운로드를 선택 해주세요";
        this.confirmParam.isShow = true;
        // this.$toast.info("10MB이상의 파일은 지원하지 않습니다.");
      } else {
        console.log(file);
        this.$emit("viewPdf", file);
      }
    },
    pdfCallback(param) {
      if (param.pdfViewType) {
        this.$emit("viewPdf", param.file);
      } else {
        HistoryFileApi.fileDown(
          param.file.name,
          param.file.fileId,
          param.file.fileNo
        );
      }
    },
    getFileInfo(file) {
      return file.name + "(" + (file.size * 0.000001).toFixed(2) + "MB)";
    },
    testTypeConvert(item) {
      if (item === "normal") return "콘크리트";
      return "숏크리트";
    },
    save() {},
  },
};
</script>
<style>
.v-toast.v-toast--bottom {
  z-index: 1000000 !important;
}
</style>

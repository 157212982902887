<template>
  <div class="dash_admin_wrap" style="display: block; z-index: 100000000">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <div class="dash_admin_box_head">
        <p class="admin_box_title">이선교</p>

        <button class="close_popup">
          <img src="/img/common/popup_close.svg" @click="close" />
        </button>
      </div>

      <div class="option_wrap">
        <div class="right_area">
          <div
            class="download_button"
            :class="displayGraph === true ? 'on' : ''"
          >
            <button type="button" @click="changeDisplay('graph')">
              그래프
            </button>
          </div>
          <div
            class="download_button"
            :class="displayList === true ? 'on' : ''"
          >
            <button type="button" @click="changeDisplay('list')">관리대장</button>
          </div>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div
        v-show="displayGraph === true"
        id="container"
        class="graph_wrap"
        style="text-align: center;"
      >
        <img src="/img/sample/xr_sample_chart.png" style="width: 40%;"/>
      </div>
    </div>

    <div v-show="displayList === true" class="dash_admin_box_table">
      <table>
        <thead>
          <tr>
              <th width="90">처리상태</th>
              <th>시험일</th>
              <th>시험번호</th>
              <th>시험구분</th>
              <th width="280">위치/부위</th>
              <th width="200">재료</th>
              <th>시험·검사항목</th>
              <th>시험성과</th>
              <th width="150">시험기준</th>
              <th width="100">판정</th>
              <th>작성자</th>
              <!-- 화렬님 작업해 주세요 -->
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.total === 0">
              <td colspan="11">검색 결과가 없습니다.</td>
            </tr>
            <tr v-for="(item, index) in pageParam.content" :key="index">
                <td>
                  {{ item.bookStatus }}
                </td>
                <td>
                  {{ item.testDay }}
                </td>
                <td>
                  {{ item.testNo }}
                </td>
                <td>
                  {{ item.division }}
                </td>
                <td v-html="enter2Br(item.location)" />
                <td>
                  {{ item.material }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.result }}
                </td>
                <td>
                  {{ item.standard }}
                </td>
                <td>
                  {{ item.judgment }}
                </td>
                <td>
                  {{ item.name }}
                </td>

                <!-- 화렬님 작업해 주세요 -->
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PopupMapObject",
  components: {},
  props: {
    siteId: {},
  },
  data: () => {
    return {
      companyList: [],
      pageParam: {
        content: [
          {
            bookStatus: "결재완로",
            testDay: '2024-01-06',
            testNo: 'A-103-03-01-001',
            division: '숏크리트시험',
            location: 'STA.9+396~386(2T/L양평) <br>STA.9+432~420(2T/L이천)',
            material: '25-27-210',
            name: '공기량',
            result: '2.5',
            standard: '2 ~ 3%',
            judgment: '합격'
          },
        {
            bookStatus: "결재완로",
            testDay: '2024-01-06',
            testNo: 'A-103-03-02-001',
            division: '숏크리트시험',
            location: 'STA.9+396~386(2T/L양평) <br>STA.9+432~420(2T/L이천)',
            material: '25-27-210',
            name: '염화물량',
            result: '0.032',
            standard: '0.3 ㎏/㎥이하',
            judgment: '합격'
          },
        {
            bookStatus: "결재완로",
            testDay: '2024-01-06',
            testNo: 'A-103-03-03-001',
            division: '숏크리트시험',
            location: 'STA.9+396~386(2T/L양평) <br>STA.9+432~420(2T/L이천)',
            material: '25-27-210',
            name: '슬럼프',
            result: '95',
            standard: '100mm ± 25mm',
            judgment: '합격'
          },
        {
            bookStatus: "결재완로",
            testDay: '2024-01-07',
            testNo: 'A-103-03-01-002',
            division: '숏크리트시험',
            location: 'STA.12+622~614지보(양평) 하부 <br>STA.12+628.4~625.4지보(이천) 상부',
            material: '25-27-210',
            name: '공기량',
            result: '2.5',
            standard: '2 ~ 3%',
            judgment: '합격'
          },
        {
            bookStatus: "결재완로",
            testDay: '2024-01-07',
            testNo: 'A-103-03-02-002',
            division: '숏크리트시험',
            location: 'STA.12+622~614지보(양평) 하부 <br>STA.12+628.4~625.4지보(이천) 상부',
            material: '25-27-210',
            name: '염화물량',
            result: '0.032',
            standard: '0.3 ㎏/㎥이하',
            judgment: '합격'
          },
          {
              bookStatus: "결재완로",
              testDay: '2024-01-07',
              testNo: 'A-103-03-03-001',
              division: '숏크리트시험',
              location: 'STA.12+622~614지보(양평) 하부 <br>STA.12+628.4~625.4지보(이천) 상부',
              material: '25-27-210',
              name: '슬럼프',
              result: '95',
              standard: '100mm ± 25mm',
              judgment: '합격'
          },
      ] },
      displayGraph: true,
      displayList: false,
    };
  },
  computed: {},
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    onClickExcel() {},
    close() {
      this.$emit("closePopUp");
    },
    changeDisplay(type) {
      if (type === "graph") {
        this.displayGraph = true;
        this.displayList = false;
      } else if (type === "list") {
        this.displayGraph = false;
        this.displayList = true;
      }
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}
</style>

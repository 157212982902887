<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">공지사항</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">공지 제목</p>
            <p class="input">
              <input
                v-model="param.title"
                type="text"
                placeholder="제목을 입력하세요."
                readonly
              />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">작성자</p>
            <p class="input">
              <input v-model="param.author" type="text" readonly />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">등록기관</p>
            <p class="input">
              <input v-model="param.companyName" type="text" readonly />
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">현장 선택</p>
            <p class="input">
              <label
                v-for="(item, index) in accessSiteList"
                :key="index"
                class="custom_checkbox"
                :for="item"
                style="pointer-events: none"
              >
                <input
                  :id="item"
                  v-model="selectSiteId"
                  :value="item.id"
                  type="checkbox"
                  style="pointer-events: none"
                />
                <span>{{ item.name }} &nbsp </span>
              </label>
            </p>
          </div>
        </div>

        <div class="form_item">
          <div class="edit_tool">
            <vue-editor
              id="noticeEditor"
              :editor-toolbar="customToolbar"
              v-model="param.content"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">닫기</button>
      </div>
    </div>
  </div>
</template>
<script>
import { USER_NAME } from "../../const";
import BoardApi from "../../api/qbox/BoardApi";
export default {
  name: "PopupModifyNotice",
  components: {},
  props: {},
  data: () => {
    return {
      isShow: false,
      item: {},
      param: {
        type: "notice",
        sysType: "qtest",
        title: "",
        content: "",
        fileId: null,
        author: localStorage.getItem(USER_NAME),
      },
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
      ],
      accessSiteList: [],
      selectSiteId: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.isShow = false;
    },
    open(item) {
      this.param = item;
      this.accessSiteList = item.accessSiteList;
      this.selectSiteId = item.selectSiteId;
      this.isShow = true;
    },
  },
};
</script>

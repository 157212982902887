import { httpClient } from "../../network/httpclient/HttpClientFactory";

const create = (param) =>
  httpClient.post(`/confirm-request`, param).then((res) => res.data);
const remove = (id) =>
  httpClient.delete(`/confirm-request/${id}`).then((res) => res.data);
const list = (id) =>
  httpClient.get(`/confirm-request/${id}`).then((res) => res.data);
export default {
  create,
  remove,
  list,
};

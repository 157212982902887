<template>
  <div v-if="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form action="">
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
            </div>
          </form>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div id="container" class="graph_wrap square">
        <div class="grid-container">
          <div
            class="grid-item"
            style="display: grid"
            v-for="(option, index) in optionList"
            :key="index"
          >
            <v-chart
              ref="chart1"
              class="chart"
              :option="option"
              autoresize
              style="min-height: 400px"
            />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="(xAxisData, index) in option.xAxis[0].data"
                    :key="index"
                  >
                    {{ xAxisData }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(legendData, legendIndex) in option.legend.data"
                  :key="legendIndex"
                >
                  <td>{{ legendData }}</td>
                  <td
                    v-for="(seriesData, seriesIndex) in option.series[
                      legendIndex
                    ].data"
                    :key="seriesIndex"
                  >
                    {{ seriesData }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { getToday } from "../utils/date";
import { mapGetters, mapActions, mapState } from "vuex";
import TestBookApi from "../api/qbox/TestBookApi";
import SiteApi from "../api/qbox/SiteApi";

import PlanCountApi from "../api/qbox/PlanCountApi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
]);

export default {
  name: "PopupMaterialGraph",
  components: { VChart, DatePicker },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      optionList: [],
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        beginDate: "",
        endDate: getToday(),
      },
      testItemList: {},
      firstCode: null,
      accessSiteList: [],
      selectSiteId: null,
      siteYearPlanList: [],
      testItemDepth1: null,
      option: {},
      optionMonth: {},
      testItemName: [],
      testItemTotal: [],
      testItemMonth: [],
      testItemPlanCount: [],
      displayGraph: true,
      displayList: false,
      graphTypeCount: true,
      graphTypeMonth: false,
      isShow: false,
      siteName: [],
      inside: [],
      request: [],
      remicon: [],
      shortCrete: [],
      specList: null,
      inCount: null,
      outCount: null,
      failCount: null,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      const year = new Date().getFullYear();
      this.listParam.beginDate = year + "-01-01";
      this.getSiteList();
      await this.graphCountMaterial();
      await this.setup();
    },
    getToday: getToday,
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
      console.log(this.accessSiteList);
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchTestBook();
    },

    changeSite() {
      this.monthStatistic();
    },

    pageCallback(page) {
      this.listParam.page = page;
      this.fetchTestBook();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },
    codeChange() {
      this.pageParam = { content: [] };
      this.listParam.itemCode = this.firstCode;
      this.fetchTestBook();
    },

    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },

    changeDisplay(type) {
      if (type === "graph") {
        this.displayGraph = true;
        this.displayList = false;
      } else if (type === "list") {
        this.displayGraph = false;
        this.displayList = true;
      }
    },
    changeGraphType(type) {
      if (type === "count") {
        this.graphTypeCount = true;
        this.graphTypeMonth = false;
      } else if (type === "month") {
        this.graphTypeCount = false;
        this.graphTypeMonth = true;
      }
    },

    async graphCountMaterial() {
      const result = await PlanCountApi.graphCountMaterial(this.listParam);
      let map = new Map();
      map = result;
      this.specList = new Map();
      this.inCount = new Map();
      this.outCount = new Map();
      this.failCount = new Map();

      let list = [];
      let inList = [];
      let outList = [];
      let failList = [];

      Object.keys(map).forEach((item) => {
        map[item].forEach((spec) => {
          list.push(spec.materialName);
          inList.push(spec.inQuantity);
          outList.push(spec.outQuantity);
          failList.push(spec.failQuantity);
        });
        this.specList[item] = list;
        this.inCount[item] = inList;
        this.outCount[item] = outList;
        this.failCount[item] = failList;
        list = [];
        inList = [];
        outList = [];
        failList = [];
      });
    },
    setup() {
      const labelOption = {
        show: false,
        position: "inside",
        distance: 5,
        align: "middle",
        verticalAlign: "middle",
        rotate: 90,
        formatter: "{c}",
        fontSize: "10",
        fontFamily: "Open Sans",
        fontWeight: "100",
        rich: {
          name: {
            fontFamily: "Noto Sans Korean",
            fontSize: "15",
            fontWeight: "400",
          },
        },
      };

      this.optionList = [];
      this.accessSiteList.forEach((site) => {
        const option = {
          title: {
            text: site.name,
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 20,
              color: "#ffffff",
            },
            backgroundColor: "rgba(30,110,235,1)",
            borderRadius: 50,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: ["반입량", "출고량"],
            textStyle: {
              color: "#fff",
              fontSize: "15",
              fontWeight: "500",
              fontFamily: "Noto Sans Korean",
            },
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                color: "#fff",
                fontSize: "15",
                fontWeight: "400",
                fontFamily: "Noto Sans Korean",
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.2)",
                  type: "dashed",
                },
              },
            },
          ],
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              data: this.specList[site.name],
              axisLabel: {
                rotate: 45,
                color: "#fff",
                fontSize: "15",
                fontWeight: "100",
                fontFamily: "Noto Sans Korean",
                padding: [5, 0, 0, 0],
              },
              gridLines: {
                color: "rgba(255, 255, 255, 0.4)",
                lineWidth: 1,
              },
            },
          ],
          series: [
            {
              name: "반입량",
              type: "bar",
              barGap: 0,
              emphasis: {
                focus: "series",
              },
              data: this.inCount[site.name],
              label: labelOption,
            },
            {
              name: "출고량",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              data: this.outCount[site.name],
              label: labelOption,
            },
          ],
        };
        this.optionList.push(option);
      });
    },

    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
    detail(item) {
      console.log(item);
      //TODO 팝업 테스트
      this.$refs.popupDetail.open(item);
    },
    test() {
      console.log("sefsef");
    },
    async onClickSearch() {
      await this.graphCountMaterial();
      this.setup();
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}
td {
  font-size: 1.2rem !important;
}
</style>

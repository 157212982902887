<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap" style="display: flex">
        <div class="search_filter">
          <form>
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="changeSite"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="changeSite"
              />
            </div>
            <select
              v-model="selectSiteId"
              class="search_option"
              @change="changeSite()"
            >
              <option
                v-for="item in accessSiteList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </form>
        </div>
      </div>

      <!-- 검색 Wrap //-->
      <div id="container" class="graph_wrap oneitem" @click="clickChart">
        <v-chart
          class="chart"
          :option="option"
          @click="clickChart"
          autoresize
        />
      </div>
      <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th v-for="(xAxisData, index) in option.xAxis.data" :key="index">
                {{ xAxisData }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(legendData, legendIndex) in option.legend.data"
              :key="legendIndex"
            >
              <td>{{ legendData }}</td>
              <td
                v-for="(seriesData, seriesIndex) in option.series[legendIndex]
                  .data"
                :key="seriesIndex"
              >
                {{ addCommasToNumber(checkData(seriesData)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import Pagination from "./layout/Pagination.vue";
import { getToday, toDayFormatAddDay } from "../utils/date";
import { mapGetters, mapActions, mapState } from "vuex";
import TestItemApi from "../api/qbox/TestItemApi";
import TestBookApi from "../api/qbox/TestBookApi";
import SiteApi from "../api/qbox/SiteApi";

import PlanCountApi from "../api/qbox/PlanCountApi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import PopupModifyNoticeVue from "./detail/PopupCreateNotice.vue";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
]);

export default {
  name: "PopupStatisticGraph",
  components: { Pagination, DatePicker, VChart, PopupModifyNoticeVue },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        beginDate: "",
        endDate: getToday(),
      },
      testItemList: {},
      firstCode: null,
      accessSiteList: [],
      selectSiteId: null,
      siteYearPlanList: [],
      testItemDepth1: null,
      option: {},
      optionMonth: {},
      testItemName: [],
      testItemTotal: [],
      testItemMonth: [],
      testItemPlanCount: [],
      displayGraph: true,
      displayList: false,
      graphTypeCount: true,
      graphTypeMonth: false,
      isShow: false,
      totalPlanCount: [],
      totalActualCount: [],
      siteType: "",
      siteYearPlanList: [],
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      this.selectSiteId = this.siteId;
      const response = await SiteApi.getSiteYearPlan(this.siteId);
      this.siteYearPlanList = response;
      if (this.siteYearPlanList.length > 0) {
        const today = this.getToday();
        const findSiteYear = this.siteYearPlanList.filter(
          (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
        );
        if (findSiteYear.length > 0) {
          this.listParam.beginDate = findSiteYear[0].beginDay;
        } else {
          this.listParam.beginDate = toDayFormatAddDay(-7);
        }
      } else {
        const today = new Date();
        this.listParam.beginDate = this.dayFormatNewDate(
          new Date(today.getFullYear(), 0, 1)
        );
      }

      this.mainStatistic();

      this.getSiteList();
    },
    getToday: getToday,
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
      this.siteType = response[0].type;
    },

    changeSite() {
      this.mainStatistic();
    },

    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },

    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },

    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    async mainStatistic(param) {
      let result = null;
      if (!param) {
        result = await PlanCountApi.graphStatisticYear(
          this.selectSiteId,
          this.listParam
        );
      } else {
        this.listParam.testItemId = param;
        result = await PlanCountApi.graphStatisticYearByDepth(
          this.selectSiteId,
          this.listParam
        );
      }

      const nowYear = new Date().getFullYear();

      this.totalActualCount = [];
      this.totalPlanCount = [];

      var nameList = [];
      var keyList = Object.keys(result);
      var planCount = new Map();
      var totalCount = new Map();

      var planData = [];
      var totalData = [];

      var legendData = ["누적계획", "누적실적"];
      keyList.forEach((item, index) => {
        if (item == nowYear) {
          legendData.push(item + "계획");
          legendData.push(item + "실적");
        }
      });

      Object.keys(result).forEach((item, itemIndex) => {
        result[item].forEach((list, index) => {
          if (list.name === "civil") list.name = "토목";
          if (list.name === "construct") list.name = "건축";
          nameList.push(list.name);
          if (itemIndex != 0) {
            this.totalPlanCount[index] =
              list.planCount + this.totalPlanCount[index];
            this.totalActualCount[index] =
              list.totalCount + this.totalActualCount[index];
          } else {
            this.totalPlanCount.push(list.planCount);
            this.totalActualCount.push({ value: list.totalCount, id: list.id });
          }
          planData.push(list.planCount);
          totalData.push({ value: list.totalCount, id: list.id });
        });
        planCount[item] = planData;
        totalCount[item] = totalData;
        planData = [];
        totalData = [];
      });

      var seriesData = [
        {
          data: this.totalPlanCount,
          type: "line",
          name: "누적계획",
          barWidth: "10%",
          showBackground: true,
          emphasis: {
            focus: "series",
          },
          // areaStyle: {
          //   opacity: 0.3,
          // },
          itemStyle: {
            color: "#e71d7a",
          },
        },
        {
          data: this.totalActualCount,
          type: "bar",
          name: "누적실적",
          barWidth: "10%",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.15)",
          },
          itemStyle: {
            color: "#e71d7a",
          },
          emphasis: {
            focus: "series",
          },
          stack: "actual",
        },
      ];

      legendData.forEach((item, index) => {
        if (index > 1) {
          const year = item.substring(0, 4);
          const type = item.substring(4);
          if (type === "계획") {
            seriesData.push({
              data: planCount[year],
              type: "line",
              name: item,
              barWidth: "10%",
              showBackground: true,
              emphasis: {
                focus: "series",
              },
              // areaStyle: {
              //   opacity: 0.3,
              // },
              itemStyle: {
                color: "#0044a4",
              },
            });
          } else {
            seriesData.push({
              data: totalCount[year],
              type: "bar",
              name: item,
              barWidth: "10%",
              showBackground: true,
              // backgroundStyle: {
              //   color: "rgba(180, 180, 180, 0.15)",
              // },
              itemStyle: {
                color: "#0044a4",
              },
              emphasis: {
                focus: "series",
              },
            });
          }
        }
      });

      const set = new Set(nameList);
      const newNameList = [...set];

      if (this.siteType === "HOUSE") {
        legendData.forEach((item, index) => {
          if (item.includes("계획")) {
            legendData.splice(index, 1);
          }
        });
        seriesData.forEach((item, index) => {
          if (item.name.includes("계획")) {
            seriesData.splice(index, 1);
          }
        });
      }
      this.setup(legendData, newNameList, seriesData);
    },

    setup(legendData, newNameList, seriesData) {
      this.option = {
        grid: {
          top: 40,
          bottom: 50,
          left: 30,
          right: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow",
          },
          data: newNameList,
          boundaryGap: true,
          axisLabel: {
            rotate: 20,
            interval: 0,
            // X축 폰트 크기
            show: true,
            fontSize: 8,
            // color: "rgba(255, 255, 255, 0.5)",
            color: "#8D8D8D",
            textStyle: {
              fontSize: 12,
              fontWeight: "bolder",
            },
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 12,
            color: "rgba(255, 255, 255, 0.5)",
            // margin: -10,
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        legend: {
          data: legendData,
          textStyle: {
            color: "#ccc",
            fontSize: 20,
            fontFamily: "sans-serif",
          },
        },
        series: seriesData,
      };
      console.log(this.option);
    },
    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
    async clickChart(param) {
      console.log(param);
      if (param.componentType) {
        param.event.event.stopPropagation(); //상위 태그로 클릭이벤트 전달을 막아줌
        this.mainStatistic(param.data.id);
        this.listParam.testItemId = param.data.id;
      } else {
        this.mainStatistic();
        console.log("배경클릭");
      }
    },
    checkData(item) {
      if (!item) return 0;
      if (typeof item === "object") {
        return item.value;
      }
      return item;
    },
    addCommasToNumber(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}
.graph_wrap.oneitem {
  height: calc(100vh - 450px) !important;
}
.dash_admin_box_table {
  height: 100%;
}
</style>

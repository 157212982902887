<template>
  <div v-show="isShow" class="dash_admin_wrap">
    <div class="dash_admin_inner">
      <div class="dash_admin_content">
        <div class="dash_admin_left">
          <div class="dash_admin_head">
            <p class="title">통계 데이터 관리</p>
          </div>
          <ul>
            <li>
              <button
                type="button"
                :class="tabId === 1 ? 'on' : ''"
                @click="onClickTab(1)"
              >
                ESG 통계
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 2 ? 'on' : ''"
                @click="onClickTab(2)"
              >
                공정별 통계
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 3 ? 'on' : ''"
                @click="onClickTab(3)"
              >
                시험별 통계
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 4 ? 'on' : ''"
                @click="onClickTab(4)"
              >
                실적 통계
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 5 ? 'on' : ''"
                @click="onClickTab(5)"
              >
                콘크리트 타설량통계
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 6 ? 'on' : ''"
                @click="onClickTab(6)"
              >
                숏크리트 타설량통계
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 7 ? 'on' : ''"
                @click="onClickTab(7)"
              >
                콘크리트 강도값분포
              </button>
            </li>

            <li>
              <button
                type="button"
                :class="tabId === 8 ? 'on' : ''"
                @click="onClickTab(8)"
              >
                철근사용량
              </button>
            </li>
          </ul>
        </div>
        <div class="dash_admin_cont">
          <popup-esg-count
            ref="tab-1"
            :site-id="siteId"
            :countParam="countParam"
          />
          <popup-count-graph ref="tab-2" :site-id="siteId" />
          <popup-division-count-graph ref="tab-3" :site-id="siteId" />
          <popup-statistic-graph ref="tab-4" :site-id="siteId" />
          <popup-conc-amount ref="tab-5" :site-id="siteId" />
          <popup-short-conc-amount ref="tab-6" :site-id="siteId" />
          <popup-scatter-graph ref="tab-7" :site-id="siteId" />
          <popup-material-graph ref="tab-8" :site-id="siteId" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopupCountGraph from "./PopupCountGraph.vue";
import PopupDivisionCountGraph from "./PopupDivisionCountGraph.vue";

import PopupConcAmount from "./PopupConcAmount.vue";
import PopupShortConcAmount from "./PopupShortConcAmount.vue";
import PopupEsgCount from "./PopupEsgCount.vue";
import PopupScatterGraph from "./PopupScatterPour.vue";

import PopupMaterialGraph from "./PopupMaterialGraph.vue";
import PopupStatisticGraph from "./PopupStatistcGraph.vue";

export default {
  name: "PopupDashAdmin",
  components: {
    PopupCountGraph,
    PopupDivisionCountGraph,
    PopupConcAmount,
    PopupEsgCount,
    PopupScatterGraph,
    PopupShortConcAmount,
    PopupMaterialGraph,
    PopupStatisticGraph,
  },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    currentPopup: {
      type: String,
      default: "",
    },
    countParam: {
      type: Array,
    },
  },
  data: () => {
    return {
      isShow: false,
      tabId: 1,
    };
  },
  computed: {},
  created() {},
  mounted() {
    console.log(process.env.PROGRESS_DOMAIN);
  },
  beforeDestroy() {},
  methods: {
    toggle() {
      this.isShow = !this.isShow;
      if (this.isShow) this.showTab();
    },
    onClickTab(tabId) {
      this.tabId = tabId;
      this.showTab();
    },
    showTab() {
      const popupObj = this.$refs;
      for (const key in popupObj) {
        popupObj[key].close();
      }
      this.$refs[`tab-${this.tabId}`].open();
    },
  },
};
</script>

const getLang = (state) => {
  return state.lang;
};
const getStartTime = (state) => {
  return state.startTime;
};
const getEndTime = (state) => {
  return state.endTime;
};
const getPosition = (state) => {
  return state.position;
};
export default {
  getLang,
  getStartTime,
  getEndTime,
  getPosition,
};

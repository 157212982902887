<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">{{ title }}</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <div v-if="pdfSource">
        <PDFViewer
          :source="pdfSource"
          :controls="pdfControls"
          style="margin-top: 0px; height: 80vh; width: 50vw"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
export default {
  name: "PopupPdf",
  components: { PDFViewer },
  props: {},
  data: () => {
    return {
      isShow: true,
      pdfSource: "",
      title: "",
      pdfControls: ["rotate", "zoom", "catalog", "switchPage"],
    };
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.revisionList = [];
      this.qualityPlan = {};
      this.etcFiles = [];
      this.isShow = false;
    },
    open(file) {
      console.log(file);
      this.isShow = true;
      this.pdfViewFile(file);
    },
    pdfViewFile(file) {
      if (file) {
        this.pdfSource = this.makeFileUrl(file.fileId, file.fileNo);
        this.title = file.name;
      }

      console.log(this.pdfSource);
    },
    makeFileUrl(fileId, fileNo) {
      return (
        "/qbox/api/v1/history/download/file?" +
        "file_id=" +
        fileId +
        "&file_no=" +
        fileNo +
        "&v=" +
        Date.now()
      );
    },
  },
};
</script>

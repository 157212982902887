import { sync } from "vuex-router-sync";
import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";

import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";

import Mapbox from "mapbox-gl";
Vue.use(Mapbox);
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);
Vue.use(VueToast, {
  position: "bottom",
});

Vue.config.productionTip = false;

Vue.prototype.isConsoleDefine = false;

sync(store, router);

//console.log(store.getters.getLang);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

import MutationTypes from "../../MutationTypes";
export default {
  setMapBounds: async ({ commit }, param) => {
    commit(MutationTypes.MAP_BOUNDS, param);
  },
  showLoading: async ({ commit }) => {
    commit(MutationTypes.SET_IS_LOADING, true);
  },
  hideLoading: async ({ commit }) => {
    commit(MutationTypes.SET_IS_LOADING, false);
  },
};

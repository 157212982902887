import { httpClient } from "../../network/httpclient/HttpClientFactory";

const getDepthList = (depth, siteId, code) =>
  httpClient
    .get(`/test-items/${depth}/${siteId}`, { params: code })
    .then((res) => res.data);

export default {
  getDepthList,
};

<template v-if="0 < end">
  <div class="page_wrap">
    <p class="total_cnt">총 {{ addCommasToNumber(pageParam.total) }}건</p>
    <div class="button_box">
      <button
        v-if="pageParam.page > lstCnt"
        type="button"
        @click="page(prevPage)"
      >
        <img src="/img/common/page_pre.svg" alt="이전으로" />
      </button>
      <template v-for="start in end">
        <template v-if="nextPage < 12 && begin <= start">
          <div :key="start">
            <button
              type="button"
              :class="start == pageParam.page ? 'on' : ''"
              @click="page(start)"
            >
              {{ start }}
            </button>
          </div>
        </template>
        <template v-else>
          <div v-if="start >= prevPage + 10" :key="start">
            <button
              type="button"
              :class="start == pageParam.page ? 'on' : ''"
              @click="page(start)"
            >
              {{ start }}
            </button>
          </div>
        </template>
      </template>

      <button
        v-if="pageParam.page < nextPage && 0 < end"
        type="button"
        @click="page(nextPage)"
      >
        <img src="/img/common/page_next.svg" alt="다음으로" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "QboxPagination",
  components: {},
  props: {
    pageParam: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      prevPage: 0,
      nextPage: 0,
      start: 0,
      end: 0,
      totalPage: 0,
      lstCnt: 10,
      begin: 0,
    };
  },
  computed: {},
  watch: {
    pageParam: {
      immediate: true,
      deep: true,
      handler(val) {
        //this.pageParam = val;

        this.start = 0;
        this.end = 0;
        if (this.pageParam.total && this.pageParam.size) {
          this.totalPage =
            parseInt(val.total / val.size) + (val.total % val.size > 0 ? 1 : 0);

          const curPos1 =
            parseInt(val.page / this.lstCnt) +
            (val.page % this.lstCnt > 0 ? 1 : 0);
          if (curPos1 > 1)
            this.prevPage = parseInt(curPos1 - 2) * this.lstCnt + 1;

          var curPos2 = parseInt(
            parseInt(val.page / this.lstCnt) +
              parseInt(val.page % this.lstCnt > 0 ? 1 : 0)
          );
          if (curPos2 * this.lstCnt + 1 <= this.totalPage) {
            this.nextPage = curPos2 * this.lstCnt + 1;
          }

          if (this.totalPage < this.nextPage) {
            this.nextPage = this.totalPage;
          }

          this.start =
            (parseInt(val.page / this.lstCnt) +
              (val.page % this.lstCnt > 0 ? 1 : 0)) *
              this.lstCnt -
            (this.lstCnt - 1);
          this.end =
            (parseInt(val.page / this.lstCnt) +
              (val.page % this.lstCnt > 0 ? 1 : 0)) *
            this.lstCnt;

          if (this.end > this.totalPage) this.end = this.totalPage;

          this.begin = this.start;
        }
      },
    },
  },
  beforeCreate() {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    onChangeSize() {
      this.$emit("pageCallback", 1, this.pageParam.size);
    },
    page(page) {
      this.$emit("pageCallback", page, this.pageParam.size);
    },
    addCommasToNumber(number) {
      if (!number) return "";
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

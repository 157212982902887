<template>
  <div class="header">
    <div class="header_inner">
      <div class="logo_wrap" style="width: 250px">
        <img
          class="osm_logo"
          src="/img/common/login_title.svg"
          alt="on site monitoring login"
        />
      </div>
      <div class="logo_wrap">
        <h1 class="logo" v-show="siteGroupId == 5">
          <img src="/img/sea/bim_logo.svg" alt="Korea Expressway Service" />
        </h1>
        <p class="logo_info">{{ this.siteCstName }}</p>
      </div>
      <div class="header_info">
        <p class="info_date">{{ dayText }}</p>
        <p />
        <p class="info_week">{{ weekText }}</p>
        <p class="info_time">{{ timeText }}</p>
      </div>
      <p class="menu_btn">
        <button
          type="button"
          class="btn_menu1"
          :class="openMenu === 'setting' ? 'on' : 'off'"
          @click="onClickSetting"
        />
        <span class="menu_btn_name">문서</span>
      </p>
      <p class="menu_btn">
        <button
          type="button"
          class="btn_menu2"
          :class="openMenu === 'graph' ? 'on' : 'off'"
          @click="onClickGraph"
        />
        <span class="menu_btn_name">통계</span>
      </p>
      <p class="menu_btn">
        <button type="button" class="btn_menu3" @click="logOut()" />
        <span class="menu_btn_name">로그아웃</span>
      </p>
    </div>
  </div>
</template>
<script>
import SiteApi from "@/api/qbox/SiteApi";
import WeatherApi from "../../api/osm/WeatherApi";
import { getToday, toDayFormatMinusDay } from "../../utils/date";
export default {
  name: "OsmHeader",
  components: {},
  props: {
    siteId: {},
  },
  data: () => {
    return {
      htmlClock: "",
      accidentCount: 0,
      progress: 0,
      weatherCls: "icon1",
      weather: {
        tmn: "-",
        tmx: "-",
      },
      air: { pm10Value: "" },
      dayText: "0000.00.00",
      weekText: "?요일",
      timeText: "00:00:00",
      showTestBook: false,
      openMenu: null,
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
      },
      siteGroupId: localStorage.getItem("siteGroupId"),
      accessSiteList: [],
      siteCstName: "",
    };
  },
  computed: {},
  created() {
    this.getTime();
  },
  async mounted() {
    this.getSiteList();
    for (var i = 0; i < 100; i++) {
      clearInterval(i);
    }

    setInterval(this.getTime, 1000);

    this.getWeather();

    setInterval(() => {
      this.getWeather();
    }, 1000 * 60 * 60); //1 시간 단위
  },
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    toDayFormatMinusDay: toDayFormatMinusDay,
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
      this.siteCstName = response[0].cstName;
    },
    onClickSetting() {
      if (this.openMenu === "setting") {
        this.openMenu = null;
      } else if (this.openMenu === "graph") {
        this.$emit("openGraph");
        this.openMenu = "setting";
      } else {
        this.openMenu = "setting";
      }

      this.$emit("openSetting");
    },
    onClickGraph() {
      if (this.openMenu === "graph") {
        this.openMenu = null;
      } else if (this.openMenu === "setting") {
        this.$emit("openSetting");
        this.openMenu = "graph";
      } else {
        this.openMenu = "graph";
      }

      this.$emit("openGraph");
    },
    async getWeather() {
      const result = await WeatherApi.day(this.getToday().replaceAll("-", ""));

      if (result.content) {
        this.weather = result.content;
      } else {
        const result = await WeatherApi.day(
          this.toDayFormatMinusDay(1).replaceAll("-", "")
        );
        this.weather = result.content;
      }
      const airResult = await WeatherApi.air(this.getToday());

      if (airResult) {
        this.air = airResult.content;
      }

      if (result.content) {
        const displayType = result.content.displayType;
        if (displayType === "맑음") {
          this.weatherCls = "icon1";
        } else if (displayType === "흐림") {
          this.weatherCls = "icon4";
        } else if (displayType === "눈") {
          this.weatherCls = "icon9";
        } else if (displayType === "비") {
          this.weatherCls = "icon5";
        }
      }
    },

    getTime() {
      const date = new Date();
      const week = new Array(
        "일요일",
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일"
      );

      this.weekText = week[date.getDay()];
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      this.dayText = `${year}.${month < 10 ? `0${month}` : month}.${
        day < 10 ? `0${day}` : day
      }`;

      this.timeText = `${hours < 10 ? `0${hours}` : hours}:
            ${minutes < 10 ? `0${minutes}` : minutes}:
            ${seconds < 10 ? `0${seconds}` : seconds}`;
    },
    logOut() {
      this.$emit("onLogout");
    },
  },
  pad(number) {
    return number < 10 ? "0" + number : number;
  },
};
</script>

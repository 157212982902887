<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <select
              v-model="paramSiteId"
              class="search_option"
              @change="changeSite"
            >
              <option
                v-for="item in accessSiteList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>

            <select
              v-model="paramSpecId"
              class="search_option"
              @change="fetchList"
            >
              <option v-for="item in specList" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>

            <div class="search_button">
              <button type="button">검색</button>
            </div>
          </form>
        </div>
      </div>

      <!-- 검색 Wrap //-->

      <div class="dash_admin_box_table">
        <table class="ledger">
          <thead>
            <tr>
              <th width="40">구분</th>
              <th width="40">규격</th>
              <th width="40">단위</th>
              <th width="100">년월일</th>
              <th width="40">생산업체</th>
              <th width="40">보관장소</th>
              <th width="50">반입량</th>
              <th width="30">불합격량</th>
              <th width="30">출고량</th>
              <th width="30">잔량</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="list.length === 0">
              <td colspan="10">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="list.length !== 0">
              <tr v-for="(item, index) in list" :key="index">
                <td>
                  {{ inspectionType[item.inspectionType] }}
                </td>
                <td v-show="item.inspectionType !== 'total'">
                  {{ item.specName }}
                </td>
                <td v-show="item.inspectionType !== 'total'">
                  {{ item.unit }}
                </td>
                <td v-show="item.inspectionType !== 'total'">
                  {{ item.inDay }}
                </td>
                <td v-show="item.inspectionType !== 'total'">
                  {{ item.productionName }}
                </td>
                <td v-show="item.inspectionType !== 'total'">
                  {{ item.storageCode }}
                </td>

                <td v-show="item.inspectionType !== 'total'">
                  {{ item.inQuantity }}
                </td>
                <td v-show="item.inspectionType !== 'total'">
                  {{ item.failQuantity }}
                </td>
                <td v-show="item.inspectionType !== 'total'">
                  {{ item.outQuantity }}
                </td>
                <td v-if="item.inspectionType !== 'total'">
                  {{ item.cumulativeQuantity }}
                </td>
                <td v-else colspan="9">{{ item.cumulativeQuantity }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MaterialSpecApi from "../api/qbox/MaterialSpecApi";
import MaterialInspectionApi from "../api/qbox/MaterialInspectionApi";
import SiteApi from "../api/qbox/SiteApi";
export default {
  name: "PopupMaterialLedger",
  components: {},
  props: {
    siteId: {},
  },
  data: () => {
    return {
      isShow: false,
      accessSiteList: [],
      selectSiteId: [],
      specList: [],
      list: [],
      paramSiteId: null,
      paramSpecId: null,
      totalCount: 0,
      inspectionType: {
        inspection: "반입",
        invoice: "출고",
        total: "현재잔량",
      },
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    async init() {
      this.paramSiteId = this.siteId;
      this.getSpecList();
      this.getSiteList();
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },
    getSpecList() {
      MaterialSpecApi.specList(this.paramSiteId).then((res) => {
        this.specList = res;
        if (res.length > 0) {
          this.paramSpecId = this.specList[0].id;
          this.fetchList();
        }
      });
    },
    changeSite() {
      this.getSpecList();
    },
    fetchList() {
      MaterialInspectionApi.ledger(this.paramSiteId, this.paramSpecId).then(
        (res) => {
          this.totalCount = 0;
          res.forEach((data) => {
            if (!data.failQuantity) data.failQuantity = 0;
            data.cumulativeQuantity =
              Number(data.inQuantity) -
              Number(data.failQuantity) -
              Number(data.outQuantity) +
              Number(this.totalCount);
            data.cumulativeQuantity = data.cumulativeQuantity.toFixed(3);
            this.totalCount = data.cumulativeQuantity;
          });

          this.list = [
            { inspectionType: "total", cumulativeQuantity: this.totalCount },
            ...res,
          ];
        }
      );
    },
    isNormalRow(item) {
      if (item.inspectionType !== "total") return true;
      return false;
    },
    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
  },
};
</script>

<template>
  <div v-if="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form action="">
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
            </div>
            <!-- <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div> -->
          </form>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div id="container" class="graph_wrap square">
        <div class="grid-container">
          <div
            class="grid-item"
            style="display: grid"
            v-for="(option, index) in optionList"
            :key="index"
            @click="clickChart($event, index)"
          >
            <v-chart
              ref="chart1"
              class="chart"
              :option="option"
              autoresize
              style="min-height: 400px"
              @click="clickChart($event, index)"
            />
            <table>
              <thead>
                <tr>
                  <th
                    v-for="(data, index) in option.series[0].data"
                    :key="index"
                  >
                    {{ data.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    v-for="(data, index) in option.series[0].data"
                    :key="index"
                  >
                    {{ addCommasToNumber(data.value) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import SiteApi from "@/api/qbox/SiteApi";
import PlanCountApi from "../api/qbox/PlanCountApi";
import { mapGetters, mapActions, mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import { getToday } from "../utils/date";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
]);

export default {
  name: "PopupCountGraph",
  components: { VChart, DatePicker },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      accessSiteList: [],
      optionList: [],
      listParam: {
        beginDate: "",
        endDate: getToday(),
      },
      siteYearPlanList: [],

      isShow: false,
      dataList: null,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    getToday: getToday,
    async init() {
      const year = new Date().getFullYear();
      this.listParam.beginDate = year + "-01-01";
      const response = await SiteApi.getSiteYearPlan(this.siteId);
      this.siteYearPlanList = response;
      if (this.siteYearPlanList.length > 0) {
        const today = this.getToday();
        const findSiteYear = this.siteYearPlanList.filter(
          (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
        );
        if (findSiteYear.length > 0) {
          this.listParam.beginDate = findSiteYear[0].beginDay;
        } else {
          this.listParam.beginDate = toDayFormatAddDay(-7);
        }
      } else {
        const today = new Date();
        this.listParam.beginDate = this.dayFormatNewDate(
          new Date(today.getFullYear(), 0, 1)
        );
      }
      this.getSiteList();
      this.selectSiteId = this.siteId;

      await this.graphCount();
      this.setup();
    },

    setup() {
      this.optionList = [];

      this.accessSiteList.forEach((site) => {
        const option = {
          title: {
            text: site.name,
            left: "center",
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 20,
              color: "#ffffff",
            },
            top: 0,
            padding: [8, 30],
            backgroundColor: "rgba(30,110,235,1)",
            borderRadius: 50,
          },
          legend: {
            top: 50,
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 15,
              color: "#ffffff",
            },
          },
          tooltip: {},
          series: [
            {
              type: "pie",
              radius: [50, 140],
              center: ["50%", "58%"],

              itemStyle: {
                borderRadius: 8,
              },
              label: {
                color: "#fff",
                position: "outside",
                fontSize: 16,
                fontWeight: "500",
                fontFamily: "Noto Sans Korean",
              },
              data: this.dataList[site.name],
            },
          ],
        };
        console.log(option);
        this.optionList.push(option);
      });
    },

    open() {
      this.isShow = true;
      this.init();
    },
    close() {
      this.isShow = false;
    },
    detail(item) {
      console.log(item);
      //TODO 팝업 테스트
      this.$refs.popupDetail.open(item);
    },

    async graphCount() {
      const result = await PlanCountApi.graphCount(this.listParam);
      let map = new Map();
      map = result;

      this.dataList = new Map();

      let list = [];

      for (let i = 0; i < this.accessSiteList.length; i++) {
        map[this.accessSiteList[i].id].forEach((element) => {
          if (element.codeName === "civil") element.codeName = "토목";
          if (element.codeName === "construct") element.codeName = "건축";

          if (element.totalCount !== 0) {
            list.push({
              value: element.totalCount,
              name: element.codeName,
              id: element.id,
            });
          }
        });

        this.dataList[map[this.accessSiteList[i].id][0].siteName] = list;
        list = [];
      }
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },
    async onClickSearch() {
      await this.graphCount();
      this.setup();
    },
    async clickChart(param, index) {
      if (param.componentType) {
        param.event.event.stopPropagation(); //상위 태그로 클릭이벤트 전달을 막아줌
        const testItemId = param.data.id;
        const site = this.accessSiteList[index];
        let option = this.optionList[index];
        this.listParam.testItemId = testItemId;
        this.listParam.siteId = site.id;
        const result = await PlanCountApi.graphCountByDepth(this.listParam);

        if (!result[site.id]) {
          this.$toast.info("하위 시험이 존재 하지 않습니다");
          return;
        }

        let list = [];

        result[site.id].forEach((item) => {
          if (item.totalCount !== 0) {
            list.push({
              value: item.totalCount,
              name: item.codeName,
              id: item.id,
            });
          }
        });

        option = {
          title: {
            text: site.name,
            left: "center",
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 20,
              color: "#ffffff",
            },
            top: 0,
            padding: [8, 30],
            backgroundColor: "rgba(30,110,235,1)",
            borderRadius: 50,
          },
          legend: {
            top: 50,
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 15,
              color: "#ffffff",
            },
          },
          tooltip: {},
          series: [
            {
              type: "pie",
              radius: [50, 140],
              center: ["50%", "58%"],

              itemStyle: {
                borderRadius: 8,
              },
              label: {
                color: "#fff",
                position: "outside",
                fontSize: 16,
                fontWeight: "500",
                fontFamily: "Noto Sans Korean",
              },
              data: list,
            },
          ],
        };

        this.$set(this.optionList, index, option);
      } else {
        const site = this.accessSiteList[index];
        let option = this.optionList[index];
        option.series = [
          {
            type: "pie",
            radius: [50, 140],
            center: ["50%", "58%"],

            itemStyle: {
              borderRadius: 8,
            },
            label: {
              color: "#fff",
              position: "outside",
              fontSize: 16,
              fontWeight: "500",
              fontFamily: "Noto Sans Korean",
            },
            data: this.dataList[site.name],
          },
        ];
        this.$set(this.optionList, index, option);
      }
    },
    addCommasToNumber(number) {
      if (!number) return "";
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2열 */
  grid-template-rows: 1fr 1fr; /* 2행 */
  height: 110%; /* 부모의 높이를 100%로 설정 */
  width: 100%; /* 필요에 따라 너비 설정 */
  overflow: auto;
}

.grid-item {
  border: 1px solid #bfb2b2; /* 경계선을 보여주기 위해 추가 */
}
</style>

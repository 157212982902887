<template>
  <div v-if="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form action="">
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
            </div>
            <!-- <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div> -->
          </form>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div id="container" class="graph_wrap square">
        <div class="grid-container one-column">
          <div
            class="grid-item"
            style="display: contents"
            v-for="(option, index) in optionList"
            :key="index"
          >
            <v-chart
              ref="bachart"
              id="chart"
              style="min-height: 400px"
              class="chart"
              :option="option"
              @click="specGraphClick($event, index)"
            />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="(data, index) in option.series[0].data"
                    :key="index"
                  >
                    {{ checkData(data) }}
                  </th>
                  <th>총계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>타설량</td>
                  <td
                    v-for="(data, index) in option.series[0].data"
                    :key="index"
                  >
                    {{ addCommasToNumber(data.value) }}
                  </td>
                  <td>{{ addCommasToNumber(total(option.series[0].data)) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import SiteApi from "@/api/qbox/SiteApi";
import { mapGetters, mapActions, mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
]);
import PlanCountApi from "../api/qbox/PlanCountApi";
import { getToday } from "../utils/date";
export default {
  name: "PopupCountGraph",
  components: { VChart, DatePicker },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      listParam: {
        beginDate: "",
        endDate: getToday(),
      },
      accessSiteList: [],
      optionList: [],
      isShow: false,
      specList: null,
      specGroupList: null,
      companyList: null,
      companyGroupList: null,
      map: new Map(),
      selectedSpec: null,
      selectedCompany: null,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      const year = new Date().getFullYear();
      this.listParam.beginDate = year + "-01-01";
      this.getSiteList();
      this.selectSiteId = this.siteId;
      await this.graphCountPour();
      this.setup();
    },

    setup() {
      this.optionList = [];
      this.accessSiteList.forEach((site) => {
        let specList = "";
        if (this.specGroupList[site.name]) {
          specList = this.specGroupList[site.name].map((it) => it.name);
        }

        const option = {
          title: {
            text: site.name,
            left: "center",
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 20,
              color: "#ffffff",
            },
            top: 0,
            padding: [8, 30],
            backgroundColor: "rgba(30,110,235,1)",
            borderRadius: 50,
          },
          tooltip: {},
          grid: [
            {
              top: 55,
              width: "60%",
              height: "85%",
              left: 50,
              containLabel: true,
            },
          ],
          xAxis: [
            {
              type: "value",
              splitLine: {
                show: false,
              },
              axisLabel: {
                fontFamily: "Noto Sans Korean",
                fontWeight: "500",
                fontSize: "14",
              },
              axisLine: {
                show: true,
              },
              axisTick: {
                show: true,
              },
            },
          ],
          yAxis: [
            {
              type: "category",
              data: specList,

              axisLabel: {
                formatter: function (value) {
                  if (value.length > 10) {
                    return `{customStyle|${value}}`;
                  }
                  return value;
                },
                rich: {
                  customStyle: {
                    fontSize: 8,
                    fontFamily: "Noto Sans Korean",
                    fontWeight: "500",
                  },
                },
                interval: 0,
                rotate: 30,
                fontFamily: "Noto Sans Korean",
                fontWeight: "500",
                fontSize: "14",
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              type: "bar",
              stack: "chart",
              barWidth: "24",
              label: {
                position: "right",
                show: true,
                textStyle: {
                  color: "#fff",
                  fontFamily: "Noto Sans Korean",
                  fontWeight: "500",
                  fontSize: "15",
                },
              },

              data: this.specGroupList[site.name],
            },
            {
              type: "pie",
              radius: [0, "40%"],
              center: ["82%", "50%"],
              label: {
                position: "outside",
                fontSize: 16,
                fontWeight: "500",
                fontFamily: "Noto Sans Korean",
              },
              data: this.companyGroupList[site.name],
            },
          ],
        };
        this.optionList.push(option);
      });
    },
    open() {
      this.isShow = true;
      this.init();
    },
    close() {
      this.isShow = false;
    },
    detail(item) {
      this.$refs.popupDetail.open(item);
    },
    test() {
      console.log("sefsef");
    },
    async graphCountPour() {
      const result = await PlanCountApi.graphCountPour(
        "normal",
        this.listParam
      );
      this.map = result;

      this.specList = new Map();
      this.specGroupList = new Map();
      this.companyList = new Map();
      this.companyGroupList = new Map();

      let list = [];
      let companyList = [];
      let sum = 0;
      for (let i = 0; i < this.accessSiteList.length; i++) {
        this.map[this.accessSiteList[i].id].sumSpecification.forEach(
          (element) => {
            list.push({
              value: element.actualQuantity,
              name: element.specification,
              company: element.company,
              first: true,
            });
            sum += element.actualQuantity;
          }
        );

        sum = 0;
        this.map[this.accessSiteList[i].id].sumCompany.forEach((element) => {
          companyList.push({
            value: element.actualQuantity,
            name: element.company,
            spec: element.spec,
          });
        });
        let companyGroupListTmp = companyList.reduce((acc, item) => {
          // 그룹화 키(name)로 현재 아이템 찾기
          const existingItem = acc.find((i) => i.name === item.name);

          if (existingItem) {
            // 기존 아이템이 있으면 count를 합산
            existingItem.value += item.value;
          } else {
            // 기존 아이템이 없으면 새로운 객체 추가
            acc.push({ ...item });
          }

          return acc;
        }, []);

        let specGroupListTmp = list.reduce((acc, item) => {
          // 그룹화 키(name)로 현재 아이템 찾기
          const existingItem = acc.find((i) => i.name === item.name);

          if (existingItem) {
            // 기존 아이템이 있으면 count를 합산
            existingItem.value += item.value;
          } else {
            // 기존 아이템이 없으면 새로운 객체 추가
            acc.push({ ...item });
          }

          return acc;
        }, []);

        if (this.map[this.accessSiteList[i].id].sumSpecification[0]) {
          this.specList[
            this.map[this.accessSiteList[i].id].sumSpecification[0].siteName
          ] = list;
          this.companyList[
            this.map[this.accessSiteList[i].id].sumSpecification[0].siteName
          ] = companyList;
          this.companyGroupList[
            this.map[this.accessSiteList[i].id].sumSpecification[0].siteName
          ] = companyGroupListTmp;
          this.specGroupList[
            this.map[this.accessSiteList[i].id].sumSpecification[0].siteName
          ] = specGroupListTmp;
        }
        list = [];
        companyList = [];
        companyGroupListTmp = [];
        specGroupListTmp = [];
      }
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },
    specGraphClick(param, index) {
      let firstChart = true;

      if (param.name === "총 계") return;
      const site = this.accessSiteList[index];
      let option = this.optionList[index];
      let filterCompanyList = this.companyGroupList[site.name];
      let filterSpecList = this.specGroupList[site.name];

      let customColor = [
        "#5470C6",
        "#91CC75",
        "#EE6666",
        "#73C0DE",
        "#3BA272",
        "#FCCE10",
        "#E87C25",
        "#B5C334",
        "#FE8463",
        "#9BCA63",
        "#FAD860",
        "#F3A43B",
        "#60C0DD",
        "#D7504B",
        "#C6E579",
        "#F4E001",
        "#F0805A",
        "#26C0C0",
      ];

      if (param.componentSubType === "bar") {
        if (this.selectedSpec !== param.name) {
          this.selectedSpec = param.name;

          filterCompanyList = this.companyList[site.name].filter(
            (company) => company.spec === this.selectedSpec
          );

          filterSpecList = this.specList[site.name].filter(
            (company) => company.name === this.selectedSpec
          );
          filterSpecList.forEach((item) => {
            item.first = false;
            firstChart = false;
          });
        }
      } else {
        if (this.selectedCompany !== param.name) {
          this.selectedCompany = param.name;

          filterCompanyList = this.companyGroupList[site.name].filter(
            (company) => company.name === this.selectedCompany
          );

          filterSpecList = this.specList[site.name].filter(
            (company) => company.company === this.selectedCompany
          );
          filterSpecList.forEach((item) => {
            item.first = true;
            firstChart = true;
          });
        }
      }

      console.log(filterSpecList);

      option = {
        title: {
          text: site.name,
          left: "center",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 20,
            color: "#ffffff",
          },
          top: 0,
          padding: [8, 30],
          backgroundColor: "rgba(30,110,235,1)",
          borderRadius: 50,
        },
        tooltip: {},
        grid: [
          {
            top: 55,
            width: "60%",
            height: "85%",
            left: 50,
            containLabel: true,
          },
        ],

        xAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLabel: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: "14",
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            data: filterSpecList.map((it) => {
              if (it.first === false) {
                return it.name + "\n" + it.company;
              } else {
                return it.name;
              }
            }),
            // data: filterSpecList.map((it) => it.name),

            axisLabel: {
              formatter: function (value) {
                // if (value.length > 10) {
                //   return `{customStyle|${value}}`;
                // }
                return value;
              },
              rich: {
                customStyle: {
                  fontSize: 8,
                  fontFamily: "Noto Sans Korean",
                  fontWeight: "500",
                },
              },
              interval: 0,
              rotate: 30,
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: "14",
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: filterSpecList.map((it) => {
              if (it.first === false) {
                return it.name + "\n" + it.company;
              } else {
                return it.name;
              }
            }),
            type: "bar",
            stack: "chart",
            barWidth: "24",
            label: {
              position: "right",
              show: true,
              textStyle: {
                color: "#fff",
                fontFamily: "Noto Sans Korean",
                fontWeight: "500",
                fontSize: "15",
              },
            },
            data: filterSpecList,
          },
          {
            type: "pie",
            radius: [0, "40%"],
            center: ["82%", "50%"],
            label: {
              position: "outside",
              fontSize: 16,
              fontWeight: "500",
              fontFamily: "Noto Sans Korean",
            },
            data: filterCompanyList,
          },
        ],
      };

      if (firstChart === false) {
        option.color = customColor;
        option.series[0].itemStyle = {
          color: (params) => {
            return option.color[params.dataIndex % option.color.length];
          },
        };
      }
      this.$set(this.optionList, index, option);
    },
    async onClickSearch() {
      await this.graphCountPour();
      this.setup();
    },
    checkData(data) {
      if (data.first === true) {
        return data.name;
      } else if (data.first === false) {
        return data.name + "(" + data.company + ")";
      }
    },
    addCommasToNumber(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total(data) {
      let sum = 0;
      data.forEach((item) => {
        sum += item.value;
      });
      return sum;
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}

.grid-item {
  border: 1px solid #bfb2b2; /* 경계선을 보여주기 위해 추가 */
}
table {
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>

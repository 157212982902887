<template>
  <div v-if="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->

      <!-- 검색 Wrap //-->
      <div id="container" class="graph_wrap square">
        <div class="grid-container">
          <div class="grid-item" v-for="(item, index) in saveItem" :key="index">
            <div class="option_wrap">
              <div class="right_area">
                <div class="download_button graph1 on">
                  <button type="button">{{ item.siteName }}</button>
                </div>
              </div>
            </div>
            <!-- <v-chart ref="chart1" class="chart" :option="option1" /> -->
            <table>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>A4용지</th>
                  <th>물</th>
                  <th>탄소</th>
                  <th>30년 나무</th>
                  <th>BOX</th>
                </tr>
                <tr>
                  <th>기준</th>
                  <th>1장</th>
                  <th>10L</th>
                  <th>2.88g</th>
                  <th>10만L당 1그루</th>
                  <th>1박스 2,500장</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>절감량</td>
                  <td>{{ addCommasToNumber(item.paper) }} 장</td>
                  <td>{{ addCommasToNumber(item.water) }} L</td>
                  <td>{{ item.carbon }} kg</td>
                  <td>{{ item.tree }} 그루</td>
                  <td>{{ item.box }} 박스</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import { mapGetters, mapActions, mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  LineChart,
  BarChart,
  PieChart,
  PictorialBarChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
  PictorialBarChart,
]);

export default {
  name: "PopupEsgCount",
  components: { VChart },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
    countParam: {
      type: Array,
    },
  },
  data: () => {
    return {
      option1: {
        title: {
          text: "1공구",
        },
      },
      option2: {
        title: {
          text: "2공구",
        },
      },
      option3: {
        title: {
          text: "3공구",
        },
      },
      option4: {
        title: {
          text: "4공구",
        },
      },

      isShow: false,
      lineCount: 10,
      beginCount: 0,
      saveItem: [],
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      this.saveItem = [];
      this.countParam.forEach((element) => {
        const paper =
          element.insideCount * 2 +
          element.requestCount * 5 +
          element.remiconCount * 10;
        const water = paper * 10;
        const carbon = Math.ceil(paper * 2.88 * 0.001);
        const tree = water * 0.00001;
        const box = paper / 2500;
        this.saveItem.push({
          siteName: element.siteName,
          paper: paper,
          water: water,
          carbon: carbon,
          tree: tree.toFixed(2),
          box: box.toFixed(1),
        });
      });
      this.saveItem.sort((a, b) => a.siteName.localeCompare(b.siteName));
      this.selectSiteId = this.siteId;
      // this.setup();
    },
    addCommasToNumber(number) {
      if (!number) return "";
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    //fake data
    makeCategoryData() {
      var categoryData = [];
      for (var i = 0; i < 10; i++) {
        categoryData.push(i + "a");
      }
      return categoryData;
    },
    makeSeriesData(count, negative) {
      const lineCount = 10;
      const v100 = 1;
      // 100장 단위로 count 저정
      const r = (count - v100 + 1) * 10;

      const seriesData = [];
      for (let i = 0; i < lineCount; i++) {
        const sign = negative
          ? -1 * (i % 3 ? 0.9 : 1)
          : 1 * ((i + 1) % 3 ? 0.9 : 1);
        seriesData.push({
          value:
            sign *
            (count <= v100 + 1
              ? Math.abs(i - lineCount / 2 + 0.5) < lineCount / 5
                ? 5
                : 0
              : (lineCount - Math.abs(i - lineCount / 2 + 0.5)) * r),
          symbolOffset: i % 2 ? ["50%", 0] : undefined,
        });
      }
      return seriesData;
    },
    setup() {
      const treeDataURI =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAA2CAYAAADUOvnEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA5tJREFUeNrcWE1oE0EUnp0kbWyUpCiNYEpCFSpIMdpLRTD15s2ePHixnj00N4/GoyfTg2fbiwdvvagHC1UQ66GQUIQKKgn1UAqSSFua38b3prPJZDs7s5ufKn0w7CaZ2W/fe9/73kyMRqNB3Nrj1zdn4RJ6du9T2u1a2iHYSxjP4d41oOHGQwAIwSUHIyh8/RA8XeiXh0kLGFoaXiTecw/hoTG4ZCSAaFkY0+BpsZceLtiAoV2FkepZSDk5EpppczBvpuuQCqx0YnkYcVVoqQYMyeCG+lFdaGkXeVOFNu4aEBalOBk6sbQrQF7gSdK5JXjuHXuYVIVyr0TZ0FjKDeCs6km7JYMUdrWAUVmZUBtmRnVPK+x6nIR2xomH06R35ggwJPeofWphr/W5UjPIxq8B2bKgE8C4HVHWvg+2gZjXj19PkdFztY7bk9TDCH/g6oafDPpaoMvZIRI5WyMB/0Hv++HkpTKE0kM+A+h20cPAfN4GuRyp9G+LMTW+z8rCLI8b46XO9zRcYZTde/j0AZm8WGb3Y2F9KLlE2nqYkjFLJAsDOl/lea0q55mqxXcL7YBc++bsCPMe8mUyU2ZIpnCoblca6TZA/ga2Co8PGg7UGUlEDd0ueptglbrRZLLE7poti6pCaWUo2pu1oaYI1CF9b9cCZPO3F8ikJQ/rPpQT5YETht26ss+uCIL2Y8vHwJGpA96GI5mjOlaKhowUy6BcNcgIhDviTGWCGFaqEuufWz4pgcbCh+w0gEOyOjTlTtYYlIWPYWKEsLDzOs+nhzaO1KEpd+MXpOoTUgKiNyhdy5aSMPNVqxtSsJFgza5EWA4zKtCJ2OGbLn0JSLu8+SL4G86p1Fpr7ABXdGFF/UTD4rfmFYFw4G9VAJ9SM3aF8l3yok4/J6IV9sDVb36ynmtJ2M5+CwxTYBdKNMBaocKGV2nYgkz6r+cHBP30MzAfi4Sy+BebSoPIOi8PW1PpCCvr/KOD4k9Zu0WSH0Y0+SxJ2awp/nlwKtcGyHOJ8vNHtRJzhPlsHr8MogtlVtwUU0tSM1x58upSKbfJnSKUR07GVMKkDNfXpzpv0RTHy3nZMVx5IOWdZIaPabGFvfpwpjnvfmJHXLaEvZUTseu/TeLc+xgAPhEAb/PbjO6PBaOTf6LQRh/dERde23zxLtOXbaKNhfq2L/1fAOPHDUhOpIf5485h7l+GNHHiSYPKE3Myz9sFxoJuAyazvwIMAItferha5LTqAAAAAElFTkSuQmCC";

      // Basic option:
      const option = {
        title: {
          left: "center",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 20,
            color: "#ffffff",
          },
          top: 0,
          padding: [8, 30],
          backgroundColor: "rgba(30,110,235,1)",
          borderRadius: 50,
        },
        color: ["#e54035"],
        xAxis: {
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          name: "0",
          nameLocation: "middle",
          nameGap: 40,
          nameTextStyle: {
            color: "#73a73a",
            fontSize: 35,
            fontWeight: "500",
            fontFamily: "Open Sans",
          },
          min: -2800,
          max: 2800,
        },
        yAxis: {
          data: this.makeCategoryData(),
          show: false,
        },
        grid: {
          top: "center",
          height: 280,
        },
        series: [
          {
            name: "all",
            type: "pictorialBar",
            symbol: "image://" + treeDataURI,
            center: ["50%", "55%"],
            symbolSize: [30, 55],
            symbolRepeat: true,
            data: this.makeSeriesData(),
            animationEasing: "elasticOut",
          },
          {
            name: "all",
            type: "pictorialBar",
            symbol: "image://" + treeDataURI,
            center: ["50%", "55%"],
            symbolSize: [30, 55],
            symbolRepeat: true,
            data: this.makeSeriesData(),
            animationEasing: "elasticOut",
          },
        ],
      };
      // Make fake data.

      // Set dynamic data.
      const option2 = JSON.parse(JSON.stringify(option));
      const option3 = JSON.parse(JSON.stringify(option));
      const option4 = JSON.parse(JSON.stringify(option));

      this.option1 = {
        ...option,
        title: {
          ...option.title,
          ...this.option1.title,
        },
      };

      this.option2 = {
        ...option2,
        title: {
          ...option2.title,
          ...this.option2.title,
        },
      };

      this.option3 = {
        ...option3,
        title: {
          ...option3.title,
          ...this.option3.title,
        },
      };

      this.option4 = {
        ...option4,
        title: {
          ...option4.title,
          ...this.option4.title,
        },
      };

      this.updatePaperSum();
    },
    open() {
      this.isShow = true;
      this.init();
    },
    close() {
      this.isShow = false;
    },
    detail(item) {
      console.log(item);
      //TODO 팝업 테스트
      this.$refs.popupDetail.open(item);
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    },
    updatePaperSum() {
      var data1 = 0;
      var data2 = 0;
      var data3 = 0;
      var data4 = 0;

      this.countParam.forEach((element) => {
        var bookData = 0;
        bookData = Math.ceil(
          (element.testBookApproval + element.testBookWaiting) * 0.001
        );
        if (element.siteName === "1공구") data1 = bookData;
        if (element.siteName === "2공구") data2 = bookData;
        if (element.siteName === "3공구") data3 = bookData;
        if (element.siteName === "4공구") data4 = bookData;
        console.log(bookData);
      });
      // const data1 = 3;
      // const data2 = 12;
      // const data3 = 29;
      // const data4 = 7;

      var count1 = 0;
      var count2 = 0;
      var count3 = 0;
      var count4 = 0;

      //이거 count 를 다 채우면 setInterval 에 대한 이벤트를 지워야 함

      const intervalId = setInterval(() => {
        if (count1 >= data1) {
          count1 = data1;
        } else {
          count1 += 1;
        }

        if (count2 >= data2) {
          count2 = data2;
          // clearInterval(intervalId);
        } else {
          count2 += 1;
        }

        if (count3 >= data3) {
          count3 = data3;
          // clearInterval(intervalId);
        } else {
          count3 += 1;
        }

        if (count4 >= data4) {
          count4 = data4;
        } else {
          count4 += 1;
        }

        this.option1.xAxis.name = count1;
        this.option1.series[0].data = this.makeSeriesData(count1);
        this.option1.series[1].data = this.makeSeriesData(count1, true);

        this.option2.xAxis.name = count2;
        this.option2.series[0].data = this.makeSeriesData(count2);
        this.option2.series[1].data = this.makeSeriesData(count2, true);

        this.option3.xAxis.name = count3;
        this.option3.series[0].data = this.makeSeriesData(count3);
        this.option3.series[1].data = this.makeSeriesData(count3, true);

        this.option4.xAxis.name = count4;
        this.option4.series[0].data = this.makeSeriesData(count4);
        this.option4.series[1].data = this.makeSeriesData(count4, true);
      }, 500);
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2열 */
  grid-template-rows: 1fr 1fr; /* 2행 */
  height: 130%; /* 부모의 높이를 100%로 설정 */
  width: 100%; /* 필요에 따라 너비 설정 */
}

.grid-item {
  border: 1px solid #bfb2b2; /* 경계선을 보여주기 위해 추가 */
}
</style>

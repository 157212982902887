import {
  httpClient,
  downloadClient,
} from "../../network/httpclient/HttpClientFactory";
import FileHelper from "@/helper/FileHelper";

const boardList = (param) =>
  httpClient.get(`/board`, { params: param }).then((res) => res.data);

const detail = (id) => httpClient.get(`/board/${id}`).then((res) => res.data);

const create = (param) =>
  httpClient.put("/board", param).then((res) => res.data);

const update = (id, param) =>
  httpClient.put(`/board/${id}`, param).then((res) => res.data);

const remove = (id) =>
  httpClient.delete(`/board/${id}`).then((res) => res.data);
export default {
  boardList,
  detail,
  create,
  update,
  remove,
};

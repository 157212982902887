import { httpClient } from "../../network/httpclient/HttpClientFactory";

const getSiteYearPlan = (siteId) =>
  httpClient.get(`/site/year-plan/${siteId}`).then((res) => res.data);

const getSiteList = () => httpClient.get(`/site/list`).then((res) => res.data);
const getSiteByConfirmCount = () =>
  httpClient.get(`/site/confirm-request`).then((res) => res.data);

export default {
  getSiteYearPlan,
  getSiteList,
  getSiteByConfirmCount,
};

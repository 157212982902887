import Vue from "vue";
import Router from "vue-router";
import Login from "../components/Login";
import DashBoard from "../components/DashBoard";
import PopupDivisionCountGraph from "../components/PopupDivisionCountGraph";
import PopupCountGraph from "../components/PopupCountGraph";
//import TestBook from "../components/TestBook";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      alias: "/login",
      component: Login,
    },
    {
      path: "/",
      name: "DashBoard",
      alias: "/main",
      component: DashBoard,
    },
    {
      path: "/popupDivisionCountGraph",
      name: "PopupDivisionCountGraph",
      alias: "/popupDivisionCountGraph",
      component: PopupDivisionCountGraph,
    },
    {
      path: "/popupCountGraph",
      name: "PopupCountGraph",
      alias: "/popupCountGraph",
      component: PopupCountGraph,
    },
  ],
  // mode: "history",
});

export default router;

import {
  httpClient,
  downloadClient,
  excelHttpClient,
} from "../../network/httpclient/HttpClientFactory";
import FileHelper from "@/helper/FileHelper";
const getMimeByExt = (fileName) => {
  var extToMimes = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    tif: "image/tif",
    ai: "image/ai",
    bmp: "image/bmp",
    pdf: "application/pdf",
    zip: "application/zip",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.ms-excel",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.ms-powerpoint",
    doc: "application/msword",
    docx: "application/msword",
    hwp: "application/x-hwp",
    mp4: "video/mp4",
    mov: "video/quicktime",
  };
  const ext = fileName.split(".").pop() || "";
  if (hasOwnProperty.call(extToMimes, ext.toLowerCase())) {
    return extToMimes[ext];
  }
  return "application/x-msdownload";
};

const historyFileList = (param) =>
  httpClient.get(`/history`, { params: param }).then((res) => res.data);

const qualityPlanFileList = (param) =>
  httpClient.get(`/history/quality`, { params: param }).then((res) => res.data);

const detail = (id) =>
  httpClient.get(`/history/quality/${id}`).then((res) => res.data);

const updateQuality = (id, param) =>
  httpClient.post(`/history/quality/${id}`, param).then((res) => res.data);

const updateHistory = (id, param) =>
  httpClient.post(`/history/${id}`, param).then((res) => res.data);

const getFiles = (id) =>
  httpClient
    .get(`/history/files`, { params: { fileId: id } })
    .then((res) => res.data);

const fileDown = (fileName, fileId, fileNo) => {
  downloadClient
    .get(`/history/download/file?file_id=${fileId}&file_no=${fileNo}`)
    .then((res) => {
      try {
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
        // not IE
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "_self";
        if (fileName) link.download = fileName;
        link.click();
      } catch (e) {
        console.error(e);
      }
    });
};

const multiDownload = (param, fileName) =>
  downloadClient.post("/history/multiDownload", param).then((res) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(res.data, fileName);
    } else {
      var a = document.createElement("a");
      var url = (window.URL || window.webkitURL).createObjectURL(res.data);
      a.href = url;
      try {
        a.download = fileName;
      } catch (e) {
        /* ignore for IE */
      }
      a.click();

      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  });

const excelDown = (url, title, param) =>
  excelHttpClient.post(url, param).then((res) => {
    const fileName = `${title}.xlsx`;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(res.data, title + fileName);
    } else {
      var a = document.createElement("a");
      var url = (window.URL || window.webkitURL).createObjectURL(res.data);
      a.href = url;
      try {
        a.download = fileName;
      } catch (e) {
        /* ignore for IE */
      }
      a.click();

      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  });

const statisticExcel = (param) =>
  httpClient.post(`/history/statistic/excel`, param).then((res) => res.data);

const deleteHistoryFile = (ids) =>
  httpClient.delete(`/history/delete`, { data: ids }).then((res) => res.data);

export default {
  historyFileList,
  qualityPlanFileList,
  detail,
  getFiles,
  fileDown,
  multiDownload,
  updateQuality,
  updateHistory,
  statisticExcel,
  excelDown,
  deleteHistoryFile,
};

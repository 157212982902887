<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <select
              v-model="selectSiteId"
              class="search_option"
              @change="changeSite()"
            >
              <option
                v-for="item in accessSiteList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>

            <div class="option_date w100">
              <input
                v-model="listParam.specification"
                type="text"
                placeholder="규격 검색"
                @keyup.enter="onClickSearch"
              />
            </div>

            <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div>
          </form>
        </div>
      </div>

      <!-- 검색 Wrap //-->

      <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="40">현장명</th>
              <th width="70">타설일자</th>
              <th width="200">타설위치</th>
              <th width="70">타설량</th>
              <th width="100">규격</th>
              <th width="100">양생방법</th>
              <th width="100">양생기간</th>
              <th width="100">압축강도</th>
              <th width="100">타설 시간</th>
              <th width="60">발주사확인</th>
              <th width="60">요청내역</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.length === 0">
              <td colspan="11">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.length !== 0">
              <tr v-for="(item, index) in pageParam" :key="index">
                <td>
                  {{ item.siteName }}
                </td>
                <td style="cursor: pointer; color: aqua" @click="detail(item)">
                  {{ item.pourDay }}
                </td>

                <td>
                  {{ item.location }}
                </td>
                <td>
                  {{ item.quantity }}
                </td>
                <td>
                  {{ item.specification }}
                </td>
                <td>
                  {{ item.curing }}
                </td>
                <td>
                  {{ item.curingPeriod }}
                </td>
                <td>
                  {{ item.strength }}
                </td>
                <td>
                  {{ sliceTime(item.beginTime) }} ~
                  {{ sliceTime(item.endTime) }}
                </td>
                <td
                  @click.stop="onClickConfirm(item)"
                  v-html="makeConfirmBtn(item)"
                />
                <td
                  @click.stop="onClickConfirmList(item)"
                  v-html="makeConfirmListBtn(item)"
                />
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <popup-weather-conc-detail ref="popupWeatherConcDetail" />
    <popup-confirm-list ref="popupConfirmList" />
    <popup-confirm ref="popupConfirm" @regConfirm="regConfirm" />
    <confirm :param="confirmParam" @confirmCallback="confirmCallback" />
  </div>
</template>
<script>
import { getToday, toDayFormatAddDay, dayFormat } from "../utils/date";
import { mapGetters } from "vuex";
import HistoryFileApi from "../api/qbox/HistoryFileApi";
import ColdConcBookApi from "../api/qbox/ColdConcBookApi";
import SiteApi from "../api/qbox/SiteApi";
import PopupWeatherConcDetail from "./detail/PopupWeatherConcDetail.vue";
import ConfirmRequestApi from "../api/qbox/ConfirmRequestApi";
import PopupConfirm from "./detail/PopupConfirm.vue";
import PopupConfirmList from "./detail/PopupConfirmList.vue";
import Confirm from "./Confirm.vue";

export default {
  name: "ColdWeatherConcList",
  components: {
    PopupConfirm,
    PopupConfirmList,
    PopupWeatherConcDetail,
    Confirm,
  },
  props: {
    siteId: {},
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 10,
        beginDate: toDayFormatAddDay(-7),
        endDate: getToday(),
        specification: null,
      },
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
        callbackParam: {},
      },
      isShow: false,
      allSelected: false,
      fileIds: [],
      accessSiteList: [],
      selectSiteId: null,
      confirmStatus: { writing: "미확인", confirm: "확인", change: "수정요청" },
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    dayFormat: dayFormat,
    async init() {
      this.selectSiteId = this.siteId;
      const response = await SiteApi.getSiteYearPlan(this.siteId);
      this.siteYearPlanList = response;
      if (this.siteYearPlanList.length > 0) {
        const today = this.getToday();
        const findSiteYear = this.siteYearPlanList.filter(
          (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
        );
        if (findSiteYear.length > 0) {
          this.listParam.beginDate = findSiteYear[0].beginDay;
        } else {
          this.listParam.beginDate = toDayFormatAddDay(-7);
        }
      }
      this.getSiteList();
      this.fetchHistoryFile();
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
      console.log(this.accessSiteList);
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchHistoryFile();
    },

    async fetchHistoryFile() {
      //TODO 구해야 됨
      this.listParam.siteId = this.selectSiteId;

      const response = await ColdConcBookApi.concBookList(this.listParam);
      console.log(response);
      response.size = this.listParam.size;
      this.pageParam = response;
      this.pageParam.forEach((item) => {
        // item.categoryNo = this.convertNumber(item.category);
        this.accessSiteList.forEach((site) => {
          if (item.siteId === site.id) {
            item.siteName = site.name;
          }
        });
      });
      // this.pageParam = this.pageParam.sort(
      //   (a, b) => a.categoryNo - b.categoryNo
      // );
    },
    convertNumber(category) {
      if (!category) return null;
      const index = category.indexOf("-");
      let result = null;
      if (index !== -1) {
        result = category.substring(0, index);
      } else {
        result = category;
      }

      return Number(result);
    },
    pageCallback(page) {
      this.listParam.page = page;
      this.fetchHistoryFile();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },

    sliceTime(time) {
      if (time) return time.slice(0, 5);
      return "";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
    selectAll() {
      this.fileIds = [];
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.pageParam.content.forEach((element) => {
          this.fileIds.push(element.fileId);
        });
      }
    },
    select() {
      this.allSelected = false;
    },
    detail(item) {
      //TODO 팝업 테스트
      // const item = null;
      this.$refs.popupWeatherConcDetail.open(item);
    },
    callViewPdf(file) {
      console.log("list");
      this.$emit("viewPdf", file);
    },
    changeSite() {
      this.fetchHistoryFile();
    },
    onClickConfirm(item) {
      if (item.confirmStatus === "writing") {
        this.confirmParam.item = item;
        this.confirmParam.msg = "확정처리 하시겠습니까?";
        this.confirmParam.isShow = true;
      } else if (item.confirmStatus === "change") {
        ConfirmRequestApi.list(item.confirmRequestId).then((res) => {
          item.msg = res[res.length - 1].msg;
          this.$refs.popupConfirm.open(item);
        });
      }
    },
    confirmCallback(item) {
      console.log(item);
      ColdConcBookApi.updateConcBook(item.id, {
        ...item,
        confirmStatus: "confirm",
      }).then(() => {
        this.fetchHistoryFile();
      });
    },
    onClickConfirmList(item) {
      if (!item.confirmRequestId) return;
      this.$refs.popupConfirmList.open(item);
    },
    makeConfirmBtn(item) {
      let labelClass = "detail";
      if (item.confirmStatus === "confirm") {
        labelClass = "approval";
      } else if (item.confirmStatus === "change") {
        labelClass = "waiting";
      }
      return `<a class='dayTest ${
        item.id === null ? "null" : "notnull"
      }  ${labelClass}'>${this.confirmStatus[item.confirmStatus]}</a>`;
    },
    makeConfirmListBtn(item) {
      if (!item.confirmRequestId) return;
      return `<a class='dayTest notnull detail'>보기</a>`;
    },
    regConfirm(item) {
      ColdConcBookApi.updateConcBook(item.id, {
        ...item,
        confirmStatus: "writing",
      }).then(() => {
        this.fetchHistoryFile();
      });
    },
    sliceTime(time) {
      if (time) return time.slice(0, 5);
      return "";
    },
  },
};
</script>

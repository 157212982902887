<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">수정요청 이력</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <table class="table">
        <thead class="table-header">
          <tr>
            <th width="200">요청일</th>
            <th width="200">내용</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <td>
              {{ item.requestDatetimeFn }}
            </td>
            <td v-html="brText(item.msg)" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmRequestApi from "../../api/qbox/ConfirmRequestApi";
export default {
  name: "PopupConfirmList",
  components: {},
  props: {},
  data: () => {
    return {
      isShow: false,
      confirmRequestId: null,
      list: [],
    };
  },
  computed: {
    ...mapState({}),
  },
  watch: {},

  beforeCreate() {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.isShow = false;
    },
    open(item) {
      if (item.confirmRequestId) {
        this.isShow = true;
        ConfirmRequestApi.list(item.confirmRequestId).then((res) => {
          res.map((data) => {
            data.requestDatetimeFn = this.formattedDatetime(
              data.requestDatetime
            );
          });
          this.list = res;
        });
      }
    },
    formattedDatetime(datetimeString) {
      const datetime = new Date(datetimeString);

      const year = datetime.getFullYear();
      const month = String(datetime.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더하고, 문자열로 변환하여 2자리로 만듭니다.
      const date = String(datetime.getDate()).padStart(2, "0"); // 일자를 2자리로 만듭니다.
      const hours = String(datetime.getHours()).padStart(2, "0"); // 시를 2자리로 만듭니다.
      const minutes = String(datetime.getMinutes()).padStart(2, "0"); // 분을 2자리로 만듭니다.
      const seconds = String(datetime.getSeconds()).padStart(2, "0"); // 초를 2자리로 만듭니다.

      const formattedDatetime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
      return formattedDatetime;
    },
    brText(text) {
      if (text) return text.replaceAll("\n", "<br>");
      return "";
    },
  },
};
</script>
<style scoped>
table.table tr {
  text-align: center;
}
.table {
  background: #adadad;
}
.table-header tr {
  background: antiquewhite;
  color: black;
}
.table td {
  border: 1px solid white;
  color: black;
}
table.table {
  font-size: 18px;
}
</style>

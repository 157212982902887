<template>
  <div class="dash_admin_wrap cont_popup">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <div class="dash_admin_box_head title">
        <p class="admin_box_title">{{ param.name }}</p>

        <button class="close_popup">
          <img src="/img/common/popup_close.svg" @click="close" />
        </button>
      </div>

      <!-- 검색 Wrap -->

      <div class="option_wrap">
        <div class="search_filter"></div>
        <div class="right_area">
          <div
            class="download_button"
            :class="displayGraph === true ? 'on' : ''"
          >
            <button type="button" @click="changeDisplay('graph')">
              그래프
            </button>
          </div>
          <div
            class="download_button"
            :class="displayList === true ? 'on' : ''"
          >
            <button type="button" @click="changeDisplay('list')">성적서</button>
          </div>
        </div>
        <div
          v-show="displayList === true"
          class="option_wrap"
          style="display: flex"
        >
          <div class="search_filter">
            <form>
              <div class="option_date">
                <date-picker
                  id="startedDate"
                  v-model="listParam.beginDate"
                  :lang="getLang"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                />
                <span>~</span>
                <date-picker
                  id="endedDate"
                  v-model="listParam.endDate"
                  :lang="getLang"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                />
              </div>

              <select
                v-model="firstCode"
                class="search_option"
                @change="codeChange()"
              >
                <option
                  v-for="item in testItemList"
                  :key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>

              <div class="option_date w100">
                <input
                  v-model="listParam.name"
                  type="text"
                  placeholder="작업자 검색"
                  @keyup.enter="onClickSearch"
                />
              </div>

              <div class="search_button">
                <button type="button" @click="onClickSearch">검색</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- 검색 Wrap //-->

      <div
        v-show="displayGraph === true && graphTypeCount === true"
        id="container"
        class="graph_wrap"
      >
        <v-chart class="chart" :option="option" autoresize />
      </div>

      <div
        v-show="displayGraph === true && graphTypeMonth === true"
        id="container"
        class="graph_wrap"
      >
        <v-chart class="chart" :option="optionMonth" autoresize />
      </div>

      <div v-show="displayGraph === true" class="option_wrap">
        <div class="search_filter">1</div>
        <div class="right_area">
          <div
            class="download_button"
            :class="graphTypeCount === true ? 'on' : ''"
          >
            <button type="button" @click="changeGraphType('count')">
              실적
            </button>
          </div>
          <div
            class="download_button"
            :class="graphTypeMonth === true ? 'on' : ''"
          >
            <button type="button" @click="changeGraphType('month')">
              월별추이
            </button>
          </div>
        </div>
      </div>

      <div v-show="displayList === true" class="dash_admin_box_head">
        <p class="admin_box_title">성적서 목록</p>
      </div>

      <div v-show="displayList === true" class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="90">처리상태</th>
              <th>시험일</th>
              <th>시험번호</th>
              <th>시험구분</th>
              <th width="280">위치/부위</th>
              <th>재료</th>
              <th>시험·검사항목</th>
              <th>시험성과</th>
              <th>시험기준</th>
              <th>판정</th>
              <th>작성자</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr v-if="pageParam.total === 0">
              <td colspan="11">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam.content" :key="index">
                <td>
                  {{ displayStatus(item.bookStatus) }}
                </td>
                <td>
                  {{ item.testDay }}
                </td>
                <td>
                  {{ item.testNo }}
                </td>
                <td>
                  {{ getTestTypeValue(item.division) }}
                </td>
                <td v-html="enter2Br(item.location)" />
                <td>
                  {{ item.material }}
                </td>
                <td>
                  {{ item.testItem.name }}
                </td>
                <td>
                  {{ item.result }}
                </td>
                <td>
                  {{ item.standard }}
                </td>
                <td>
                  {{ displayJudgment(item.judgment) }}
                </td>
                <td>
                  {{ item.name }}
                </td>

                <!-- 화렬님 작업해 주세요 -->
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <pagination
        v-show="displayList === true"
        :page-param="pageParam"
        :list-cnt="listParam.size"
        @pageCallback="pageCallback"
      />
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "./layout/Pagination.vue";
import { getToday, toDayFormatAddDay } from "../utils/date";
import { mapGetters } from "vuex";
import TestItemApi from "../api/qbox/TestItemApi";
import TestBookApi from "../api/qbox/TestBookApi";
import PlanCountApi from "../api/qbox/PlanCountApi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import SiteApi from "../api/qbox/SiteApi";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
]);

export default {
  name: "TestBookList",
  components: { Pagination, VChart, DatePicker },
  props: {
    siteId: {},
    param: {},
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 20,
        beginDate: toDayFormatAddDay(-7),
        endDate: getToday(),
        name: null,
      },
      testItemList: {},
      firstCode: null,
      option: {},
      optionMonth: {},
      testItemDepth2: null,
      testItemName: [],
      testItemTotal: [],
      testItemMonth: [],
      testItemPlanCount: [],
      displayGraph: true,
      displayList: false,
      graphTypeCount: true,
      graphTypeMonth: false,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {
    const response = await SiteApi.getSiteYearPlan(this.siteId);
    this.siteYearPlanList = response;
    if (this.siteYearPlanList.length > 0) {
      const today = this.getToday();
      const findSiteYear = this.siteYearPlanList.filter(
        (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
      );
      if (findSiteYear.length > 0) {
        this.listParam.beginDate = findSiteYear[0].beginDay;
      } else {
        this.listParam.beginDate = toDayFormatAddDay(-7);
      }
    }
    console.log(this.param);
    this.mainStatisticDepth2();
    this.monthStatisticDepth2();
    this.fetchTestBook();
    this.testItem();
  },
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchTestBook();
    },
    testItem() {
      TestItemApi.getDepthList(2, this.siteId, { code: this.param.code }).then(
        (res) => {
          console.log(res);
          this.testItemList = res;
          const defaultCode = { name: "공종전체", id: null, code: null };
          this.testItemList.unshift(defaultCode);
        }
      );
    },
    async fetchTestBook() {
      this.listParam.siteId = this.siteId;
      this.listParam.itemCode = this.param.code;
      if (this.firstCode !== null) {
        this.listParam.itemCode = this.firstCode;
      }
      const response = await TestBookApi.testBookList(this.listParam);
      response.size = this.listParam.size;
      this.pageParam = response;
      console.log(response);
    },
    pageCallback(page) {
      this.listParam.page = page;
      this.fetchTestBook();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },
    close() {
      this.$emit("closePopUp");
    },
    codeChange() {
      this.pageParam = { content: [] };
      this.listParam.itemCode = this.firstCode;
      this.fetchTestBook();
    },
    displayJudgment(judgment) {
      if (judgment === "pass") {
        return "합격";
      } else if (judgment === "fail") {
        return '<font color="red">불합격</font>';
      }
      return "-";
    },
    displayStatus(status) {
      if (status === "approval") return "결재완료";
      else return "결재대기";
    },
    getTestTypeValue(division) {
      if (division === "remicon") return "레미콘시험";
      else if (division === "inside") return "현장시험";
      else if (division === "request") return "의뢰시험";
      else return "-";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    async mainStatisticDepth2() {
      const response = await PlanCountApi.mainStatisticDepth2(
        this.siteId,
        this.param.code
      );
      this.testItemDepth2 = response;

      for (var item of this.testItemDepth2) {
        this.testItemName.push(item.name);
        this.testItemMonth.push(item.monthCount);
        this.testItemTotal.push(item.totalCount - item.monthCount);
        this.testItemPlanCount.push(item.planCount);
      }

      this.setup();
    },
    async monthStatisticDepth2() {
      const response = await PlanCountApi.monthlyCountDepth2(
        this.siteId,
        this.param.code
      );
      var xAxisData = [];
      var codeName = [];
      var seriesData = [];
      for (var item of response) {
        xAxisData.push(item.month);
        codeName.push(item.name);
      }

      const newXAxisData = xAxisData.filter(
        (v, i) => xAxisData.indexOf(v) === i
      );
      const newCodeName = codeName.filter((v, i) => codeName.indexOf(v) === i);

      for (let i = 0; i < newCodeName.length; i++) {
        seriesData.push({
          name: newCodeName[i],
          type: "line",
          // stack: "Total",
          // areaStyle: {},
          emphasis: {
            focus: "series",
          },

          data: [],
        });
      }

      for (let i = 0; i < newCodeName.length; i++) {
        for (var data of response) {
          if (seriesData[i].name === data.name) {
            seriesData[i].data.push(data.monthCount);
          }
        }
      }

      this.setupMonth(newXAxisData, newCodeName, seriesData);

      console.log(response);
    },
    changeDisplay(type) {
      if (type === "graph") {
        this.displayGraph = true;
        this.displayList = false;
      } else if (type === "list") {
        this.displayGraph = false;
        this.displayList = true;
      }
    },
    changeGraphType(type) {
      if (type === "count") {
        this.graphTypeCount = true;
        this.graphTypeMonth = false;
      } else if (type === "month") {
        this.graphTypeCount = false;
        this.graphTypeMonth = true;
      }
    },
    setup() {
      this.option = {
        grid: {
          top: 45,
          bottom: 70,
          left: 40,
          right: 30,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow",
          },
          data: this.testItemName,
          boundaryGap: true,
          axisLabel: {
            rotate: 20,
            interval: 0,
            // X축 폰트 크기
            show: true,
            fontSize: 8,
            // color: "rgba(255, 255, 255, 0.5)",
            color: "#8D8D8D",
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: "14",
            },
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: "14",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        legend: {
          data: ["계획", "누계", "금월"],
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: "16",
            color: "#ccc",
          },
        },
        series: [
          {
            data: this.testItemPlanCount,
            type: "line",
            name: "계획",
            barWidth: "10%",
            showBackground: true,
            emphasis: {
              focus: "series",
            },
            areaStyle: {
              opacity: 0.3,
            },
          },
          {
            data: this.testItemTotal,
            type: "bar",
            name: "누계",
            barWidth: "10%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.15)",
            },
            itemStyle: {
              color: "rgba(100, 255, 1, 0.6)",
            },
            emphasis: {
              focus: "series",
            },
            stack: "x",
          },
          {
            data: this.testItemMonth,
            type: "bar",
            name: "금월",
            barWidth: "10%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.15)",
            },
            itemStyle: {
              color: "rgba(185, 80, 245, 0.6)",
            },
            emphasis: {
              focus: "series",
            },
            stack: "x",
          },
        ],
      };
    },
    setupMonth(xAxisData, codeName, seriesData) {
      this.optionMonth = {
        grid: {
          top: 40,
          bottom: 50,
          left: 30,
          right: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow",
          },
          data: xAxisData,
          boundaryGap: true,
          axisLabel: {
            rotate: 20,
            interval: 0,
            // X축 폰트 크기
            show: true,
            fontSize: 8,
            // color: "rgba(255, 255, 255, 0.5)",
            color: "#8D8D8D",
            textStyle: {
              fontSize: 12,
              fontWeight: "bolder",
            },
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        legend: {
          data: codeName,
          textStyle: {
            color: "#ccc",
            fontSize: 20,
            fontFamily: "sans-serif",
          },
        },
        series: seriesData,
      };
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}
</style>

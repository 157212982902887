export const APPLICATION_VERSION = process.env.VERSION;
export const APPLICATION_PHASE = process.env.APPLICATION_PHASE || "REAL";

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiYW5kdGhlYmVlIiwiYSI6ImNsYWFyYjZoZDA5dTAzc3J6dHdoNjFtYmMifQ.5K1rLqbH-2HRzi-Ta5Zxyg";
export const MAPBOX_MAP_STYLE =
  "mapbox://styles/andthebee/clac74ycx000515s02xju213f";
export const MAPBOX_MAP_STYLE_SATELLITE =
  "mapbox://styles/andthebee/clac7368u000614mipr4qoc10";

export const POPUP_NAME = {
  ADMIN: "dash_admin",
  CALENDAR: "calendar",
  WORK_CALENDAR: "workCalendar",
  COMMUTE: "commute",
};

export const QBOX_URL = process.env.QBOX_URL;
export const FILE_URL = process.env.FILE_URL;

//LocalStorage
export const COMPANY = "company";
export const USER_NAME = "name";
export const JWT_TOKEN = "token";
export const JWT_REFRESH_TOKEN = "refreshtoken";
export const AUTH_TYPE = "authType";
export const USER_ID = "userId";
export const SITE_NAME = "siteName";
export const DEV_LEVEL = "devLevel";
export const COMPANY_NAME = "companyName";
export const COMPANY_LIST = "companyList";
export const HEADER_DEVICE_ID = "Device-Id";
export const HEADER_RE_TOKEN = "X-Refresh-Token";
export const SITE_ID_LIST = "siteIdList";
export const SITE_GROUP_ID = "siteGroupId";

export const LABEL_COLOR = [
  "#0f287d",
  "#7b240e",
  "#7b690e",
  "#2a7b0e",
  "#0e7b78",
  "#620e7b",
];

<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">총괄표 생성</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <div class="form_wrap">
        <div class="form_item">
          <div class="form_write">
            <p class="title">현장 선택</p>
            <p class="input">
              <select v-model="selectSiteId" class="option">
                <option
                  v-for="item in accessSiteList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <div class="form_item">
          <div class="form_write">
            <p class="title">시작일 / 종료일</p>
            <p class="input">
              <date-picker
                v-model="day"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                range
              />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">닫기</button>
        <button type="button" class="comfirm" @click="ok">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { USER_NAME } from "../../const";
import { getToday, toDayFormatAddDay } from "../../utils/date";
import { mapGetters, mapState } from "vuex";
export default {
  name: "PopupModifyNotice",
  components: { DatePicker },
  props: {},
  data: () => {
    return {
      isShow: false,
      item: {},
      param: {
        type: "notice",
        sysType: "qtest",
        title: "",
        content: "",
        fileId: null,
        author: localStorage.getItem(USER_NAME),
      },
      callBackParam: {
        countStyle: ["approval", "waiting"],
        selectSiteId: null,
        beginDate: null,
        endDate: null,
      },
      accessSiteList: [],
      selectSiteId: 8,
      day: [toDayFormatAddDay(-7), getToday()],
    };
  },
  computed: {},
  watch: {},
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    close() {
      this.isShow = false;
    },
    open(item) {
      this.day = [];
      this.day[0] = toDayFormatAddDay(-7);
      this.day[1] = getToday();
      this.accessSiteList = item;
      // this.param = item;
      // this.accessSiteList = item.accessSiteList;
      // this.selectSiteId = item.selectSiteId;
      this.isShow = true;
    },
    ok() {
      this.accessSiteList.forEach((Element) => {
        if (Element.id === this.selectSiteId) {
          this.callBackParam.siteName = Element.name;
        }
      });
      this.callBackParam.beginDate = this.day[0];
      this.callBackParam.endDate = this.day[1];
      this.callBackParam.selectSiteId = this.selectSiteId;
      this.isShow = false;
      this.$emit("excelCreate", this.callBackParam);
    },
  },
};
</script>

import {
  httpClient,
  downloadClient,
  excelHttpClient,
} from "../../network/httpclient/HttpClientFactory";

const concBookList = (param) =>
  httpClient
    .get(`/cold/concbook/list`, { params: param })
    .then((res) => res.data);

const concBookExcelData = (param) =>
  httpClient.get(`/cold/concbook/excelData/${param}`).then((res) => res.data);

const updateConcBook = (id, param) =>
  httpClient.post(`/cold/concbook/${id}`, param).then((res) => res.data);

const getFiles = (id) =>
  httpClient
    .get(`/cold/concbook/files`, { params: { fileId: id } })
    .then((res) => res.data);

export default {
  concBookList,
  concBookExcelData,
  updateConcBook,
  getFiles,
};

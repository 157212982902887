<template>
  <div v-if="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form action="">
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
            </div>
            <!-- <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div> -->
          </form>
        </div>
        <div class="right_area" style="margin-top: 10px">
          <div
            class="download_button graph1"
            :class="graphTypeCount === true ? 'on' : ''"
          >
            <button type="button" @click="changeGraphType('count')">
              실적
            </button>
          </div>
          <div
            class="download_button graph2"
            :class="graphTypeMonth === true ? 'on' : ''"
          >
            <button type="button" @click="changeGraphType('month')">
              월별추이
            </button>
          </div>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div
        id="container"
        class="graph_wrap oneitem"
        v-show="graphTypeCount === true"
      >
        <v-chart class="chart" :option="option" autoresize />
      </div>
      <div v-show="graphTypeCount === true" class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>현장시험</th>
              <th>의뢰시험</th>
              <th>레미콘시험</th>
              <th>숏크리트 시험</th>
              <th>총 계</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in option.xAxis[0].data" :key="index">
              <td>{{ data }}</td>
              <td>{{ addCommasToNumber(option.series[0].data[index]) }}</td>
              <td>{{ addCommasToNumber(option.series[1].data[index]) }}</td>
              <td>{{ addCommasToNumber(option.series[2].data[index]) }}</td>
              <td>{{ addCommasToNumber(option.series[3].data[index]) }}</td>
              <td>
                {{
                  addCommasToNumber(
                    option.series[0].data[index] +
                      option.series[1].data[index] +
                      option.series[2].data[index] +
                      option.series[3].data[index]
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        id="container"
        class="graph_wrap square"
        v-show="graphTypeMonth === true"
      >
        <div class="grid-container">
          <div
            class="grid-item"
            style="display: grid"
            v-for="(option, index) in optionList"
            :key="index"
          >
            <v-chart
              ref="chart1"
              class="chart"
              :option="option"
              style="min-height: 400px"
              autoresize
            />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="(xAxisData, index) in option.xAxis.data"
                    :key="index"
                  >
                    {{ xAxisData }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(legendData, indexLegend) in option.legend.data"
                  :key="indexLegend"
                >
                  <td>{{ legendData }}</td>
                  <td
                    v-for="(seriesData, indexSeries) in option.series[
                      indexLegend
                    ].data"
                    :key="indexSeries"
                  >
                    {{ addCommasToNumber(seriesData) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { getToday, toDayFormatAddDay } from "../utils/date";
import { mapGetters, mapActions, mapState } from "vuex";
import SiteApi from "../api/qbox/SiteApi";

import PlanCountApi from "../api/qbox/PlanCountApi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
]);

export default {
  name: "PopupDivisionCountGraph",
  components: { VChart, DatePicker },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      listParam: {
        beginDate: "",
        endDate: getToday(),
      },
      companyList: [],
      testItemList: {},
      firstCode: null,
      accessSiteList: [],
      selectSiteId: null,
      siteYearPlanList: [],
      testItemDepth1: null,
      option: {},
      testItemName: [],
      testItemTotal: [],
      testItemMonth: [],
      testItemPlanCount: [],
      displayGraph: true,
      displayList: false,
      graphTypeCount: true,
      graphTypeMonth: false,
      isShow: false,
      siteName: [],
      inside: [],
      request: [],
      remicon: [],
      shortCrete: [],
      sum: [],
      xAxisDataMonthly: null,
      divisionName: ["현장시험", "의뢰시험", "레미콘 시험", "숏크리트 시험"],
      insideMonthly: null,
      requestMonthly: null,
      remiconMonthly: null,
      shortCreteMonthly: null,
      seriesData: null,
      optionList: [],
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      const year = new Date().getFullYear();
      this.listParam.beginDate = year + "-01-01";
      this.getSiteList();
      this.graphCountDivision();
      this.graphCountDivisionMonthly();
      this.selectSiteId = this.siteId;

      // this.mainStatistic();
    },
    getToday: getToday,
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },

    async mainStatistic() {
      const response = await PlanCountApi.mainStatistic(this.selectSiteId);
      this.testItemDepth1 = response;

      this.testItemName = [];
      this.testItemMonth = [];
      this.testItemTotal = [];
      this.testItemPlanCount = [];

      for (var item of this.testItemDepth1) {
        this.testItemName.push(item.name);
        this.testItemMonth.push(item.monthCount);
        this.testItemTotal.push(item.totalCount - item.monthCount);
        this.testItemPlanCount.push(item.planCount);
      }

      this.setup();
    },

    changeGraphType(type) {
      if (type === "count") {
        this.graphTypeCount = true;
        this.graphTypeMonth = false;
      } else if (type === "month") {
        this.graphTypeCount = false;
        this.graphTypeMonth = true;
      }
    },
    async graphCountDivision() {
      this.siteName = [];
      this.inside = [];
      this.request = [];
      this.shortCrete = [];
      this.remicon = [];
      this.sum = [];
      const result = await PlanCountApi.graphCountDivision(this.listParam);
      result.forEach((item) => {
        this.siteName.push(item.siteName);
        this.inside.push(item.insideCount);
        this.request.push(item.requestCount);
        this.shortCrete.push(item.shortCreteCount);
        this.remicon.push(item.remiconCount);
        this.sum.push(
          item.insideCount +
            item.requestCount +
            item.shortCreteCount +
            item.remiconCount
        );
      });
      this.siteName = this.siteName.sort();
      this.setup();
    },

    async graphCountDivisionMonthly() {
      const result = await PlanCountApi.graphCountDivisionMonthly(
        this.listParam
      );
      console.log(result);
      this.xAxisDataMonthly = new Map();
      this.insideMonthly = new Map();
      this.requestMonthly = new Map();
      this.remiconMonthly = new Map();
      this.shortCreteMonthly = new Map();
      this.seriesData = new Map();

      let monthList = [];
      let insideList = [];
      let requestList = [];
      let remiconList = [];
      let shortList = [];
      let data = [];

      Object.keys(result).forEach((item) => {
        result[item].forEach((list) => {
          monthList.push(list.month);
          insideList.push(list.insideCount);
          requestList.push(list.requestCount);
          remiconList.push(list.remiconCount);
          shortList.push(list.shortCreteCount);
        });
        this.xAxisDataMonthly[item] = monthList;
        this.insideMonthly[item] = insideList;
        this.requestMonthly[item] = requestList;
        this.remiconMonthly[item] = remiconList;
        this.shortCreteMonthly[item] = shortList;
        monthList = [];
        insideList = [];
        requestList = [];
        shortList = [];
        remiconList = [];
        for (let i = 0; i < this.divisionName.length; i++) {
          data.push({
            name: this.divisionName[i],
            type: "line",
            emphasis: {
              focus: "series",
            },
            data: [],
          });
        }
        this.seriesData[item] = data;

        for (let i = 0; i < data.length; i++) {
          if (data[i].name === "현장시험") {
            data[i].data = this.insideMonthly[item];
          } else if (data[i].name === "의뢰시험") {
            data[i].data = this.requestMonthly[item];
          } else if (data[i].name === "레미콘 시험") {
            data[i].data = this.remiconMonthly[item];
          } else if (data[i].name === "숏크리트 시험") {
            data[i].data = this.shortCreteMonthly[item];
          }
        }
        data = [];
      });

      this.setupMonth();
    },
    setup() {
      const labelOption = {
        show: false,
        position: "inside",
        distance: 5,
        align: "middle",
        verticalAlign: "middle",
        rotate: 90,
        formatter: "{c}  {name|{a}}",
        fontSize: "22",
        fontFamily: "Open Sans",
        fontWeight: "500",
        rich: {
          name: {
            fontFamily: "Noto Sans Korean",
            fontSize: "15",
            fontWeight: "400",
          },
        },
      };

      this.option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["현장시험", "의뢰시험", "레미콘시험", "숏크리트시험", "총계"],
          textStyle: {
            color: "#fff",
            fontSize: "15",
            fontWeight: "500",
            fontFamily: "Noto Sans Korean",
          },
        },

        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.siteName,
            axisLabel: {
              color: "#fff",
              fontSize: "15",
              fontWeight: "400",
              fontFamily: "Noto Sans Korean",
              padding: [5, 0, 0, 0],
            },
            gridLines: {
              color: "rgba(255, 255, 255, 0.4)",
              lineWidth: 1,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#fff",
              fontSize: "15",
              fontWeight: "400",
              fontFamily: "Noto Sans Korean",
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.2)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "현장시험",
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: this.inside,
            label: labelOption,
          },
          {
            name: "의뢰시험",
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: this.request,
            label: labelOption,
          },
          {
            name: "레미콘시험",
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: this.remicon,
            label: labelOption,
          },
          {
            name: "숏크리트시험",
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: this.shortCrete,
            label: labelOption,
          },
        ],
      };
      console.log(this.option);
    },

    setupMonth() {
      this.optionList = [];
      this.accessSiteList.forEach((site) => {
        const option = {
          title: {
            text: site.name,
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 20,
              color: "#ffffff",
            },
            backgroundColor: "rgba(30,110,235,1)",
            borderRadius: 50,
            bottom: "auto",
          },
          grid: {
            top: 40,
            bottom: 50,
            left: 30,
            right: 0,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          xAxis: {
            type: "category",
            axisPointer: {
              type: "shadow",
            },
            data: this.xAxisDataMonthly[site.name],
            boundaryGap: true,
            axisLabel: {
              rotate: 20,
              interval: 0,
              // X축 폰트 크기
              show: true,
              fontSize: 8,
              // color: "rgba(255, 255, 255, 0.5)",
              color: "#8D8D8D",
              textStyle: {
                fontSize: 12,
                fontWeight: "bolder",
              },
            },
            axisLine: {
              // X축 라인 컬러
              lineStyle: {
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              // Y축 폰트 크기
              fontSize: 11,
              color: "rgba(255, 255, 255, 0.5)",
            },
            splitLine: {
              // 차트 중간선 컬러
              lineStyle: {
                color: "rgba(255, 255, 255, 0.1)",
              },
            },
          },
          legend: {
            data: this.divisionName,
            textStyle: {
              color: "#ccc",
              fontSize: 20,
              fontFamily: "sans-serif",
            },
          },
          series: this.seriesData[site.name],
        };
        console.log(option);
        this.optionList.push(option);
      });
    },

    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
    detail(item) {
      console.log(item);
      //TODO 팝업 테스트
      this.$refs.popupDetail.open(item);
    },
    test() {
      console.log("sefsef");
    },
    async onClickSearch() {
      this.graphCountDivision();
      this.graphCountDivisionMonthly();
    },
    addCommasToNumber(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}
.graph_wrap.oneitem {
  height: calc(100vh - 450px) !important;
}
.dash_admin_box_table {
  height: 100%;
}
</style>

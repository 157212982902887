<template>
  <div>
    <router-view :key="$route.fullPath" />
    <loading />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import loading from "./components/layout/Loading.vue";
export default {
  name: "App",
  components: { loading },
  data: () => {
    return {
      bodyCls: "",
    };
  },
  computed: {
    ...mapState({}),
  },
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: { ...mapActions([]) },
};
</script>

<template>
  <div v-if="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form action="">
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
            </div>
            <!-- <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div> -->
          </form>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div id="container" class="graph_wrap square">
        <div class="grid-container">
          <div
            class="grid-item"
            style="display: grid"
            v-for="(option, index) in optionList"
            :key="index"
          >
            <v-chart
              ref="chart1"
              class="chart"
              :option="option"
              autoresize
              style="min-height: 400px"
            />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="(data, index) in option.series[0].data"
                    :key="index"
                  >
                    {{ data.name }}
                  </th>
                  <th>총계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>타설량</td>
                  <td
                    v-for="(data, index) in option.series[0].data"
                    :key="index"
                  >
                    {{ addCommasToNumber(data.value) }}
                  </td>
                  <td>{{ addCommasToNumber(total(option.series[0].data)) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import SiteApi from "@/api/qbox/SiteApi";
import { mapGetters, mapActions, mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
]);
import PlanCountApi from "../api/qbox/PlanCountApi";
import { getToday } from "../utils/date";
export default {
  name: "PopupShortConcAmount",
  components: { VChart, DatePicker },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      listParam: {
        beginDate: "",
        endDate: getToday(),
      },
      accessSiteList: [],
      optionList: [],
      option1: {
        title: {
          text: "1공구",
        },
      },
      option2: {
        title: {
          text: "2공구",
        },
      },
      option3: {
        title: {
          text: "3공구",
        },
      },
      option4: {
        title: {
          text: "4공구",
        },
      },

      isShow: false,
      specList: null,
      companyList: null,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      const year = new Date().getFullYear();
      this.listParam.beginDate = year + "-01-01";
      this.getSiteList();
      this.selectSiteId = this.siteId;
      await this.graphCountPour();
      await this.setup();
    },

    setup() {
      const specList = [
        { value: 1048, name: "25-18-80", itemStyle: { color: "#0f329f" } },
        { value: 735, name: "25-21-150", itemStyle: { color: "#1929bb" } },
        { value: 580, name: "25-27-150", itemStyle: { color: "#0044a4" } },
        {
          value: 484,
          name: "25-27-150(터널용)",
          itemStyle: { color: "#145cb6" },
        },
        { value: 300, name: "25-30-40", itemStyle: { color: "#1d8af5" } },
        { value: 300, name: "25-40-150", itemStyle: { color: "#6ebaf1" } },
      ];

      const companyList = [
        { value: 17365, name: "영산", itemStyle: { color: "#312783" } },
        { value: 4079, name: "삼정", itemStyle: { color: "#e71d7a" } },
        { value: 6929, name: "쌍용", itemStyle: { color: "#98c73d" } },
      ];

      this.optionList = [];

      this.accessSiteList.forEach((site) => {
        let specList = "";
        if (this.specList[site.name]) {
          specList = this.specList[site.name].map((it) => it.name);
        }

        const option = {
          title: {
            text: site.name,
            left: "center",
            textStyle: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: 20,
              color: "#ffffff",
            },
            top: 0,
            padding: [8, 30],
            backgroundColor: "rgba(30,110,235,1)",
            borderRadius: 50,
          },
          tooltip: {},
          grid: [
            {
              top: 55,
              width: "60%",
              height: "85%",
              left: 0,
              containLabel: true,
            },
          ],
          xAxis: [
            {
              type: "value",
              splitLine: {
                show: false,
              },
              axisLabel: {
                fontFamily: "Noto Sans Korean",
                fontWeight: "500",
                fontSize: "14",
              },
              axisLine: {
                show: true,
              },
              axisTick: {
                show: true,
              },
            },
          ],
          yAxis: [
            {
              type: "category",
              data: specList,
              axisLabel: {
                interval: 0,
                rotate: 30,
                fontFamily: "Noto Sans Korean",
                fontWeight: "500",
                fontSize: "14",
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              type: "bar",
              stack: "chart",
              barWidth: "24",
              label: {
                position: "right",
                show: true,
                textStyle: {
                  color: "#fff",
                  fontFamily: "Noto Sans Korean",
                  fontWeight: "500",
                  fontSize: "15",
                },
              },
              data: this.specList[site.name],
            },
          ],
        };
        this.optionList.push(option);
      });
    },
    open() {
      this.isShow = true;
      this.init();
    },
    close() {
      this.isShow = false;
    },
    detail(item) {
      console.log(item);
      //TODO 팝업 테스트
      this.$refs.popupDetail.open(item);
    },
    test() {
      console.log("sefsef");
    },
    async graphCountPour() {
      const result = await PlanCountApi.graphCountPour(
        "shortCrete",
        this.listParam
      );
      let map = new Map();
      map = result;
      console.log(result);
      console.log(map[6]);

      this.specList = new Map();
      this.companyList = new Map();
      let list = [];
      let companyList = [];
      let sum = 0;
      for (let i = 0; i < this.accessSiteList.length; i++) {
        map[this.accessSiteList[i].id].sumSpecification.forEach((element) => {
          list.push({
            value: element.actualQuantity,
            name: element.specification,
          });
          sum += element.actualQuantity;
        });
        // list.push({
        //   value: sum,
        //   name: "총 계",
        //   itemStyle: { color: "#e71d7a" },
        // });
        sum = 0;
        map[this.accessSiteList[i].id].sumCompany.forEach((element) => {
          companyList.push({
            value: element.actualQuantity,
            name: element.company,
          });
        });
        if (map[this.accessSiteList[i].id].sumSpecification[0]) {
          this.specList[
            map[this.accessSiteList[i].id].sumSpecification[0].siteName
          ] = list;
          this.companyList[
            map[this.accessSiteList[i].id].sumSpecification[0].siteName
          ] = companyList;
        }
        list = [];
        companyList = [];
      }
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },
    async onClickSearch() {
      await this.graphCountPour();
      this.setup();
    },
    addCommasToNumber(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total(data) {
      console.log(data);
      let sum = 0;
      if (data) {
        data.forEach((item) => {
          sum += item.value;
        });
      }
      return sum;
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2열 */
  grid-template-rows: 1fr 1fr; /* 2행 */
  height: 110%; /* 부모의 높이를 100%로 설정 */
  width: 100%; /* 필요에 따라 너비 설정 */
}

.grid-item {
  border: 1px solid #bfb2b2; /* 경계선을 보여주기 위해 추가 */
}
table {
  margin-top: 20px;
}
</style>

<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">총괄표 생성</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>

    <div class="popup_content">
      <div class="text_info_popup">선택한 파일을 삭제 하시겠습니까?</div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">닫기</button>
        <button type="button" class="comfirm" @click="ok">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import { getToday } from "../../utils/date";
export default {
  name: "PopupDeleteConfirm",
  components: {},
  props: {},
  data: () => {
    return {
      isShow: false,
      item: {},
      callBackParam: {
        countStyle: ["approval", "waiting"],
        selectSiteId: null,
        beginDate: null,
        endDate: null,
      },
    };
  },
  watch: {},
  computed: {},
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    close() {
      this.isShow = false;
    },
    open() {
      this.isShow = true;
    },
    ok() {
      this.isShow = false;
      this.$emit("deleteFile", this.callBackParam);
    },
  },
};
</script>

<template>
  <div class="sea_wrap bim">
    <qbox-header
      :siteId="siteId"
      @openPopUp="openPopUp"
      @openSetting="openSetting"
      @openGraph="openGraph"
      @onLogout="onLogout"
    />
    <!-- dashboard 추가 -->
    <div class="dashboard_wrap">
      <div data-v-22009874="" class="dashboard">
        <div data-v-22009874="" class="column_box">
          <div data-v-22009874="" class="column notice">
            <div data-v-22009874="" class="title notice">확인요청</div>
            <ul class="notice-box">
              <li v-for="(data, index) in confirmCount" :key="index">
                {{ accessSiteMap[data.siteId].name }} &#183;
                <span class="writing">{{ data.requestCount }}</span> /
                <span class="change">{{ data.changeCount }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- bridge dashboard -->
      <div class="dashboard">
        <div
          v-for="(site, index) in accessSiteList"
          :key="index"
          class="column_box"
          @click="onClickSite(site.id)"
        >
          <div :class="['column', { 'column-selected': site.id === siteId }]">
            <div class="title" @click="onClickSite(site.id)">
              {{ site.name }}
            </div>
            <div class="progress">
              {{ addCommasToNumber(site.totalCount) }}/{{
                addCommasToNumber(site.planCount)
              }}
            </div>
            <div class="data_chart">
              <div
                class="chart_percent"
                :style="`width: ${calcPercentage(
                  site.planCount,
                  site.totalCount
                )}%`"
              />
            </div>
            <div class="count">
              금월
              <div class="num">{{ site.monthCount }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- information dashboard 재정리 -->
      <div class="box-infomation">
        <div class="box-item item-percent">
          <div class="box-content">
            <div class="content-box box-01">
              <vue-flip v-model="cardFlip" width="100%" height="100%">
                <template v-slot:front>
                  <div class="side side1">
                    <div class="gauge_item_chart chart_01">
                      <svg
                        class="radial-progress"
                        :data-percentage="siteInfo.progressRate"
                        viewBox="0 0 80 80"
                      >
                        <!-- data-percentage 에 % 값 변경 하면 됩니다. -->
                        <defs>
                          <linearGradient
                            id="gradient"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stop-color="#56850f" />
                            <stop offset="100%" stop-color="#85c621" />
                          </linearGradient>
                        </defs>
                        <circle class="incomplete" cx="40" cy="40" r="35" />
                        <circle
                          class="complete"
                          stroke="url(#gradient)"
                          cx="40"
                          cy="40"
                          r="35"
                          :style="'stroke-dashoffset: ' + calcProgress(0)"
                        />
                      </svg>
                      <div class="center_text">
                        <p class="number">
                          {{ siteInfo.progressRate }}<em>%</em>
                        </p>
                        <p class="description">시험공정률</p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:back>
                  <div class="side side2">
                    <div class="gauge_item_chart chart_02">
                      <svg
                        class="radial-progress"
                        :data-percentage="siteInfo.progressDayRate"
                        viewBox="0 0 80 80"
                      >
                        <!-- data-percentage 에 % 값 변경 하면 됩니다. -->
                        <defs>
                          <linearGradient
                            id="gradient2"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stop-color="#dfaa0b" />
                            <stop offset="100%" stop-color="#f9e222" />
                          </linearGradient>
                        </defs>
                        <circle class="incomplete" cx="40" cy="40" r="35" />
                        <circle
                          class="complete"
                          stroke="url(#gradient2)"
                          cx="40"
                          cy="40"
                          r="35"
                          :style="'stroke-dashoffset: ' + calcProgressDay(0)"
                        />
                      </svg>
                      <div class="center_text">
                        <p class="number">
                          {{ siteInfo.progressDay }}<em>일</em>
                        </p>
                        <p class="description">공사일</p>
                      </div>
                    </div>
                  </div>
                </template>
              </vue-flip>
            </div>
          </div>
        </div>

        <div class="box-item item-weather">
          <div class="box-content">
            <div class="content-box box-01">
              <div class="item">
                <div class="side side1">
                  <div class="item">
                    <div v-for="(item, index) in weatherList" :key="index">
                      <div class="weather_content content_01">
                        <span class="weather_title">{{ item.siteName }}</span>
                        <p class="info_weather" :class="item.weatherCls" />
                        <span>{{ item.tmp }}℃</span>
                        <p class="info_weather iconDrop" />
                        <span>{{ item.pop }}%</span>
                        <p class="info_weather iconPm" />
                        <span>{{ item.pm10Value }}㎍/㎥</span>
                      </div>
                      <hr />
                    </div>
                    <!-- <div class="weather_content content_01">
                      <span class="weather_title">광주</span>
                      <p class="info_weather icon3" />
                      <span>27℃</span>
                      <p class="info_weather iconDrop" />
                      <span>0%</span>
                      <p class="info_weather iconPm" />
                      <span>5㎍/㎥</span>
                    </div>
                    <hr />
                    <div class="weather_content content_01">
                      <span class="weather_title">여주</span>
                      <p class="info_weather icon2" />
                      <span>28℃</span>
                      <p class="info_weather iconDrop" />
                      <span>0%</span>
                      <p class="info_weather iconPm" />
                      <span>20㎍/㎥</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--- 추가 alert -->
      <div class="box-infomation alert">
        <div
          v-show="warningTitle.length > 0"
          class="box-item item-alert"
          style="display: block"
        >
          <div class="box-content">
            <div class="content-box box-01">
              <div class="alert-card">
                <p class="cont">{{ warningContent }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--- 추가 alert //-->
    </div>
    <!-- dashboard_wrap //-->

    <div class="canvas_wrap">
      <div id="bim-type-data" class="canvas_info">
        <div class="info_btns">
          <div class="legend">
            <div class="info comp1">계획</div>
            <div class="info comp2">금월</div>
            <div class="info comp3">누계</div>
          </div>
        </div>
        <div
          v-for="(process, index) in processCounts"
          :key="index"
          class="info_btns"
          @click="onClickGraph(process)"
        >
          <p class="title">{{ process.name }}</p>
          <div class="legend">
            <div class="info comp1">
              {{ process.planCount ? process.planCount : 0 }}
            </div>
            <div class="info comp2">{{ process.monthCount }}</div>
            <div class="info comp3">
              {{ process.totalCount - process.monthCount }}
            </div>
          </div>
        </div>
      </div>

      <div class="canvas_graph">
        <div id="map" class="map_wrap canvas_inner" />
        <div class="bottom_wrap" style="z-index: 1">
          <div class="people_wrap left">
            <div class="people_inner">
              <div class="people_sec sec_03">
                <div class="poeple_title">불합격</div>
                <div class="poeple_card">
                  <div class="card_item card_w">
                    <p class="sub">총계</p>
                    <p class="wide">{{ countOnMap.fail }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="people_wrap left">
            <div class="people_inner">
              <div class="people_sec sec_03">
                <div class="poeple_title">반려</div>
                <div class="poeple_card">
                  <div class="card_item card_w">
                    <p class="sub">총계</p>
                    <p class="wide">{{ countOnMap.reject }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="people_wrap">
            <div class="people_inner">
              <!-- <div class="people_sec sec_01">
                <div class="poeple_title">감리원</div>
                <div class="poeple_card">
                  <div class="card_item card_w">
                    <p class="sub">총원</p>
                    <p>{{ countOnMap.supervisorCount }}</p>
                  </div>
                  <div class="card_item card_p">
                    <p class="sub">결재대기</p>
                    <p>{{ countOnMap.supervisorWaiting }}</p>
                  </div>
                  <div class="card_item card_t">
                    <p class="sub">완료</p>
                    <p>
                      {{ addCommasToNumber(countOnMap.supervisorApproval) }}
                    </p>
                  </div>
                </div>
              </div> -->
              <div class="people_sec sec_02">
                <div class="poeple_title">{{ countOnMap.companyName }}</div>
                <div class="poeple_card">
                  <div class="card_item card_w">
                    <p class="sub">총원</p>
                    <p>
                      {{ countOnMap.testerCount - countOnMap.supervisorCount }}
                    </p>
                  </div>
                  <div class="card_item card_s">
                    <p class="sub">작성중</p>
                    <p>
                      {{ countOnMap.totalWriting + countOnMap.remiconWriting }}
                    </p>
                  </div>
                  <div class="card_item card_p">
                    <p class="sub">결재대기</p>
                    <p>
                      {{ countOnMap.totalWaiting + countOnMap.remiconWaiting }}
                    </p>
                  </div>
                  <div class="card_item card_t">
                    <p class="sub">완료</p>
                    <p>
                      {{
                        addCommasToNumber(
                          countOnMap.totalApproval + countOnMap.remiconApproval
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="people_wrap">
            <div class="people_inner">
              <div class="people_sec sec_04">
                <div class="poeple_title">관리대장</div>
                <div class="poeple_card">
                  <div class="card_item card_p">
                    <p class="sub">결재대기</p>
                    <p>{{ countOnMap.testBookWaiting }}</p>
                  </div>
                  <div class="card_item card_t">
                    <p class="sub">완료</p>
                    <p>
                      {{ addCommasToNumber(countOnMap.testBookApproval) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="people_wrap">
            <div class="people_inner">
              <div class="people_sec sec_04">
                <div class="poeple_title">타설대장</div>
                <div class="poeple_card">
                  <div class="card_item card_p">
                    <p class="sub">결재대기</p>
                    <p>
                      {{
                        countOnMap.pourBookWaiting
                          ? countOnMap.pourBookWaiting
                          : 0
                      }}
                    </p>
                  </div>
                  <div class="card_item card_t">
                    <p class="sub">완료</p>
                    <p>
                      {{
                        addCommasToNumber(countOnMap.pourBookApproval)
                          ? addCommasToNumber(countOnMap.pourBookApproval)
                          : 0
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="choice_map">
            <button
              :class="currentBtn === 1 ? 'on' : ''"
              class="map_btn"
              @click="changeMapStyle('satellite-v9')"
            >
              위성
            </button>
            <button
              :class="currentBtn === 2 ? 'on' : ''"
              class="map_btn"
              @click="changeMapStyle('default')"
            >
              일반
            </button>
          </div>
        </div>
        <div class="right_wrap" style="z-index: 1">
          <div class="people_wrap">
            <div class="people_inner">
              <div
                v-for="(tester, index) in testerTotalList"
                :key="index"
                class="people_sec sec_02"
              >
                <div class="poeple_title">{{ tester.name }}</div>
              </div>
              <div
                v-for="tester in supervisorList"
                :key="tester.name"
                class="people_sec sec_01"
              >
                <div class="poeple_title">
                  {{ tester.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <popup-test-book
      v-if="showTestBook"
      :siteId="siteId"
      @closePopUp="closePopUp"
    /> -->
    <!-- <popup-remicon-book
      v-if="showRemiconBook"
      :siteId="siteId"
      @closePopUp="closePopUp"
    /> -->
    <popup-graph
      v-if="showGraph"
      :site-id="siteId"
      :param="testName"
      @closePopUp="closePopUp"
    />

    <popup-map-object
      v-if="showMapObject"
      :siteId="siteId"
      @closePopUp="closePopUp"
    />

    <popup-dash-admin ref="popupDashAdmin" :site-id="siteId" />
    <popup-dash-graph
      :countParam="countEsg"
      ref="popupDashGraph"
      :site-id="siteId"
    />
    <popup-detail-notice ref="popupDetailNotice" />

    <confirm :param="confirmParam" @logOutCallback="logOutCallback" />
  </div>
</template>
<script>
import VueFlip from "vue-flip";
import QboxHeader from "./layout/Header.vue";
import PopupGraph from "./PopupGraph.vue";
import PopupMapObject from "./PopupMapObject.vue";
import WeatherApi from "../api/osm/WeatherApi";
import {
  getToday,
  toDayFormatMinusDay,
  toDayFormatAddDay,
} from "../utils/date";
import PlanCountApi from "../api/qbox/PlanCountApi";
import BoardApi from "@/api/qbox/BoardApi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_MAP_STYLE,
  MAPBOX_MAP_STYLE_SATELLITE,
} from "../const";
import TestBookApi from "@/api/qbox/TestBookApi";
import SiteApi from "@/api/qbox/SiteApi";
import PopupDashAdmin from "./PopupDashAdmin.vue";
import popupDashGraph from "./PopupDashGraph.vue";
import PopupDetailNotice from "../components/detail/PopupDetailNotice.vue";
import Confirm from "../components/Confirm.vue";
import UserApi from "../api/osm/UserApi";

export default {
  name: "QBoxDashBoard",
  components: {
    "vue-flip": VueFlip,
    QboxHeader,
    PopupGraph,
    PopupMapObject,
    PopupDashAdmin,
    popupDashGraph,
    PopupDetailNotice,
    Confirm,
  },
  data: () => {
    return {
      siteInfo: { progress: 0, progressRate: 0 },
      oceanInfo: {},
      cardFlip: false,
      warningTitle: "",
      warningContent: "",
      interval: 3000,
      siteId: null,
      accessSiteList: [],
      accessSiteMap: {},
      processCounts: [],
      showTestBook: false,
      showMapObject: false,
      showRemiconBook: false,
      showGraph: false,
      weather: {
        tmn: "-",
        tmx: "-",
      },
      weatherList: [],
      testName: null,
      accessToken: MAPBOX_ACCESS_TOKEN,
      mapStyle: MAPBOX_MAP_STYLE,
      mapStyleSatellite: MAPBOX_MAP_STYLE_SATELLITE,
      supervisorTotal: {
        member: 0,
        waiting: 0,
        approval: 0,
      },
      companyTotal: {
        member: 0,
        writing: 0,
        waiting: 0,
        approval: 0,
        name: "시험자",
      },
      countOnMap: {},
      countOnMapList: [],
      testerTotalList: [],
      supervisorList: [],
      currentBtn: 2,
      mapContextMenu: true,
      siteIdList: localStorage.getItem("siteIdList").split(","),
      sumCount: [],
      processRate: null,
      cstrCenter: {
        8: [127.372935, 37.319175],
        6: [127.398425, 37.350165],
        9: [127.429704, 37.383817],
        7: [127.468576, 37.419421],
      },
      isDev: false, //TODO 배포확인
      noticeList: [],
      siteNameMap: [],
      confirmCount: [],
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
        callbackParam: {},
      },
      countEsg: [],
    };
  },
  computed: {},
  beforeCreate() {
    console.log("beforeCreate");
  },
  created() {
    // this.siteId = this.siteIdList[0];
    // this.siteId = parseInt(this.$route.params.id) || 1;
  },
  async mounted() {
    this.siteId = Number(this.siteIdList[0]);
    if (!this.isDev) {
      this.mainStatistic();
      this.getWeather();
    }

    // for (var i = 0; i < 100; i++) {
    //   clearInterval(i);
    // }
    setInterval(this.getSpecialReport, 1000 * 60 * 20);

    setTimeout(() => {
      location.reload();
    }, 600000 * 6 * 24); // 600000밀리초 = 10분

    this.getCountOnMap();
    this.getCountTesterList();
    this.getCountSupervisorList();
    // this.getSiteList();
    await this.getTotal();
    await this.getCountOnMapList();
    await this.getInit();
    await this.mapinit(this.mapStyle);
  },
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    toDayFormatMinusDay: toDayFormatMinusDay,
    async getSiteByConfirmCount() {
      const result = await SiteApi.getSiteByConfirmCount();
      this.confirmCount = result.sort(
        (a, b) => b.requestCount - a.requestCount
      );
    },
    async getCountOnMap() {
      const response = await TestBookApi.testBookCount(this.siteId);
      this.countOnMap = response;
    },
    getCountOnMapList() {
      this.accessSiteList.forEach((element) => {
        TestBookApi.testBookCountForESG(element.id).then((res) => {
          res.siteId = element.id;
          res.siteName = element.name;
          this.countEsg.push(res);
        });
      });
    },
    async getCountTesterList() {
      const response = await TestBookApi.testBookCountByTester(this.siteId);
      this.testerTotalList = response;
    },
    async getCountSupervisorList() {
      const response = await TestBookApi.CountBySupervisor(this.siteId);
      this.supervisorList = response;
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },
    onClickGraph(process) {
      this.testName = process;
      this.showGraph = true;
    },
    onClickSite(siteId) {
      const mapinit = JSON.parse(this.accessSiteMap[siteId].mapInitInfo);
      this.map.setCenter([mapinit.center[0], mapinit.center[1]]);

      for (let site of this.accessSiteList) {
        if (site.id == siteId) {
          this.getDateDiff(site.beginDay, new Date(), site.endDay);
          if (!site.planCount || site.totalCount === 0) {
            this.siteInfo.progressRate = 0;
          } else {
            this.siteInfo.progressRate = Math.round(
              (site.totalCount / site.planCount) * 100
            );
          }
        }
      }
      if (this.siteInfo.progressRate > 100) this.siteInfo.progressRate = 100;
      this.siteId = siteId;
      this.mainStatistic();
      // this.$router.push({ path: `/dashboard/${siteId}` });
      this.getCountOnMap();
      this.getCountTesterList();
      this.getCountSupervisorList();
    },
    async getInit() {
      this.siteInfo = {
        name: "양평-이천 고속도로 현장",
        progressRate: "15.6",
        accidentDate: "2022-12-01",
        infoText: "",
        mapInitInfo: this.accessSiteMap[this.siteId].mapInitInfo,
        startDate: "2022-12-01",
        endDate: "2024-12-01",
      };

      this.getSpecialReport();
    },

    async getSpecialReport() {
      this.reportResult = await WeatherApi.specialReport();

      this.reportResult = [];

      this.warningTitle = "";
      this.warningContent = "";

      this.warningTitle = this.reportResult
        .map((report) => report.warnVar + report.warnStress)
        .join();

      //this.warningTitle = this.warningTitle || "주의사항";
      this.warningContent = this.reportResult
        .map(
          (report) =>
            `${report.areaName}  ${report.time.substr(
              0,
              2
            )}:${report.time.substr(2, 2)}경 ${report.warnVar}  ${
              report.warnStress
            }  ${report.command}`
        )
        .join();
    },

    calcProgress(add) {
      return 220 - this.siteInfo.progressRate * 2.2 + add;
    },
    calcProgressDay(add) {
      return 220 - this.siteInfo.progressDayRate * 2.2 + add;
    },
    calcPercentage(totalCount, finishCount) {
      if (totalCount === 0 || !totalCount) return 0;
      let result = Math.round((finishCount / totalCount) * 100);
      if (result > 100) result = 100;
      return result;
    },
    async getWeather() {
      const result = await WeatherApi.day(this.getToday().replaceAll("-", ""));
      if (result.content) {
        this.weatherList = result.content;
      } else {
        const result = await WeatherApi.day(
          this.toDayFormatMinusDay(1).replaceAll("-", "")
        );
        this.weatherList = result.content;
      }

      const airResult = await WeatherApi.air(this.getToday());

      this.weatherList.forEach((weather) => {
        const displayType = weather.displayType;
        if (displayType === "맑음") {
          weather.weatherCls = "icon1";
        } else if (displayType === "흐림") {
          weather.weatherCls = "icon4";
        } else if (displayType === "눈") {
          weather.weatherCls = "icon9";
        } else if (displayType === "비") {
          weather.weatherCls = "icon5";
        }

        airResult.content.forEach((air) => {
          if (weather.siteId === air.siteId) weather.pm10Value = air.pm10Value;
        });
      });
    },
    closePopUp() {
      this.showTestBook = false;
      this.showMapObject = false;
      this.showRemiconBook = false;
      this.showGraph = false;
    },
    openPopUp(param) {
      if (param === "TestBook") {
        this.showTestBook = true;
      } else if (param === "Remicon") {
        this.showRemiconBook = true;
      } else if (param === "MapObject") {
        this.showMapObject = true;
      }
    },
    openSetting() {
      this.$refs.popupDashAdmin.toggle();
    },
    openGraph() {
      this.$refs.popupDashGraph.toggle();
    },
    async mainStatistic() {
      const response = await PlanCountApi.mainStatistic(this.siteId);
      response.forEach((item) => {
        if (item.name === "civil") item.name = "토목";
        if (item.name === "construct") item.name = "건축";
      });
      this.processCounts = response;

      PlanCountApi.graphStatisticYear(this.siteId).then((res) => {
        console.log(res);
      });
    },
    async getTotal() {
      for (let id of this.siteIdList) {
        let tempItem = {};
        let monthTotal = 0;
        let fullTotal = 0;
        const response = await PlanCountApi.mainStatistic(id);
        console.log(response);

        for (let item of response) {
          monthTotal += item.monthCount;
          fullTotal += item.totalCount;
          tempItem = {
            siteId: id,
            monthTotal: monthTotal,
            fullTotal: fullTotal,
          };
        }
        this.sumCount.push(tempItem);
      }

      const siteList = await SiteApi.getSiteList();
      this.accessSiteList = siteList;

      this.accessSiteList.map((site) => {
        this.accessSiteMap[site.id] = site;
      });
      for (let site of this.accessSiteList) {
        for (let list of this.sumCount) {
          if (site.id == list.siteId) {
            site.totalCount = list.fullTotal;
            site.monthCount = list.monthTotal;
          }
          if (this.siteId == site.id) {
            this.getDateDiff(site.beginDay, new Date(), site.endDay);
            if (!site.planCount || site.totalCount === 0) {
              this.siteInfo.progressRate = 0;
            } else {
              this.siteInfo.progressRate = Math.round(
                (site.totalCount / site.planCount) * 100
              );
            }
          }
        }
      }
      if (this.siteInfo.progressRate > 100) this.siteInfo.progressRate = 100;

      this.getSiteByConfirmCount();
    },
    async mapinit(style) {
      const mapinit = JSON.parse(this.accessSiteMap[this.siteId].mapInitInfo);
      const result = await PlanCountApi.graphCountDivision();
      result.forEach((Element) => {
        if (Element.siteName === "1공구")
          Element.lnglat = [127.36662322202596, 37.320545563053614];
        if (Element.siteName === "2공구")
          Element.lnglat = [127.39015330295473, 37.35119744562007];
        if (Element.siteName === "3공구")
          Element.lnglat = [127.42253560078467, 37.38819537969164];
        if (Element.siteName === "4공구")
          Element.lnglat = [127.46136394311429, 37.42291962876989];
      });
      if (!style) {
        style = this.mapStyle;
      }
      mapboxgl.accessToken = this.accessToken;
      this.map = new mapboxgl.Map({
        container: "map", // container ID
        style: style, // style URL
        center: [mapinit.center[0], mapinit.center[1]],
        zoom: mapinit.zoom,
        // pitch: this.mapInitInfo.pitch,
        bearing: mapinit.bearing,
      });

      this.map.on("load", () => {
        this.map.addSource("ic_all_L", {
          type: "raster",
          tiles: [
            `https://gis.andthebee.net/geoserver/cite/wms?FORMAT=image/png8&SERVICE=WMS&version=1.1.1&request=GetMap&srs=EPSG:5186&transparent=true&width=512&height=512&layers=cite:ic_all_L&SRS=EPSG:3857&BBOX={bbox-epsg-3857}`,
          ],
        });
        this.map.addLayer({
          id: "L_layer",
          source: "ic_all_L",
          type: "raster",
          "source-layer": `cite:ic_all_L`,
        });

        this.map.addSource("ic_all_F", {
          type: "raster",
          tiles: [
            `https://gis.andthebee.net/geoserver/cite/wms?FORMAT=image/png8&SERVICE=WMS&version=1.1.1&request=GetMap&srs=EPSG:5186&transparent=true&width=512&height=512&layers=cite:ic_all_F&SRS=EPSG:3857&BBOX={bbox-epsg-3857}`,
          ],
        });
        this.map.addLayer({
          id: "F_layer",
          source: "ic_all_F",
          type: "raster",
          "source-layer": `cite:ic_all_F`,
        });

        this.map.addSource("ic_all_lineInfo_L", {
          type: "raster",
          tiles: [
            `https://gis.andthebee.net/geoserver/cite/wms?FORMAT=image/png8&SERVICE=WMS&version=1.1.1&request=GetMap&srs=EPSG:5186&transparent=true&width=512&height=512&layers=cite:ic_all_lineInfo_L&SRS=EPSG:3857&BBOX={bbox-epsg-3857}`,
          ],
        });
        this.map.addLayer({
          id: "I_layer",
          source: "ic_all_lineInfo_L",
          type: "raster",
          "source-layer": `cite:ic_all_lineInfo_L`,
        });

        this.map.addSource("ic_all_tunnel_F", {
          type: "raster",
          tiles: [
            `https://gis.andthebee.net/geoserver/cite/wms?FORMAT=image/png8&SERVICE=WMS&version=1.1.1&request=GetMap&srs=EPSG:5186&transparent=true&width=512&height=512&layers=cite:ic_all_tunnel_F&SRS=EPSG:3857&BBOX={bbox-epsg-3857}`,
          ],
        });
        this.map.addLayer({
          id: "T_layer",
          source: "ic_all_tunnel_F",
          type: "raster",
          "source-layer": `cite:ic_all_tunnel_F`,
        });

        const cstrLineList = [
          { name: "1공구", titleLng: 127.358183, titleLat: 37.325978 },
          { name: "2공구", titleLng: 127.385244, titleLat: 37.355161 },
          { name: "3공구", titleLng: 127.418552, titleLat: 37.391412 },
          { name: "4공구", titleLng: 127.452751, titleLat: 37.428193 },
          {
            name: "1단지",
            titleLng: 127.001,
            titleLat: 37.3707,
          },
          {
            name: "2단지",
            titleLng: 127.001,
            titleLat: 37.3718,
          },
        ];

        cstrLineList.forEach((cstr) => {
          this.cstrLine(cstr, this.map, mapboxgl);
        });

        //127.38882,37.33659

        const bridgeList = [
          {
            lnglat: [127.353327, 37.30326],
            name: "진우분기점교",
            info: "L=400m",
          },
          {
            lnglat: [127.362488, 37.303646],
            name: "진우분기점1교",
            info: "L=305m",
          },
          {
            lnglat: [127.35895, 37.311048],
            name: "진우분기점2교",
            info: "L=335m",
          },

          {
            lnglat: [127.394933, 37.326814],
            name: "신촌교",
            info: "L=450m",
          },
          {
            lnglat: [127.389059, 37.329473],
            name: "신촌3교",
            info: "L=17m",
          },
          {
            lnglat: [127.391444, 37.334444],
            name: "수양교",
            info: "L=15m",
          },

          {
            lnglat: [127.394933, 37.345843],
            name: "봉현교",
            info: "L=450m",
          },
          {
            lnglat: [127.40517, 37.354975],
            name: "부항교",
            info: "L=175m",
          },

          {
            lnglat: [127.42365350609084, 37.37786580919791],
            name: "이선교",
            info: "L=350m",
          },
          {
            lnglat: [127.42365350609084, 37.36886580919791],
            name: "부항교",
            info: "L=120m",
          },
          {
            lnglat: [127.43883529162469, 37.39825923388637],
            name: "상품교",
            info: "L=200m",
          },
          {
            lnglat: [127.44424703650094, 37.402000019125854],
            name: "후리교",
            info: "L=250m",
          },
          {
            lnglat: [127.51357, 37.446748],
            name: "용담천1교",
            info: "L=320m",
          },

          {
            lnglat: [127.48663, 37.426688],
            name: "강상분기점2교",
            info: "L=10m",
          },
          {
            lnglat: [127.485319, 37.433458],
            name: "용담천2교",
            info: "L=320m",
          },
          {
            lnglat: [127.489253, 37.434852],
            name: "용담천1교",
            info: "L=320m",
          },
          {
            lnglat: [127.492339, 37.432876],
            name: "강상분기점1교",
            info: "L=90m",
          },
        ];

        bridgeList.map((res) => {
          this.bridgePanel(res, this.map, mapboxgl);
        });

        const testPanelList = result;

        testPanelList.map((res) => {
          this.testPanel(res, this.map);
        });
      });

      this.map.on("click", (e) => {
        console.log(`A click event has occurred at ${e.lngLat}`);
        // if (!(e.originalEvent.target instanceof HTMLElement)) return;
        // if (e.originalEvent.target.className.includes("bridge")) {
        //   console.log("brgClick");
        //   this.openPopUp("MapObject");
        // }
      });
    },
    bridgePanel(item, map, mapboxgl) {
      const fDiv = document.createElement("div");
      fDiv.className = "bridge-bottom";
      const imgEl = document.createElement("img");
      imgEl.src = "../img/common/axshare/p_bridge_bottom.svg";
      fDiv.append(imgEl);

      const textDiv = document.createElement("div");
      textDiv.className = "text detail";

      const textDivP = document.createElement("p");

      const textDivPSpan = document.createElement("span");
      textDivPSpan.className = "bridge";
      textDivPSpan.textContent = item.info;
      textDivP.append(textDivPSpan);
      textDiv.append(textDivP);
      fDiv.append(textDiv);

      //

      const sDiv = document.createElement("div");
      sDiv.className = "bridge-top";
      const imgEl2 = document.createElement("img");
      imgEl2.src = "../img/common/axshare/p_bridge_top.svg";
      sDiv.append(imgEl2);

      const textDiv2 = document.createElement("div");
      textDiv2.className = "text detail";

      const textDivP2 = document.createElement("p");

      const numberSpan = document.createElement("span");

      numberSpan.textContent = item.orderId;
      numberSpan.className = "num";
      const textDivPSpan2 = document.createElement("span");
      textDivPSpan2.textContent = item.name;
      textDivPSpan2.className = "bridge";
      if (item.orderId && item.orderId !== 0) textDiv2.append(numberSpan);
      textDivP2.append(textDivPSpan2);
      textDiv2.append(textDivP2);
      sDiv.append(textDiv2);

      const mainDiv = document.createElement("div");
      mainDiv.append(sDiv);
      mainDiv.append(fDiv);

      new mapboxgl.Marker(mainDiv)
        .setLngLat(item.lnglat)
        .setPitchAlignment("map")
        .addTo(map);
    },
    testPanel(info, map) {
      // const testBox = document.createElement("div");
      // testBox.className = "test_box";
      // const title = document.createElement("div");
      // title.className = "title";
      // title.textContent = info.siteName;
      // const testCount = document.createElement("div");
      // testCount.className = "test_cont";
      // let ulContent =
      //   "<ul><li class='test_name'>현장</li><li class='num'>" +
      //   info.insideCount +
      //   "</li></ul>";
      // ulContent +=
      //   "<ul><li class='test_name'>의뢰</li><li class='num'>" +
      //   info.requestCount +
      //   "</li></ul>";
      // ulContent +=
      //   "<ul><li class='test_name'>레미콘</li><li class='num'>" +
      //   info.remiconCount +
      //   "</li></ul>";
      // ulContent +=
      //   "<ul><li class='test_name'>숏크리트</li><li class='num'>" +
      //   info.shortCreteCount +
      //   "</li></ul>";
      // testCount.innerHTML = ulContent;
      // testBox.append(title);
      // testBox.append(testCount);
      // // mainDiv.append(fDiv);
      // new mapboxgl.Marker(testBox)
      //   .setLngLat(info.lnglat)
      //   .setPitchAlignment("map")
      //   .addTo(map);
    },
    changeMapStyle(type) {
      // window.tb.dispose();
      this.map.remove();
      if (type === "default") {
        this.currentBtn = 2;
        this.mapinit();
      } else {
        this.currentBtn = 1;
        this.mapinit(this.mapStyleSatellite);
      }
    },
    cstrLine(site, map, mapboxgl) {
      const fDiv = document.createElement("div");
      fDiv.className = "cstr-line-top";
      const sDiv = document.createElement("div");
      sDiv.className = "cstr-line-bottom";

      const textDivP2 = document.createElement("p");

      const textDivPSpan = document.createElement("span");
      textDivPSpan.textContent = site.name;
      textDivPSpan.className =
        "font-family:'Noto Sans CJK KR Medium', 'Noto Sans CJK KR Regular', 'Noto Sans CJK KR', sans-serif;font-weight:500;";
      textDivP2.append(textDivPSpan);
      const textDivPSpan2 = document.createElement("span");
      textDivPSpan2.textContent = site.infoText;
      textDivPSpan2.className =
        "font-family:'Open Sans Semibold', 'Open Sans Regular', 'Open Sans', sans-serif;font-weight:600;";
      textDivP2.append(textDivPSpan2);

      sDiv.append(textDivP2);
      const mainDiv = document.createElement("div");
      mainDiv.className = "cstr-line-main";
      mainDiv.append(fDiv);
      mainDiv.append(sDiv);

      new mapboxgl.Marker(mainDiv)
        .setLngLat([site.titleLng, site.titleLat])
        .setPitchAlignment("map")
        .addTo(map);

      const fDivS = document.createElement("div");
      fDivS.className = "cstr-line-s-top";
      const sDivS = document.createElement("div");
      sDivS.className = "cstr-line-s-bottom";
    },
    getDateDiff(d1, d2, d3) {
      const today = new Date();

      let date1 = new Date(d1);
      // 현장에 연단위 설정이 안되어있다면 공사 시작과 끝은 올해 1월 1일과 12월 31로 설정한다
      if (!d1) date1 = new Date(today.getFullYear(), 0, 1);
      let date2 = new Date(d2);
      let date3 = new Date(d3);
      // 현장에 연단위 설정이 안되어있다면 공사 시작과 끝은 올해 1월 1일과 12월 31로 설정한다
      if (!d3) date3 = new Date(today.getFullYear(), 11, 31);

      const diffDate = date1.getTime() - date2.getTime();
      const diffDateTotal = date1.getTime() - date3.getTime();

      // const diffDate = (date1.getTime() / date2.getTime()) * 100;

      const progressDay = Math.round(
        Math.abs(diffDate / (1000 * 60 * 60 * 24))
      );

      const totalDay = Math.round(
        Math.abs(diffDateTotal / (1000 * 60 * 60 * 24))
      );

      this.siteInfo.progressDay = progressDay; // 밀리세컨 * 초 * 분 * 시 = 일

      this.siteInfo.progressDayRate = Math.round(
        (progressDay / totalDay) * 100
      );
    },
    addCommasToNumber(number) {
      if (!number) return "";
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onLogout() {
      this.confirmParam.title = "로그아웃";
      this.confirmParam.msg = "로그아웃 하시겠습니까?";
      this.confirmParam.isShow = true;
    },
    logOutCallback() {
      UserApi.logout();
    },
  },
  pad(number) {
    return number < 10 ? "0" + number : number;
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
.popup {
  z-index: 1000000;
}
</style>

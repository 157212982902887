import { httpClient } from "../../network/httpclient/HttpClientFactory";

const csiInsideBookList = (param) =>
  httpClient.get(`/csi/list`, { params: param }).then((res) => res.data);

const csiStat = (param) =>
  httpClient.get(`/csi/stat/weekly`, { params: param }).then((res) => res.data);

export default {
  csiInsideBookList,
  csiStat,
};

import {
  httpOsmLoginClient,
  httpQboxLoginClient,
} from "../../network/httpclient/HttpClientFactory";

import router from "../../router";

const login = (param, headerParam) =>
  httpQboxLoginClient
    .post("/member/login", param, { headers: headerParam })
    .then((res) => res.data);

const reToken = (headerParam) =>
  httpOsmLoginClient
    .post(
      "/user/token/refresh",
      { memberId: headerParam.memberId },
      { headers: headerParam }
    )
    .then((res) => res.data);

const logout = () => {
  router.push({ name: "Login" });
  localStorage.clear();
};

export default {
  login,
  reToken,
  logout,
};

import MutationTypes from "../../MutationTypes";

export default {
  [MutationTypes.MAP_BOUNDS]: (state, payload) => {
    state.mapBounds = payload;
  },
  [MutationTypes.SET_IS_LOADING]: (state, payload) => {
    state.isLoading = payload;
  },
};

import {
  httpClient,
  excelHttpClient,
} from "../../network/httpclient/HttpClientFactory";

const remiconBookList = (param) =>
  httpClient.get(`/remicon`, { params: param }).then((res) => res.data);

const excelDown = (url, param) =>
  excelHttpClient.get(url, { params: param }).then((res) => {
    var disposition = res.headers["content-disposition"];
    var fileName = decodeURI(
      disposition.substring(
        disposition.indexOf("filename=") + 9,
        disposition.length
      )
    );

    const blob = new Blob([res.data], { type: res.headers["content-type"] });

    var fileUrl = window.URL.createObjectURL(blob);
    var fileLink = document.createElement("a");

    fileLink.href = fileUrl;
    fileLink.download = fileName;
    document.body.appendChild(fileLink);

    fileLink.click();
    fileLink.remove();
  });
export default {
  remiconBookList,
  excelDown,
};

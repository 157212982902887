<template>
  <body class="login_container">
    <div class="login_wrap">
      <div class="login_form">
        <div class="login_logo">
          <p class="logo">
            <img
              src="/img/common/login_title.svg"
              alt="on site monitoring login"
            />
          </p>
        </div>
        <div class="login_info">
          <p class="login_item">
            <input v-model="login.loginId" type="text" placeholder="아이디" />
          </p>
          <p class="login_item">
            <input
              v-model="login.passwd"
              type="password"
              placeholder="비밀번호"
            />
          </p>
          <div class="login_option">
            <label class="custom_checkbox">
              <input v-model="login.isAuto" type="checkbox" />
              <span>로그인 상태 유지</span>
            </label>
          </div>
          <p class="submit_btn">
            <button @click="processLogin">로그인</button>
          </p>
        </div>
      </div>
      <div class="login_footer">
        <p>OSM is technically supported by AndTheBee Com.</p>
      </div>
    </div>
  </body>
</template>
<script>
import UserApi from "../api/osm/UserApi";
//import SiteCommonApi from "../../api/osm/SiteCommonApi";
import {
  HEADER_DEVICE_ID,
  JWT_TOKEN,
  USER_ID,
  SITE_ID_LIST,
  SITE_GROUP_ID,
  USER_NAME,
  COMPANY,
} from "../const";
export default {
  name: "QBOXLogin",
  components: {},
  data: () => {
    return {
      login: {
        isAuto: true,
        loginId: "",
        passwd: "",
        systemType: "OSM",
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    var interval_id = window.setInterval(() => {}, 99999);
    for (var i = 0; i < interval_id; i++) window.clearInterval(i);
  },
  beforeDestroy() {},
  methods: {
    async processLogin() {
      if (!this.login.loginId) {
        this.$toast.error("휴대전화번호를 입력하세요.");
        this.login.loginId.focus();
        return;
      }

      if (!this.login.passwd) {
        this.$toast.error("비밀번호를 입력하세요.");
        this.login.passwd.focus();
        return;
      }
      localStorage.clear();
      console.log(this.login);
      const response = await UserApi.login(this.login, {
        [HEADER_DEVICE_ID]: navigator.userAgent,
      });

      localStorage.setItem(JWT_TOKEN, response.content.token.accessToken);
      localStorage.setItem(USER_ID, response.content.memberId);
      localStorage.setItem(SITE_ID_LIST, response.content.siteList);
      localStorage.setItem(SITE_GROUP_ID, response.content.siteGroupId);
      localStorage.setItem(USER_NAME, response.content.name);
      localStorage.setItem(COMPANY, response.content.company);

      console.log(response);

      this.$router.replace({ path: `/main` });
      /**
       * TODO 현장정보를 가져와서 세팅 (현장정보는 QBOX에서 가져와서 처리함)
       */
    },
  },
};
</script>

<template>
  <div v-show="isShow" class="dash_admin_wrap">
    <div class="dash_admin_inner">
      <div class="dash_admin_content">
        <div class="dash_admin_left">
          <div class="dash_admin_head">
            <p class="title">품질 데이터 관리</p>
          </div>
          <ul>
            <li>
              <button
                type="button"
                :class="tabId === 1 ? 'on' : ''"
                @click="onClickTab(1)"
              >
                품질관리계획
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 2 ? 'on' : ''"
                @click="onClickTab(2)"
              >
                품질시험계획
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 3 ? 'on' : ''"
                @click="onClickTab(3)"
              >
                기성검사
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 4 ? 'on' : ''"
                @click="onClickTab(4)"
              >
                총괄표
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 5 ? 'on' : ''"
                @click="onClickTab(5)"
              >
                관리대장
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 6 ? 'on' : ''"
                @click="onClickTab(6)"
              >
                타설대장
              </button>
            </li>

            <li>
              <button
                type="button"
                :class="tabId === 7 ? 'on' : ''"
                @click="onClickTab(7)"
              >
                콘크리트 배합설계
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 8 ? 'on' : ''"
                @click="onClickTab(8)"
              >
                한중콘크리트 온도일지
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 9 ? 'on' : ''"
                @click="onClickTab(9)"
              >
                CSI 현장시험 목록
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 10 ? 'on' : ''"
                @click="onClickTab(10)"
              >
                철근수불부
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 11 ? 'on' : ''"
                @click="onClickTab(11)"
              >
                공지사항
              </button>
            </li>
            <li>
              <button
                type="button"
                :class="tabId === 12 ? 'on' : ''"
                @click="onClickTab(12)"
              >
                건의사항
              </button>
            </li>

            <!-- <li>
              <button
                type="button"
                :class="tabId === 9 ? 'on' : ''"
                @click="onClickTab(9)"
              >
                통계 그래프
              </button>
            </li> -->
          </ul>
        </div>
        <div class="dash_admin_cont">
          <popup-quality-plan
            ref="tab-1"
            :site-id="siteId"
            @viewPdf="callViewPdf"
          />
          <popup-count-plan ref="tab-2" :site-id="siteId" />
          <popup-count-report ref="tab-3" :site-id="siteId" />
          <popup-count-report-create ref="tab-4" :site-id="siteId" />
          <popup-test-book ref="tab-5" :site-id="siteId" />
          <popup-remicon-book ref="tab-6" :site-id="siteId" />
          <popup-concrete-file
            ref="tab-7"
            :site-id="siteId"
            @viewPdf="callViewPdf"
          />
          <cold-weather-conc-list ref="tab-8" :site-id="siteId" />
          <popup-csi-list-inside ref="tab-9" :site-id="siteId" />
          <popup-material-ledger-vue ref="tab-10" :site-id="siteId" />
          <popup-notice-list ref="tab-11" :site-id="siteId" />
          <popup-qna-list ref="tab-12" :site-id="siteId" />

          <!-- <popup-count-graph ref="tab-9" :site-id="siteId" /> -->
        </div>
      </div>
    </div>
    <popup-pdf ref="pdfViewer" />
  </div>
</template>
<script>
import PopupTestBook from "./PopupTestBook.vue";
import PopupRemiconBook from "./PopupRemiconBook.vue";
import PopupCountReport from "./PopupCountReport.vue";
import PopupCountPlan from "./PopupCountPlan.vue";
import PopupQualityPlan from "./PopupQualityPlan.vue";
import PopupConcreteFile from "./PopupConcreteFile.vue";
import PopupNoticeList from "./PopupNoticeList.vue";
import PopupQnaList from "./PopupQnaList.vue";
import PopupTestBookGraph from "./PopupTestBookGraph.vue";
import PopupCountGraph from "./PopupCountGraph.vue";
import PopupMaterialLedgerVue from "./PopupMaterialLedger.vue";
import ColdWeatherConcList from "./ColdWeatherConcList.vue";
import PopupPdf from "./layout/PopupPdf.vue";
import PopupCountReportCreate from "./PopupCountReportCreate.vue";
import PopupCsiListInside from "./PopupCsiListInside.vue";

export default {
  name: "PopupDashAdmin",
  components: {
    PopupTestBook,
    PopupRemiconBook,
    PopupCountReport,
    PopupCountPlan,
    PopupQualityPlan,
    PopupConcreteFile,
    PopupNoticeList,
    PopupQnaList,
    PopupTestBookGraph,
    PopupCountGraph,
    PopupMaterialLedgerVue,
    PopupPdf,
    ColdWeatherConcList,
    PopupCountReportCreate,
    PopupCsiListInside,
  },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    currentPopup: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      isShow: false,
      tabId: 1,
    };
  },
  computed: {},
  created() {},
  mounted() {
    console.log(process.env.PROGRESS_DOMAIN);
  },
  beforeDestroy() {},
  methods: {
    toggle() {
      this.isShow = !this.isShow;
      if (this.isShow) this.showTab();
    },
    onClickTab(tabId) {
      this.tabId = tabId;
      this.showTab();
    },
    showTab() {
      const popupObj = this.$refs;
      for (const key in popupObj) {
        popupObj[key].close();
      }
      this.$refs[`tab-${this.tabId}`].open();
    },
    callViewPdf(file) {
      console.log("dash");
      this.$refs.pdfViewer.open(file);
    },
  },
};
</script>

import { osmHttpClient } from "../../network/httpclient/HttpClientFactory";

const list = (params) =>
  osmHttpClient.get(`/weather/list`, { params }).then((res) => res.data);

const day = (baseDate) =>
  osmHttpClient.get(`/weather/${baseDate}`).then((res) => res.data);
const air = (baseDate) =>
  osmHttpClient.get(`/air/${baseDate}`).then((res) => res.data);
const specialReport = () =>
  osmHttpClient.get("/special-report").then((res) => res.data);
const oceanInfo = () =>
  osmHttpClient.get("/ocean-info").then((res) => res.data);
export default {
  day,
  air,
  list,
  specialReport,
  oceanInfo,
};

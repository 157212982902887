<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->

      <div class="dash_admin_head">
        <p class="title">X-R 그래프</p>
        <button class="close_popup" style="margin-left: auto">
          <img
            src="/img/common/popup_close.svg"
            alt="팝업 닫기"
            @click="close"
          />
        </button>
      </div>

      <div id="container">
        <v-chart class="chart" :option="option" autoresize />
        <v-chart class="chart" :option="optionRange" autoresize />
      </div>

      <div class="dash_admin_head">
        <p class="title">콘크리트 강도값 상세</p>
      </div>
      <div class="option_wrap">
        <div class="right_area">
          <div
            class="download_button graph1"
            :class="dataTypeNormal === true ? 'on' : ''"
          >
            <button type="button" @click="changeDataType('normal')">
              정상 데이터
            </button>
          </div>
          <div
            class="download_button graph2"
            :class="dataTypeError === true ? 'on' : ''"
          >
            <button type="button" @click="changeDataType('error')">
              이상 데이터
            </button>
          </div>
        </div>
      </div>
      <!-- 검색 Wrap //-->

      <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="90">처리상태</th>
              <th width="90">현장명</th>
              <th>시험일</th>
              <th>시험번호</th>
              <th>시험구분</th>
              <th width="280">위치/부위</th>
              <th width="200">재료</th>
              <th>시험·검사항목</th>
              <th>시험성과</th>
              <th width="150">시험기준</th>
              <th width="100">판정</th>

              <th>확인자</th>
              <th>서명</th>
              <th>감독관</th>
              <th>서명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.total === 0">
              <td colspan="15">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam.content" :key="index">
                <td>
                  {{ displayStatus(item.bookStatus) }}
                </td>
                <td>
                  {{ item.siteName }}
                </td>
                <td>
                  {{ item.testDay }}
                </td>
                <td @click="detail(item)">
                  {{ item.testNo }}
                </td>
                <td>
                  {{ getTestTypeValue(item.division) }}
                </td>
                <td v-html="enter2Br(item.location)" />
                <td>
                  {{ item.material }}
                </td>
                <td>
                  {{ item.testItem.name }}
                </td>
                <td>
                  {{ item.result }}
                </td>
                <td>
                  {{ item.standard }}
                </td>
                <td>
                  {{ displayJudgment(item.judgment) }}
                </td>
                <td>
                  <span> {{ item.checkerName }}</span>
                </td>
                <td>
                  <span class="sign">
                    <img
                      v-if="
                        item.checkerFileId != null && item.checkerSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.checkerFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
                <td>
                  <span> {{ item.supervisorName }}</span>
                </td>
                <td>
                  <span class="sign">
                    <img
                      v-if="
                        item.supervisorFileId != null &&
                        item.supervisorSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.supervisorFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination
        :page-param="pageParam"
        :list-cnt="listParam.size"
        @pageCallback="pageCallback"
      />
      <!-- <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="90">처리상태</th>
              <th width="90">현장명</th>
              <th>시험일</th>
              <th>시험번호</th>
              <th>시험구분</th>
              <th width="280">위치/부위</th>
              <th width="200">재료</th>
              <th>시험·검사항목</th>
              <th>시험성과</th>
              <th width="150">시험기준</th>
              <th width="100">판정</th>

              <th>확인자</th>
              <th>서명</th>
              <th>감독관</th>
              <th>서명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="concErrorList.length === 0">
              <td colspan="15">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="concErrorList.length !== 0">
              <tr v-for="(item, index) in concErrorList" :key="index">
                <td>
                  {{ displayStatus(item.bookStatus) }}
                </td>
                <td>
                  {{ item.siteName }}
                </td>
                <td>
                  {{ item.testDay }}
                </td>
                <td @click="detail(item)">
                  {{ item.testNo }}
                </td>
                <td>
                  {{ getTestTypeValue(item.division) }}
                </td>
                <td v-html="enter2Br(item.location)" />
                <td>
                  {{ item.material }}
                </td>
                <td>
                  {{ item.testItem.name }}
                </td>
                <td>
                  {{ item.result }}
                </td>
                <td>
                  {{ item.standard }}
                </td>
                <td>
                  {{ displayJudgment(item.judgment) }}
                </td>
                <td>
                  <span> {{ item.checkerName }}</span>
                </td>
                <td>
                  <span class="sign">
                    <img
                      v-if="
                        item.checkerFileId != null && item.checkerSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.checkerFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
                <td>
                  <span> {{ item.supervisorName }}</span>
                </td>
                <td>
                  <span class="sign">
                    <img
                      v-if="
                        item.supervisorFileId != null &&
                        item.supervisorSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.supervisorFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div> -->
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import Pagination from "./layout/Pagination.vue";
import { getToday, toDayFormatAddDay } from "../utils/date";
import { mapGetters, mapActions, mapState } from "vuex";
import TestBookApi from "../api/qbox/TestBookApi";
import SiteApi from "../api/qbox/SiteApi";
import PlanCountApi from "../api/qbox/PlanCountApi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import PopupModifyNoticeVue from "./detail/PopupCreateNotice.vue";
import { QBOX_URL, FILE_URL, JWT_TOKEN } from "../const/index";

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  MarkLineComponent,
]);

export default {
  name: "TestBookList",
  components: { Pagination, DatePicker, VChart, PopupModifyNoticeVue },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 20,
        beginDate: toDayFormatAddDay(-7),
        endDate: getToday(),
        name: null,
      },
      testItemList: {},
      firstCode: null,
      accessSiteList: [],
      selectSiteId: null,
      siteYearPlanList: [],
      testItemDepth1: null,
      option: {},
      optionRange: {},
      testItemName: [],
      testItemTotal: [],
      testItemMonth: [],
      testItemPlanCount: [],
      displayGraph: false,
      displayList: true,
      graphTypeCount: true,
      graphTypeMonth: false,
      dataTypeNormal: true,
      dataTypeError: false,
      isShow: false,
      FILE_URL: FILE_URL,
      material: null,
      concErrorList: [],
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      this.getSiteList();
      this.fetchTestBook("normal");
      // this.fetchTestBookError();
      this.getXrData();
    },
    getToday: getToday,
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchTestBook();
    },

    changeSite() {
      this.fetchTestBook();
    },
    async fetchTestBook(type) {
      //TODO 구해야 됨
      this.listParam.siteId = this.selectSiteId;
      this.listParam.material = this.material;

      let response = null;

      if (type === "normal") {
        response = await TestBookApi.testBookStrengthList(this.listParam);
      } else {
        response = await TestBookApi.testBookStrengthErrorList(this.listParam);
      }
      response.size = this.listParam.size;

      this.pageParam = response;
      if (response.content.length === 0) {
        this.pageParam.total = 0;
      }
      this.pageParam.content.forEach((item) => {
        this.accessSiteList.forEach((site) => {
          if (item.siteId === site.id) {
            item.siteName = site.name;
          }
        });
      });
    },
    async fetchTestBookError() {
      const errorResponse = await TestBookApi.testBookStrengthErrorList(
        this.listParam
      );
      // response.size = this.listParam.size;
      this.concErrorList = errorResponse;
      this.concErrorList.content.forEach((item) => {
        this.accessSiteList.forEach((site) => {
          if (item.siteId === site.id) {
            item.siteName = site.name;
          }
        });
      });
    },
    pageCallback(page) {
      this.listParam.page = page;
      if (this.dataTypeNormal === true) {
        this.fetchTestBook("normal");
      } else {
        this.fetchTestBook();
      }
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    async onClickExcel() {
      const url = "/testBooks/excel";
      this.showLoading();
      await TestBookApi.excelDown(url, this.listParam);
      this.hideLoading();
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },
    displayJudgment(judgment) {
      if (judgment === "pass") {
        return "합격";
      } else if (judgment === "fail") {
        return '<font color="red">불합격</font>';
      }
      return "-";
    },
    displayStatus(status) {
      if (status === "approval") return "결재완료";
      else return "결재대기";
    },
    getTestTypeValue(division) {
      if (division === "remicon") return "레미콘시험";
      else if (division === "inside") return "현장시험";
      else if (division === "request") return "의뢰시험";
      else return "-";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    changeDataType(type) {
      if (type === "normal") {
        this.dataTypeNormal = true;
        this.dataTypeError = false;
        this.fetchTestBook("normal");
      }
      if (type === "error") {
        this.dataTypeNormal = false;
        this.dataTypeError = true;
        this.fetchTestBook();
      }
    },
    open(siteId, name, beginDay, endDay) {
      this.isShow = true;
      this.selectSiteId = siteId;
      this.material = name;
      this.listParam.beginDate = beginDay;
      this.listParam.endDate = endDay;
      this.init();
    },
    close() {
      this.listParam.page = 1;
      this.listParam.beginDate = toDayFormatAddDay(-7);
      this.listParam.endDate = getToday();
      this.listParam.name = null;
      this.dataTypeNormal = true;
      this.dataTypeError = false;
      this.isShow = false;
    },
    detail(item) {
      var popupW = 1200;
      var popupH = 800;
      var left = Math.ceil((window.screen.width - popupW) / 2);
      var top = Math.ceil((window.screen.height - popupH) / 2);
      const token = localStorage.getItem(JWT_TOKEN);
      const options =
        "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=" +
        top +
        ",left=" +
        left;
      window.open(
        `${QBOX_URL}/testBookRouter/${item.id}?isPopup=1&token=${token}`,
        "_blank",
        options
      );
    },
    async getXrData() {
      const response = await PlanCountApi.graphCountPourXr(
        this.selectSiteId,
        this.material,
        this.listParam
      );
      let xAxisData = [];

      for (let i = 1; i <= response.xrData.length; i++) {
        xAxisData.push(i);
      }

      const yAxisData = response.xrData;

      this.setup(xAxisData, response.xrData);
      this.setupRange(xAxisData, response.range);
    },
    setup(xAxisData, yAxisData) {
      const min = Math.min(...yAxisData);
      const closestMultipleOfFive = Math.round(min / 5) * 5 - 5;

      this.option = {
        title: {
          text: "X",
          left: "center",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 20,
            color: "#ffffff",
          },
        },

        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",

          data: xAxisData,
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          min: closestMultipleOfFive,
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },

        series: [
          {
            data: yAxisData,
            type: "line",
            name: "강도값",
            markLine: {
              data: [
                { type: "average", name: "avg" },
                // { xAxis: 25.1, name: "임계값" },
              ],
              lineStyle: {
                color: "red", // markLine의 색상
                // width: 5, // markLine의 두께
                // type: "dashed", // markLine의 스타일 (실선, 점선 등)
              },
            },
          },
        ],
      };
    },
    setupRange(xAxisData, yAxisData) {
      this.optionRange = {
        title: {
          text: "R",
          left: "center",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 20,
            color: "#ffffff",
          },
        },

        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",

          data: xAxisData,
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },

        series: [
          {
            data: yAxisData,
            type: "line",
            name: "범위",
            markLine: {
              data: [
                { type: "average", name: "avg" },
                // { xAxis: 25.1, name: "임계값" },
              ],
              lineStyle: {
                color: "red", // markLine의 색상
                type: "dashed", // markLine의 스타일 (실선, 점선 등)
              },
              // label: {
              //   show: true,
              //   position: "end",
              //   formatter: "Average: {c}",
              // },
            },
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}
.dash_admin_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #6b6c73;
  background-color: #111217;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  min-width: 480px;
  z-index: 1000;
}
.dash_admin_box_table {
  height: calc(100vh - 700px) !important;
}
#container {
  height: calc(100vh - 700px) !important;
  display: flex;
}
</style>

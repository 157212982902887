<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <label
              v-for="(item, index) in accessSiteList"
              :key="index"
              class="custom_checkbox"
              :for="item"
            >
              <input
                :id="item"
                v-model="selectSiteId"
                :value="item.id"
                type="checkbox"
                @change="changeSite(item.id)"
              />
              <span>{{ item.name }} &nbsp </span>
            </label>

            <div class="option_date w100">
              <input
                v-model="listParam.fileName"
                type="text"
                placeholder="파일명 검색"
                @keyup.enter="onClickSearch"
              />
            </div>

            <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div>
          </form>
        </div>

        <div class="right_area">
          <div class="download_button">
            <button type="button" @click="onClickDiff">선택 비교</button>
          </div>
          <div class="download_button">
            <button type="button" @click="onClickDownload">
              선택 다운로드
            </button>
          </div>
        </div>
      </div>

      <!-- 검색 Wrap //-->

      <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="20">
                <label class="custom_checkbox">
                  <input
                    v-model="allSelected"
                    type="checkbox"
                    @click="selectAll"
                  />
                </label>
              </th>
              <th width="20">현장명</th>
              <th width="150">파일명</th>
              <th width="150">통계기간</th>
              <th width="20">연도</th>
              <th width="20">차수</th>
              <th width="50">작성자</th>
              <th width="50">등록일</th>
              <th width="60">발주사확인</th>
              <th width="60">요청내역</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.total === 0">
              <td colspan="10">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam.content" :key="index">
                <td>
                  <label class="custom_checkbox">
                    <input
                      v-model="fileIds"
                      :value="item"
                      type="checkbox"
                      @click="select"
                    />
                  </label>
                </td>
                <td>
                  {{ item.siteName }}
                </td>
                <td
                  style="cursor: pointer; color: aqua"
                  @click="showExcel(item)"
                >
                  {{ item.name }}
                </td>
                <td>{{ item.beginDay }} ~ {{ item.endDay }}</td>
                <td>{{ item.endDay.substr(0, 4) }}</td>
                <td>{{ item.revision ? item.revision : 0 }}</td>
                <td>
                  {{ item.regName }}
                </td>
                <td>
                  {{ dayFormat(item.regDay) }}
                </td>
                <td
                  @click.stop="onClickConfirm(item)"
                  v-html="makeConfirmBtn(item)"
                />
                <td
                  @click.stop="onClickConfirmList(item)"
                  v-html="makeConfirmListBtn(item)"
                />
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination
        :page-param="pageParam"
        :list-cnt="listParam.size"
        @pageCallback="pageCallback"
      />
      <popup-confirm-list ref="popupConfirmList" />
      <popup-confirm ref="popupConfirm" @regConfirm="regConfirm" />
      <confirm :param="confirmParam" @confirmCallback="confirmCallback" />
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import Pagination from "./layout/Pagination.vue";
import { getToday, toDayFormatAddDay, dayFormat } from "../utils/date";
import { mapGetters, mapActions } from "vuex";
import HistoryFileApi from "../api/qbox/HistoryFileApi";
import SiteApi from "../api/qbox/SiteApi";
import { QBOX_URL, JWT_TOKEN } from "../const/index";
import ConfirmRequestApi from "../api/qbox/ConfirmRequestApi";
import PopupConfirm from "./detail/PopupConfirm.vue";
import PopupConfirmList from "./detail/PopupConfirmList.vue";
import Confirm from "./Confirm.vue";
export default {
  name: "PopupCountReport",
  components: {
    Pagination,
    DatePicker,
    PopupConfirm,
    PopupConfirmList,
    Confirm,
  },
  props: {
    siteId: {},
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 10,
        beginDate: toDayFormatAddDay(-7),
        endDate: getToday(),
        fileName: null,
        fileType: "general",
        isUseModify: true,
      },
      isShow: false,
      allSelected: false,
      fileIds: [],
      accessSiteList: [],
      selectSiteId: [],
      confirmStatus: { writing: "미확인", confirm: "확인", change: "수정요청" },
      confirmParam: {
        title: "",
        msg: "",
        isShow: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    dayFormat: dayFormat,
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      this.selectSiteId = [];
      // this.selectSiteId.push(this.siteId);
      const result = await SiteApi.getSiteList();
      this.accessSiteList = result;
      for (const item of this.accessSiteList) {
        this.selectSiteId.push(item.id);
      }

      const response = await SiteApi.getSiteYearPlan(this.siteId);
      this.siteYearPlanList = response;
      if (this.siteYearPlanList.length > 0) {
        const today = this.getToday();
        const findSiteYear = this.siteYearPlanList.filter(
          (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
        );
        if (findSiteYear.length > 0) {
          this.listParam.beginDate = findSiteYear[0].beginDay;
        } else {
          this.listParam.beginDate = toDayFormatAddDay(-7);
        }
      }
      // this.getSiteList();
      this.fetchHistoryFile();
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchHistoryFile();
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },
    async fetchHistoryFile() {
      this.listParam.siteId = this.selectSiteId;

      const response = await HistoryFileApi.historyFileList(this.listParam);
      response.size = this.listParam.size;
      this.pageParam = response;
      this.pageParam.content.forEach((item) => {
        this.accessSiteList.forEach((site) => {
          if (item.siteId === site.id) {
            item.siteName = site.name;
          }
        });
      });
    },
    pageCallback(page) {
      this.listParam.page = page;
      this.fetchHistoryFile();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },

    sliceTime(time) {
      if (time) return time.slice(0, 5);
      return "";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.allSelected = false;
      this.fileIds = [];
      this.listParam.fileName = null;
      this.isShow = false;
    },
    selectAll() {
      this.fileIds = [];
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.pageParam.content.forEach((element) => {
          this.fileIds.push(element);
        });
      }
    },
    select() {
      this.allSelected = false;
    },
    async onClickDownload() {
      const fileArray = [];
      if (this.fileIds.length === 0) {
        this.$toast.info("파일을 선택하세요.");
        return;
      }

      this.fileIds.forEach((file) => {
        this.pageParam.content.forEach((item) => {
          if (file.fileId === item.fileId) {
            const file = {};
            file.fileId = item.fileId;
            file.fileNo = item.fileNo;
            file.jsonId = item.jsonId;
            fileArray.push(file);
          }
        });
      });

      const fileName = "실적파일";
      // this.showLoading();
      await HistoryFileApi.multiDownload(fileArray, fileName);
      // this.hideLoading();
    },
    onClickDiff() {
      if (this.fileIds.length === 2) {
        const site1 = this.pageParam.content.find(
          (item) => item.fileId === this.fileIds[0].fileId
        );
        const site2 = this.pageParam.content.find(
          (item) => item.fileId === this.fileIds[1].fileId
        );

        var popupW = 1200;
        var popupH = 800;
        var left = Math.ceil((window.screen.width - popupW) / 2);
        var top = Math.ceil((window.screen.height - popupH) / 2);
        const token = localStorage.getItem(JWT_TOKEN);

        const options =
          "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=" +
          top +
          ",left=" +
          left;

        window.open(
          `${QBOX_URL}/diffExcel?js1=${site1.jsonId}&js2=${site2.jsonId}&site1=${site1.siteId}&site2=${site2.siteId}&token=${token}`,
          "_blank",
          options
        );
      } else {
        alert("비교를 위해 항목은 2개만 선택 해 주세요");
      }
    },
    showExcel(site1) {
      var popupW = 1200;
      var popupH = 800;
      var left = Math.ceil((window.screen.width - popupW) / 2);
      var top = Math.ceil((window.screen.height - popupH) / 2);
      const token = localStorage.getItem(JWT_TOKEN);

      const options =
        "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=" +
        top +
        ",left=" +
        left;
      window.open(
        `${QBOX_URL}/diffExcel?js1=${site1.jsonId}&site1=${site1.siteId}&token=${token}`,
        "_blank",
        options
      );
    },
    changeSite(siteId) {
      this.allSelected = false;
      this.fileIds.forEach((item, index) => {
        if (siteId === item.siteId) {
          this.fileIds.splice(index, 1);
        }
      });
      this.fetchHistoryFile();
    },
    fileDown(file) {
      console.log(file);
      HistoryFileApi.fileDown(file.name, file.fileId, file.fileNo);
    },
    onClickConfirm(item) {
      if (item.confirmStatus === "writing") {
        this.confirmParam.item = item;
        this.confirmParam.msg = "확정처리 하시겠습니까?";
        this.confirmParam.isShow = true;
      } else if (item.confirmStatus === "change") {
        ConfirmRequestApi.list(item.confirmRequestId).then((res) => {
          item.msg = res[res.length - 1].msg;
          this.$refs.popupConfirm.open(item);
        });
      }
    },
    confirmCallback(item) {
      HistoryFileApi.updateHistory(item.id, {
        ...item,
        confirmStatus: "confirm",
      }).then(() => {
        this.fetchHistoryFile();
      });
    },
    onClickConfirmList(item) {
      if (!item.confirmRequestId) return;
      this.$refs.popupConfirmList.open(item);
    },
    makeConfirmBtn(item) {
      let labelClass = "detail";
      if (item.confirmStatus === "confirm") {
        labelClass = "approval";
      } else if (item.confirmStatus === "change") {
        labelClass = "waiting";
      }
      return `<a class='dayTest ${
        item.id === null ? "null" : "notnull"
      }  ${labelClass}'>${this.confirmStatus[item.confirmStatus]}</a>`;
    },
    makeConfirmListBtn(item) {
      if (!item.confirmRequestId) return;
      return `<a class='dayTest notnull detail'>보기</a>`;
    },
    regConfirm(item) {
      HistoryFileApi.updateHistory(item.id, {
        ...item,
        confirmStatus: "writing",
      }).then(() => {
        this.fetchHistoryFile();
      });
    },
  },
};
</script>
<style scoped>
.v-toast.v-toast--bottom {
  z-index: 10000000;
}
</style>

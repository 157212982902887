<template>
  <div v-if="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->

      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="onClickSearch"
              />
            </div>
            <select
              v-model="selectSiteId"
              class="search_option"
              @change="changeSite()"
            >
              <option
                v-for="item in accessSiteList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </form>
        </div>
      </div>

      <div class="company_box">
        <div
          class="company_div"
          v-for="(item, index) in companyList"
          :key="item"
        >
          <input
            :id="'check' + index"
            v-model="companyCheckList"
            class="company_chk"
            type="checkbox"
            :value="item"
            checked
            @change="companyChk"
          />
          <label :for="'check' + index">
            <span>{{ item }}</span>
          </label>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div id="container" class="graph_wrap oneitem2">
        <v-chart
          ref="chart1"
          class="chart"
          :option="option1"
          @click="handleChartClick"
          autoresize
        />
      </div>
    </div>
    <popup-conc-strength-list ref="popupConcStrengthList" />
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import { mapGetters, mapActions, mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  LineChart,
  BarChart,
  PieChart,
  ScatterChart,
  EffectScatterChart,
} from "echarts/charts";
import PlanCountApi from "../api/qbox/PlanCountApi";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import SiteApi from "../api/qbox/SiteApi";
import PopupConcStrengthList from "./PopupConcStrengthList.vue";
import { getToday } from "../utils/date";
import getters from "@/store/modules/common/getters";

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
  ScatterChart,
  EffectScatterChart,
]);

export default {
  name: "PopupScatterGraph",
  components: { VChart, PopupConcStrengthList, DatePicker },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      listParam: {
        beginDate: "",
        endDate: getToday(),
      },
      option1: {
        title: {
          text: "양평이천 1공구",
        },
      },

      isShow: false,
      accessSiteList: [],
      selectSiteId: null,
      specList: [],
      dataList: [],
      title: "",
      siteYearPlanList: [],
      companyList: [],
      companyCheckList: [],
      result: null,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    getToday: getToday,
    async init() {
      // const year = new Date().getFullYear();
      // this.listParam.beginDate = year + "-01-01";
      const response = await SiteApi.getSiteYearPlan(this.siteId);
      this.siteYearPlanList = response;
      if (this.siteYearPlanList.length > 0) {
        const today = this.getToday();
        const findSiteYear = this.siteYearPlanList.filter(
          (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
        );
        if (findSiteYear.length > 0) {
          this.listParam.beginDate = findSiteYear[0].beginDay;
        } else {
          this.listParam.beginDate = toDayFormatAddDay(-7);
        }
      } else {
        const today = new Date();
        this.listParam.beginDate = this.dayFormatNewDate(
          new Date(today.getFullYear(), 0, 1)
        );
      }
      this.selectSiteId = this.siteId;
      this.getSiteList();
      await this.graphCountPour();
      await this.setup();
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
    },

    async setup() {
      const option = {
        grid: {
          top: 100,
          bottom: 150,
          left: 0,
          right: 0,
        },

        title: {
          text: this.title,
          left: "center",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 20,
            color: "#ffffff",
          },
          top: 0,
          padding: [8, 30],
          backgroundColor: "rgba(30,110,235,1)",
          borderRadius: 50,
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.specList,
            scale: true,
            axisLabel: {
              interval: 0,
              rotate: 90,
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: "12",
              padding: [40, 0, 0, 0],
            },
            // triggerEvent: true,
          },
        ],
        yAxis: [
          {
            triggerEvent: true,
            type: "value",
            scale: true,
            axisLabel: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: "14",
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.2)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            type: "effectScatter",
            symbolSize: 20,
            label: {
              show: false,
              // position: "inner",
              fontSize: 18,
              fontWeight: "500",
              fontFamily: "Noto Sans Korean",
              position: ["25.8%", "40%"],
              color: "#fff",
            },
            itemStyle: {
              opacity: 0.4,
            },
            color: "rgba(255,255,0,0.3)",
            data: this.dataList,
          },
        ],
      };

      this.option1 = {
        ...option,
        title: {
          ...option.title,
          // ...this.option1.title,
        },
      };
    },
    handleChartClick(params) {
      // alert(`You clicked on: ${params.name}`);
      const firstLine = params.name.substring(0, params.name.indexOf("\n"));
      this.detail(
        this.selectSiteId,
        firstLine,
        this.listParam.beginDate,
        this.listParam.endDate
      );
    },
    open() {
      this.isShow = true;
      this.init();
    },
    close() {
      this.isShow = false;
    },
    detail(siteId, name, beginDay, endDay) {
      //TODO 팝업 테스트
      this.$refs.popupConcStrengthList.open(siteId, name, beginDay, endDay);
    },

    async graphCountPour() {
      this.specList = [];
      this.dataList = [];
      this.result = await PlanCountApi.graphCountPourScatter(
        this.selectSiteId,
        this.listParam
      );
      this.accessSiteList.forEach((element) => {
        if (element.id === this.selectSiteId) {
          this.title = element.name;
        }
      });

      // this.title = result[0].siteName;
      this.companyList = Array.from(
        new Set(
          this.result.map((item) =>
            item.company !== "" ? item.company : "이름없음"
          )
        )
      );
      this.companyList.sort();

      this.companyCheckList = this.companyList;
      // let xrData = [];
      this.result.forEach((element, index) => {
        if (element.company === "") element.company = "이름없음";
        this.specList.push(element.material + "\n" + element.company);
        // xrData = element.xrData
        element.xrData.forEach((data) => {
          const xrData = [];
          xrData.push(index);
          xrData.push(data);
          this.dataList.push(xrData);
        });
      });
    },
    async changeSite() {
      await this.graphCountPour();
      this.setup();
    },
    async onClickSearch() {
      await this.graphCountPour();
      this.setup();
    },
    companyChk() {
      const filterSpec = [];

      this.result.forEach((element) => {
        if (element.company === "") element.company = "이름없음";
        if (this.companyCheckList.some((item2) => item2 === element.company))
          filterSpec.push(element.material + "\n" + element.company);
      });
      const option = {
        grid: {
          top: 100,
          bottom: 150,
          left: 0,
          right: 0,
        },

        title: {
          text: this.title,
          left: "center",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 20,
            color: "#ffffff",
          },
          top: 0,
          padding: [8, 30],
          backgroundColor: "rgba(30,110,235,1)",
          borderRadius: 50,
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: [
          {
            type: "category",
            data: filterSpec,
            scale: true,
            axisLabel: {
              interval: 0,
              rotate: 90,
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: "12",
              padding: [40, 0, 0, 0],
            },
            // triggerEvent: true,
          },
        ],
        yAxis: [
          {
            triggerEvent: true,
            type: "value",
            scale: true,
            axisLabel: {
              fontFamily: "Noto Sans Korean",
              fontWeight: "500",
              fontSize: "14",
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.2)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            type: "effectScatter",
            symbolSize: 20,
            label: {
              show: false,
              // position: "inner",
              fontSize: 18,
              fontWeight: "500",
              fontFamily: "Noto Sans Korean",
              position: ["25.8%", "40%"],
              color: "#fff",
            },
            itemStyle: {
              opacity: 0.4,
            },
            color: "rgba(255,255,0,0.3)",
            data: this.dataList,
          },
        ],
      };

      this.option1 = {
        ...option,
        title: {
          ...option.title,
        },
      };
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2열 */
  grid-template-rows: 1fr 1fr; /* 2행 */
  height: 110%; /* 부모의 높이를 100%로 설정 */
  width: 100%; /* 필요에 따라 너비 설정 */
}

.grid-item {
  border: 1px solid #bfb2b2; /* 경계선을 보여주기 위해 추가 */
}

.company_div .company_chk {
  -webkit-appearance: auto;
}

.company_box {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.company_div span {
  color: white;
  font-size: 14px;
}

.company_div {
  margin-left: 12px;
}
</style>
